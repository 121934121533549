import React, { Component } from 'react';
import T from 'prop-types';

import { FieldTitle, CheckboxGroupInput, SimpleForm } from 'react-admin';
import {useForm, useFormState} from 'react-final-form';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Drawer from '@material-ui/core/Drawer';

export const CheckboxGroupDrawer = ({ value, onClose, onChange, title, ...rest }) => {
  // const form = useForm();
  // const { values, initialValues } = useFormState();
  const [ currValue, setCurrValue ] = React.useState(value);

  const handleClose = (e) => {
		onClose(currValue);
	};
  // const handleSave = (e) => {
	// 	console.log(2);
	// };
  const handleChange = (val) => {
    if (onChange) onChange(val);
    setCurrValue(val);
	};

  return (
    <Drawer maxWidth="xs" anchor="left" onClose={handleClose} open>
      {title && <DialogTitle id="ra-columns-dialog-title">{title}</DialogTitle>}
      {/* <DialogContent> */}
        <SimpleForm initialValues={{value}} toolbar={null} sx={{ maxWidth: 600 }}>
          <CheckboxGroupInput label="" source="value" row={false} onChange={handleChange} {...rest}/>
        </SimpleForm>
      {/* </DialogContent> */}
    </Drawer>
  );
}
