import React from 'react';
import { Resource } from 'react-admin';

import {
	ordersResource,
	OrderEditByTransporter, OrderShowForTransporter, OrderShowTabsWrapper
} from '../../resources/orders';


export const transporterDriverResources = [
	<Resource name="orders" list={ordersResource.list} edit={OrderEditByTransporter} show={OrderShowTabsWrapper}/>,
	<Resource name="ports" intent="registration" />,
	<Resource name="areas" intent="registration" />,
	<Resource name="users" intent="registration" />,
	<Resource name="order-statuses" intent="registration" />,
	<Resource name="outgoing-doc-statuses" intent="registration" />,
	<Resource name="cooler-types" intent="registration" />,
	<Resource name="order-pricelist" intent="registration" />,
	<Resource name="trucks" intent="registration" />,
	<Resource name="trailers" intent="registration" />,
	<Resource name="customer-companies" intent="registration" />,
	<Resource name="terminal" intent="registration" />,
	// <Resource name="drivers" {...customerDriversResource} />,
];
