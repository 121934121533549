import * as React from 'react';
import { useDispatch } from 'react-redux';
import {
	Button,
	useNotify,
	refreshView
} from 'react-admin';
import PublishIcon from '@material-ui/icons/Publish';
import { _uploadFile } from '../../../api/uploadFile';
import { ProgressIcon } from '../../../components/ProgressIcon';
import { SelectDialog } from './SelectDialog';

const EMPTY_SHEETS = [];

export const UploadArticlesButton = () => {
	const notify = useNotify();
	const dispatch = useDispatch()
	const inputRef = React.useRef(null);
	const [file, setFile] = React.useState(null);
	const [sheets, setSheets] = React.useState(EMPTY_SHEETS);
	const [isSheetSelectOpen, setIsSheetSelectOpen] = React.useState(false);

	const [isLoading, setIsLoading] = React.useState(false);

	const uploadPricelist = (file, sheet) => {
		setIsLoading(true);
		_uploadFile("/pricelist-articles-upload", file, sheet).then((res) => {
			setIsLoading(false);
			if (res.success) {
				notify("Pricelist is successfully uploaded", "success");
				dispatch(refreshView());
			}
		}).catch(err => {
			setIsLoading(false);
			console.dir(err);
			if (err.body) {
				err.body.errors && err.body.errors.forEach(error => {
					if (error.description === "Worksheet articles does not exist.") {
						notify("Document should include 'Pricelist' page", "error");
					} else {
						notify(error.description, "error");
					}
				});
				if (err.body.message) {
					notify(err.body.message, "error");
				}
			} else if (err.message) {
				notify(err.message, "error");
			}
		}).then(() => {
			setFile(null);
		});
	}

	const fetchAllSheets = (file) => {
		setIsLoading(true);
		_uploadFile("/xlsx-sheets-service", file).then((res) => {
			setSheets(res.sheets);
			setIsSheetSelectOpen(true);
		}).catch(err => {
			console.log(err);
			setIsLoading(false);
		});
	}

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		e.target.value = null;
		setFile(file);
		setSheets(EMPTY_SHEETS);
		fetchAllSheets(file);
	}

	const handleSheetSelect = (value) => {
		setIsSheetSelectOpen(false);
		setSheets(EMPTY_SHEETS);
		uploadPricelist(file, value);
	}

	return (
		<React.Fragment>
			<input
				type="file"
				onChange={handleFileChange}
				ref={inputRef}
				id="file"
				style={{display: "none"}}
				accept=".xlsx"
			/>
			<Button
				onClick={() => inputRef.current.click()}
				label="osfc.buttons.upload_articles"
				disabled={isLoading}
			>
				{isLoading ? <ProgressIcon /> : <PublishIcon />}
			</Button>
			<SelectDialog
				open={isSheetSelectOpen}
				handleSelect={handleSheetSelect}
				title="Select Sheet"
				items={sheets}
			/>
		</React.Fragment>
	);
}
