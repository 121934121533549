import React from "react";
import {
	TextInput, PasswordInput, required,
	usePermissions
} from 'react-admin';

import { GridForm, GridInput } from '../../../components/GridForm/';
import { PhoneInput, EmailInput, CompanySelect } from '../../../components/inputs/';
import { EmployeeTypeSelectInput } from '../../../components/inputs/EmployeeTypeSelect';
import { UserRole } from "../../../entities";
import { confirmPasswordValidation } from '../../../utils/validation/confirmPasswordValidation';
import { YesNoInput } from '../../../components/inputs/YesNoInput';


export const CustomerCreateForm = props => {
	const { permissions } = usePermissions();
	return ( !!permissions &&
		<GridForm {...props}>
			{(permissions.role === UserRole.admin || permissions.role === UserRole.osfc_manager) &&
				<GridInput sm={4} component={CompanySelect} source="company_id" reference="companies" validate={[required()]} /*filter={{is_blocked: false}}*/ />
			}
			<GridInput sm={4} component={TextInput} source="first_name" validate={[required()]} autoFocus />
			<GridInput sm={4} component={TextInput} source="last_name" validate={[required()]} />
			<GridInput sm={2} component={EmployeeTypeSelectInput} source="is_manager" defaultValue={true} />
			<GridInput sm={2} component={YesNoInput} source="access_to_export" defaultValue={false} />
			<GridInput sm={4} component={PhoneInput} source="phone" />
			<GridInput sm={4} component={EmailInput} source="email" validate={[required()]} />
		</GridForm>
	);
};
