import React from 'react';

import { Filter, useTranslate } from 'react-admin';


export const AdminActionsFilter = (props) => {
	const translate = useTranslate()

	return <Filter {...props} >		
	</Filter>
};
