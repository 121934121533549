import React from 'react';
import { useInput, FieldTitle, InputHelperText } from 'react-admin';
import TextField from '@material-ui/core/TextField';

import sanitizeRestProps from './react-admin-source/sanitizeRestProps';

export const muiInputProps = {
    variant: 'filled',
    margin: 'dense'
};

export const CustomTextInput = ({
    muiTextField: MuiTextField,
    label,
    format,
    helperText,
    onBlur,
    onFocus,
    onChange,
    options,
    parse,
    resource,
    source,
    validate,
    ...rest
}) => {	
	const {
        id,
        input,
        isRequired,
        meta: { error, touched },
    } = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        type: 'text',
        validate,
        ...rest,
    });
    return (
        <MuiTextField
            id={id}
            {...input}
            label={
                label !== '' &&
                label !== false && (
                    <FieldTitle
                        label={label}
                        source={source}
                        resource={resource}
                        isRequired={isRequired}
                    />
                )
            }
            error={!!(touched && error)}
            helperText={
                <InputHelperText
                    touched={touched}
                    error={error}
                    helperText={helperText}
                />
            }
            {...options}			
            {...muiInputProps}
            {...sanitizeRestProps(rest)}
        />
    );
};

CustomTextInput.defaultProps = {
    options: {},
    muiTextField: TextField
};
