import React from 'react';

import { Datagrid, DatagridBody } from 'react-admin';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import CustomDataGrid from '../../../../components/_extensions/react-admin/ra-ui-materialui/src/list/datagrid/DatagridEx';
import { ScrollingWrapperInCard } from '../../../../components/ScrollingWrapper';


const MyDatagridRow = ({ record, resource, id, onToggleItem, children, selected, basePath, style, cellStyle, ...rest }) => (
	<TableRow key={id} style={style}>
		{React.Children.map(children, field => (
			<TableCell key={`${id}-${field.props.source}`} style={cellStyle && cellStyle(field.props.source, record)} sortable={false}>
				{React.cloneElement(field, {
					record,
					basePath,
					resource,
				})}
			</TableCell>
		))}
	</TableRow>
);

const MyDatagridBody = ({ cellStyle, ...props }) => <DatagridBody {...props} row={<MyDatagridRow cellStyle={cellStyle} />} />;


export const MyDatagrid = ({ cellStyle, useCustomDataGrid, isHeaderShown, ...props }) => {
	if (useCustomDataGrid) {
		return <CustomDataGrid {...props} isHeaderShown={isHeaderShown} body={<MyDatagridBody cellStyle={cellStyle} />} />;
	};
	return <Datagrid {...props} body={<MyDatagridBody cellStyle={cellStyle} />} />;
};

export const HistoryDatagrid = ({children, noWraps = false, ...props}) => {
	const nextForRecordId = React.useMemo(() => {
		return props.ids ? props.ids.reduce((res, curr, index, arr) => {
			return {
				...res,
				[curr]: arr[index + 1] ? arr[index + 1] : null
			};
		}, {}) : {};
	}, [props.ids]);

	if (noWraps) { 
		return (
			<React.Fragment>
				{React.cloneElement(children, {nextForRecordId: nextForRecordId, ...props})}
			</React.Fragment>
		);
	};

	return (
		<Paper variant="outlined">
			<ScrollingWrapperInCard>
				{React.cloneElement(children, {nextForRecordId: nextForRecordId, ...props})}
			</ScrollingWrapperInCard>
		</Paper>
	);
};
