import React from "react";
import {Edit} from 'react-admin';

import { SimpleFormAdv } from '../../../components/forms/SimpleFormAdv';

import {AreaBaseForm, PortBaseForm} from "../Forms/PortAreaBaseForm";

export const PortEdit = (props) => (
	<Edit {...props} undoable={false}>
		<SimpleFormAdv>
			<PortBaseForm />
		</SimpleFormAdv>
	</Edit>
);

export const AreaEdit = (props) => (
	<Edit {...props} undoable={false}>
		<SimpleFormAdv>
			<AreaBaseForm />
		</SimpleFormAdv>
	</Edit>
);
