import React from "react";
import { List, Datagrid, TextField, EditButton, DeleteButton } from 'react-admin';

import { useForm } from 'react-final-form';
import { ScrollingWrapper } from '../../../components/ScrollingWrapper';
import { DatagridWithBlockProps } from '../../../components/_helpers/props/DatagridProps';

import { DateTimeField } from "../../../components/fields/DateFormatField";
import { DateTimeInput } from "../../../components/inputs/DateTimeInput";
import { ButtonEditableField } from "../../../components/fields/EditableField";
import { ArticleTemplatesFilter } from './ListFilters';

import MUIButton from '@material-ui/core/Button';


const defaultSort = { field: 'template', order: 'DESC' };

export const ArticleTemplatesList = props => {
	return (
		<List
			{...props}
			sort={defaultSort}
			exporter={false}
			bulkActionButtons={false}
			pagination={null}
			// filters={<ArticleTemplatesFilter />}
		>
			<ScrollingWrapper>
				<Datagrid {...DatagridWithBlockProps} >
					<TextField source="template" sortable={true} />
					<TextField source="load_time" />
					<EditButton label="" />
					<DeleteButton label="" />
				</Datagrid>
			</ScrollingWrapper>
		</List>
	);
}
