import React from "react";
import { Create, SimpleForm } from 'react-admin';

import { SaveWithCancelToolbar } from '../../../components/toolbars/SaveWithCancelToolbar';

import { CustomerCreateForm } from './CreateForm';


export const CustomerCreate = (props) => (
	<Create {...props}>
		<SimpleForm submitOnEnter={false} toolbar={<SaveWithCancelToolbar />} redirect="list">
			<CustomerCreateForm />
		</SimpleForm>
	</Create>
);
