import React from 'react';
import {
	FunctionField,
	useTranslate,
	BooleanField as RABooleanField,
} from 'react-admin';

import Tooltip from "@material-ui/core/Tooltip";
import Done from "@material-ui/icons/Done";
import Clear from "@material-ui/icons/Clear";


const FalseIcon = props => null;
export const BooleanField = props => <RABooleanField {...props} FalseIcon={FalseIcon} />
BooleanField.defaultProps = {
    addLabel: true,
};


export const EqualField = (props) => {
	const translate = useTranslate();

	return (
		<FunctionField
			{...props}
			render={record => {
				const isTrue = record[props.source] === props.to;
				return isTrue ? (
					<Tooltip title={translate(isTrue ? 'ra.boolean.true' : 'ra.boolean.false')}>
						{isTrue ? <Done fontSize="small" /> : <Clear fontSize="small" />}
					</Tooltip>
				) : null;
			}}
		/>
	);
};


EqualField.defaultProps = {
    addLabel: true,
};
