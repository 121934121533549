import React from "react";
import {
    SimpleForm,
    Create
} from 'react-admin';
import { SMSCreateForm } from "./CreateForm";
import { SaveWithCancelToolbar } from "../../../components/toolbars/SaveWithCancelToolbar";

export const CreateSMS = (props) => (
    <Create {...props}>
        <SimpleForm submitOnEnter={false} toolbar={<SaveWithCancelToolbar />} redirect="list">
            <SMSCreateForm {...props} />
        </SimpleForm>
    </Create>
)
