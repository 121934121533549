import PeopleIcon from '@material-ui/icons/People'

import { CustomerCreate } from './Create/Create';
import { CustomerEdit } from './Edit/Edit';
import { CustomersList } from './List/List';


export {
	CustomersList,
	CustomerCreate,
	CustomerEdit,
}


export const adminCustomerEmployees = {
	list: CustomersList,
	edit: CustomerEdit,
	create: CustomerCreate,
	icon: PeopleIcon,
}
