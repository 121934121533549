import React from 'react';

import { Paper } from '@material-ui/core';

import { useMutateForm } from '../../../../api/useMutateForm';
import { SimpleFormAdv } from '../../../../components/forms/SimpleFormAdv';


import { OSFCForm } from './forms';
import { ActionsWithBackButton } from './Actions';


export const MultipleOrderEditBase = (Form, name) => (props) => {
	const [mutateForm, state] = useMutateForm("updateManyArray", props.resource, props.basePath, props.selectedIds, {}); // eslint-disable-line

	const toolbarCompProps = {
		useSaveButtonEx: true,
		saveButtonExProps: {
			multiRecordData: props.initRecordData,
			onSave: mutateForm,
		},
	};

	return (
		<React.Fragment>
			<ActionsWithBackButton basePath={props.basePath} />
			<Paper>
				<SimpleFormAdv
					{...props}
					children={<Form initRecordData={props.initRecordData} />}
					toolbarCompProps={toolbarCompProps}
				/>
			</Paper>
		</React.Fragment>
	);
};


export const MultipleOrderEditByOSFC = MultipleOrderEditBase(OSFCForm, "OSFC");
