import StopIcon from '@material-ui/icons/Stop';

import { TrailersCreate } from './Create/Create';
import { TrailersEdit } from './Edit/Edit';
import { TrailersList } from './List/List';
import { TrailersShow } from './Show/Show';


export {
	TrailersList,
	TrailersCreate,
	TrailersEdit,
	TrailersShow,
};

export const transportTrailersResource = {
	list: TrailersList,
	edit: TrailersEdit,
	create: TrailersCreate,
	show: TrailersShow,
	icon: StopIcon,
};
