import React from 'react';

import CopyIcon from '@material-ui/icons/FileCopy';
import { useNotify, useMutation, Confirm, useTranslate } from 'react-admin';

import { ProgressIcon } from '../../ProgressIcon';
import { clipboardTable } from '../../clipboardTable';
import { ToolbarButton } from './ToolbarButton';

import { dateToISOWithoutTimeZone } from '../../_helpers/dateTime';


export const CopyCSVButton = ({ userId, table, exportDate, setDateFromFilter }) => {
	const notify = useNotify();
	const [ confirmOpen, setConfirmOpen ] = React.useState(false);

	const [mutate, { loading }] = useMutation();

	const translate = useTranslate()

	const handleConfirm = () => {
		if (userId && exportDate) {
			mutate({
				type: 'update',
				resource: 'orders/last-export-date',
				payload: {
					id: userId,
					data: {
						date: dateToISOWithoutTimeZone(exportDate),
					}
				},
				
			}, {
				onSuccess: () => {
					setDateFromFilter(exportDate);
					notify("Export date was updated successfully", "info");					
					setConfirmOpen(false);
				},
				onFailure: (error) => {
					notify(error.message, "error");
					setConfirmOpen(false);
				}
			});
		}		
	}
	const handleConfirmClose = () => {
		setConfirmOpen(false);
	}

	const handleClick = () => {
		clipboardTable(table.current);
		notify("resources.orders.messages.export-copied");
		setConfirmOpen(true);
	}

	return (
		<React.Fragment>
			<ToolbarButton icon={loading ? <ProgressIcon /> : <CopyIcon />} onClick={handleClick}>{translate("resources.orders.export-dialog.button.copy")}</ToolbarButton>
			<Confirm
				isOpen={confirmOpen}
				title="resources.orders.messages.export-copied-title"
				content="resources.orders.messages.export-copied-info"
				onConfirm={handleConfirm}
				onClose={handleConfirmClose}
				confirm="osfc.buttons.confirm"
			/>
		</React.Fragment>
	);
}
