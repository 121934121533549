import React from 'react';
import {
	CreateButton,
	useListContext,
	sanitizeListRestProps,
	TopToolbar,
	usePermissions
} from 'react-admin';

import CSVImportButton from '../../../components/dialogs/CSVImportButton';


export const TrucksActions = (props) => {
    const { className, exporter, filters, ...rest } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext(props);
	const { permissions } = usePermissions();
	const isAccessToImport = permissions && permissions.access_to_import;

    return React.useMemo(
        () => (
            <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
                {filters &&
                    React.cloneElement(filters, {
                        resource,
                        showFilter,
                        displayedFilters,
                        filterValues,
                        context: 'button',
                    })
                }
                {isAccessToImport && <CSVImportButton {...props} />}
                {hasCreate && <CreateButton basePath={basePath} />}
            </TopToolbar>
        ),
        [resource, displayedFilters, filterValues, selectedIds, filters, total] // eslint-disable-line react-hooks/exhaustive-deps
    );
};
