import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';

import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


const isComponentsNotValid = (arrayOfComponents) => (
    !arrayOfComponents.every((component) => typeof component === "function" || typeof component === "object")
);

const PreviewOpenButton = ({ openButtonProps, drawerProps, ...props }) => (
    <Button size="small" color="primary" {...props}>
        {openButtonProps?.formatLabel ? 
            openButtonProps.formatLabel(openButtonProps?.record || {}) 
        : 
            openButtonProps?.label
        }
    </Button>
);

const PreviewCloseButton = (props) => (
    <IconButton disableRipple={true} disableFocusRipple={true} color="primary" {...props}>
        <CloseIcon fontSize="small" />
    </IconButton>
);


const drawerStyles = withStyles(theme => ({
	paper: {
		[theme.breakpoints.up('md')]: {
			width: '25vw',
		},
		[theme.breakpoints.only('sm')]: {
			width: '22vw',
		},
		[theme.breakpoints.only('xs')]: {
			width: '100vw',
		},
	},
}));

const StyledDrawer = drawerStyles(Drawer);


const QuickPreview = ({
    formatLabel, label,
    onDrawerOpen, onDrawerClose,

    allowDivider = true, anchorSide = "right",
    contentProps = {}, withDrawerDefaultStyles = true, 

    ContentComponent,
    PreviewOpenButtonComponent = PreviewOpenButton,
    PreviewCloseButtonComponent = PreviewCloseButton,
    DrawerComponent = withDrawerDefaultStyles ? StyledDrawer : Drawer,

    ...rest
}) => {
	const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

    if (isComponentsNotValid([ContentComponent, PreviewOpenButtonComponent, 
                              PreviewCloseButtonComponent, DrawerComponent])) {
        return null;
    }

    const record = rest?.record || contentProps.record;
    const openButtonProps = { formatLabel, label, record };
    const drawerProps = { anchorSide, isDrawerOpen, setIsDrawerOpen };

    const openButtonSharedProps = { openButtonProps, drawerProps };
    const closeButtonSharedProps = { drawerProps };
    const previewContentProps = contentProps;
    const sharedProps = { openButtonProps, drawerProps, contentProps };

	const handlePreviewButtonClick = (event) => {
        onDrawerOpen && onDrawerOpen(event, sharedProps);
		setIsDrawerOpen(true);
	};

	const handleDrawerCloseClick = (event) => {
        onDrawerClose && onDrawerClose(event);
		setIsDrawerOpen(false);
	};

	return (
		<React.Fragment>
            <PreviewOpenButtonComponent {...openButtonSharedProps} onClick={handlePreviewButtonClick} />
                <DrawerComponent anchor={anchorSide} open={isDrawerOpen} onClose={handleDrawerCloseClick}>
                    <PreviewCloseButtonComponent {...closeButtonSharedProps} onClick={handleDrawerCloseClick} />
                    {allowDivider ? <Divider /> : null}
                    <ContentComponent {...previewContentProps} />
                </DrawerComponent>
		</React.Fragment>
	);
};


export default QuickPreview;
