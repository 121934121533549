import React from 'react';
import { Resource } from 'react-admin';

import {ordersResource, OrderEditByTransporter, OrderShowForTransporter, OrderShowTabsWrapper} from '../../resources/orders/';

import { customerDriversResource } from '../../resources/drivers/';
import { transportTrucksResource } from '../../resources/trucks';
import { transportTrailersResource } from '../../resources/trailers';
// import {customersEmployeesResource} from "../../resources/customer-employees";
import { adminCustomerEmployees } from '../../resources/admin-customer-employees/';


export const transporterAdminResources = [
<Resource name="orders" list={ordersResource.list} edit={OrderEditByTransporter} show={OrderShowTabsWrapper} />,
	<Resource {...transportTrucksResource} name="trucks" />,
	<Resource {...transportTrailersResource} name="trailers" />,
	// <Resource {...customersEmployeesResource} name="customer-employees" />,
	<Resource {...adminCustomerEmployees} name="admin-customer-employees" options={{ label: 'Employees' }} />,
	<Resource name="ports" intent="registration" />,
	<Resource name="users" intent="registration" />,
	<Resource name="order-statuses" intent="registration" />,
	<Resource name="outgoing-doc-statuses" intent="registration" />,
	<Resource name="cooler-types" intent="registration" />,
	<Resource name="order-pricelist" intent="registration" />,
	<Resource name="drivers" {...customerDriversResource} />,
	<Resource name="customer-companies" intent="registration" />,
	<Resource name="company-notifications" intent="registration" />,
];
