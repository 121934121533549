import React from "react";
import { Edit, SimpleForm } from 'react-admin';

import { SaveWithCancelWithPasswordChangeToolbar } from '../../../components/toolbars/SaveWithCancelWithPasswordChangeToolbar';
import { ActionsWithBackButton } from '../../../components/actionbars/ActionsWithBackButton';
import { CustomerDriverEditForm } from './EditForm';


export const DriverEdit = (props) => (
	<Edit actions={<ActionsWithBackButton />} {...props} undoable={false}>
		<SimpleForm
			submitOnEnter={false}
			toolbar={<SaveWithCancelWithPasswordChangeToolbar />}
		>
			<CustomerDriverEditForm />
		</SimpleForm>
	</Edit>
);
