import React from 'react';

// import SvgIcon from '@material-ui/core/SvgIcon';
import { FlagIcon } from './FlagIcon';


export const FlagNO = props => (
	<FlagIcon {...props} viewBox="0 0 512 512">
        <circle fill="#F0F0F0" cx="256" cy="256" r="256"/>
        <g>
            <path fill="#D80027" d="M8.819,322.783c14.812,54.959,47.457,102.593,91.355,136.322V322.783H8.819z"/>
            <path fill="#D80027" d="M233.739,511.026C241.077,511.658,248.498,512,256,512c118.279,0,217.805-80.221,247.181-189.217 H233.739V511.026z"/>
            <path fill="#D80027" d="M503.181,189.217C473.805,80.221,374.279,0,256,0c-7.502,0-14.923,0.342-22.261,0.974v188.243 H503.181z"/>
            <path fill="#D80027" d="M100.174,52.895c-43.898,33.73-76.543,81.363-91.355,136.322h91.355V52.895z"/>
        </g>
        <path fill="#0052B4" d="M509.833,222.609H200.349h-0.001V6.085c-23.658,5.246-46.087,13.749-66.783,25.042v191.48v0.001
            H2.167C0.742,233.539,0,244.683,0,256s0.742,22.461,2.167,33.391h131.397h0.001v191.481c20.696,11.292,43.125,19.797,66.783,25.042
            V289.394v-0.002h309.485C511.256,278.461,512,267.317,512,256S511.256,233.539,509.833,222.609z"/>
    </FlagIcon>
);
