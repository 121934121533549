import React from 'react';

import { MultiChangesStateEnum } from '../../../../components/GridForm';
import { isNullUndEmpty }from "../../../../utils/general";

import { MultipleOrderEditByOSFC } from '../EditForms';

function getSameValue(arr) {
	if (arr.length) {
		let sameVal = arr[0];
		if (isNullUndEmpty(sameVal)) {
			for (let ind = 1; ind < arr.length; ind++) {
				if (!isNullUndEmpty(arr[ind])) return;
			}
		} else {
			for (let ind = 1; ind < arr.length; ind++) {
				if (arr[ind] !== sameVal) return;
			}
		}		
		return sameVal;
	}
}


export const OSFCEditPage = ({ selectedIds, recordsByIds, ...props }) => {
	const [ recordData, seRecordData ] = React.useState();
	
	React.useEffect(()=>{
		const records = Object.values(recordsByIds);
		if (records.length) {
			const keys = [];
			const values = {};
			const multiValues = {};	
			const multiInfo = {};
			const state = {};	

			for (const [key, val] of Object.entries(records[0])) {
				keys.push(key);
				multiValues[key] = [val];				
			}
			for(let ind = 1; ind < records.length; ind++) {
				for (const key of keys) {
					multiValues[key].push(records[ind][key]);
				}
			}

			for (const key of keys) {		
				const sameVal = getSameValue(multiValues[key]);
				if (sameVal === undefined) {
					values[key] = null;
					state[key] = MultiChangesStateEnum.IS_INIT_DIFF | MultiChangesStateEnum.IS_CALC;
				} else {
					values[key] = sameVal;
					state[key] = MultiChangesStateEnum.NONE;
				}

				multiInfo[key] = multiValues[key].map(x => isNullUndEmpty(x) ? "EMPTY" : x).join(", ");
			}
			
			seRecordData({
				values,
				initialValues: {...values},
				multiValues,
				multiInfo,
				state
			});
		}
    }, [recordsByIds]);

	if (!recordData) return null;

	return (
		<MultipleOrderEditByOSFC 
			{...props} 
			selectedIds={selectedIds} 
			record={recordData.values} 
			initRecordData={recordData}
		/>
	);
}; 
