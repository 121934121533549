import React from 'react';
import { TextInput, required } from 'react-admin';

import { GridForm, GridInput, FormGroupGrid } from '../../../components/GridForm';
import { parseLinkQuerystring } from '../../../components/_helpers/parsers';
import { parseSortOrder } from '../../../components/_helpers/parsers';
import { validateSortOrderRange } from '../../../utils/validation/hotlinks';


export const HotlinksEditForm = (props) => (
	<GridForm {...props}>
		<FormGroupGrid>
			<GridInput sm={2} component={TextInput} source='id' disabled />
			<GridInput sm={10} component={TextInput} source='name' validate={[required()]} autoFocus />
		</FormGroupGrid>

		<FormGroupGrid>
			<GridInput sm={10} component={TextInput} source='link' parse={parseLinkQuerystring} validate={[required()]} />
			<GridInput sm={2} component={TextInput} source='sort_order' parse={parseSortOrder} validate={[validateSortOrderRange]}/>
		</FormGroupGrid>
	</GridForm>
);
