import React from 'react';

import { FunctionField } from 'react-admin';
import * as PriceCategory from '../../entities/PriceCategory';


export const PriceCategoryField = (props) => {
	return (
		<FunctionField
			{...props}
			render={record => {
				return PriceCategory.byId[record[props.source]];
			}}
		/>
	);
};


PriceCategoryField.defaultProps = {
    addLabel: true,
};
