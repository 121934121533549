import React from 'react';

import {
	usePermissions,
	ReferenceInput,
	SelectInput,
	useTranslate,
	BooleanInput
} from 'react-admin';
import { useFormState } from 'react-final-form';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';

import { UpdateForm } from './UpdateForm';
import { CardBlock } from './CardBlock';

import { UserRole } from '../../entities';
import { GridInput, GridForm } from '../../components/GridForm';
import { YesNoInput, EmailInput } from '../../components/inputs/';
import { CommodityInput } from '../../resources/orders/forms/inputs/CommodityInput';
import LanguageSelect from '../../components/inputs/LanguageSelect';

const CompanySettingsForm = (props) => {
	
	return (
		<GridForm {...props}>
			<GridInput md={3} sm={6} component={LanguageSelect}/> 
			<GridInput md={3} sm={6} component={EmailInput} source="deviation_notification_email" />
			<GridInput md={1} sm={6} component={YesNoInput} source="plumb_seal" defaultValue={false} />		
			<GridInput md={3} sm={6} component={CommodityInput} source="commodity" />	
			{/* <GridInput md={3} sm={6}>
				<ReferenceInput source="area_id" reference="areas" filter={byTerminalFilter}>
					<SelectInput optionText="name" />
				</ReferenceInput>
			</GridInput> */}
			<GridInput md={1} sm={6}>
				<ReferenceInput source="terminal_id" reference="terminal">
					<SelectInput optionText="name" />
				</ReferenceInput>
			</GridInput>
			<GridInput md={1} sm={6} component={YesNoInput} source="is_output_document" defaultValue={false} />
			<GridInput md={2} sm={6}>
				<BooleanInput label="Copy docs" source="copy_docs_on_clone" />
			</GridInput>
		</GridForm>
	);
};


export const CompanySettingsBlock = (props) => {
	const { permissions } = usePermissions();
	const translate = useTranslate();

	const isOSFCAdminManager = permissions && (
		permissions.role === UserRole.admin ||
		permissions.role === UserRole.osfc_manager
	);
	
	const currentCompanyId = permissions?.company_id || (props?.record && props.record.id);
	
	return (
		currentCompanyId ? <UpdateForm
			id={currentCompanyId}
			resource="customer-companies"
			render={({ body, buttons }) => (
				<CardBlock Component={isOSFCAdminManager ? Box : Card} 
						   title={isOSFCAdminManager ? "" : translate("tab.company_settings.name")} 
						   buttons={buttons} children={body} />
			)}
		>
			<CompanySettingsForm />
		</UpdateForm> : <></>
	);
}
