import React from "react";

import { Edit, SimpleForm } from 'react-admin';

import { UserRole } from '../../../entities/';

import { SaveWithCancelToolbar } from '../../../components/toolbars/SaveWithCancelToolbar';
import { TrucksEditForm } from './EditForm';


export const TrucksEdit = ({permissions, ...props}) => {
	const isAdminOrOSFCManager = permissions && (
		permissions.role === UserRole.osfc_manager || 
		permissions.role === UserRole.admin
	);

	return (
		<Edit {...props} undoable={false}>
			<SimpleForm submitOnEnter={false} toolbar={<SaveWithCancelToolbar />}>
				<TrucksEditForm isAdminOrOSFCManager={isAdminOrOSFCManager} />
			</SimpleForm>
		</Edit>
	);
};
