import React from "react";

import { maxLength, TextInput } from 'react-admin';
import { useFormState } from 'react-final-form';

import { requiredIfTrue } from '../../../../utils/validation/requiredIfNoFieldValue';
import { makeFormatter } from '../../../../components/formatter';


const convertOutputValue = (value) => value !== '' ? Number(value) : null;
const loadingOrderFormatter = (value) => convertOutputValue(makeFormatter([{ char: /[\d]/, repeat: 1 }])(value));


export const LoadingOrderInput = ({ validate=[], ...props }) => {
	const { values } = useFormState();
	if (validate !== null) {
		validate = [maxLength(1), requiredIfTrue(!!values.split_load), ...validate];
	}
	return <TextInput parse={loadingOrderFormatter} validate={validate} {...props} />;
}
