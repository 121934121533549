import React from 'react';

import Grid from '@material-ui/core/Grid';
import { withHidden } from '../Hidden';
import { Toolbar, SaveButton, useNotify } from 'react-admin';


import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBack';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import NoterIcon from '@material-ui/icons/Note';
import NotesIcon from '@material-ui/icons/Notes';
import UndoIcon from '@material-ui/icons/Undo';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import IconButton from '@material-ui/core/IconButton';

import { isNullUndEmpty }from "../../utils/general"

const allowedElements = {
	INPUT: true,
	TEXTAREA: true,
}

const focusOnElement = (node) => {
	if (node.className.search('disabled') > -1) {
		return false;
	}
	node.focus();
	return true;
}

const handleFocusOnNextOrPreviousInput = (e) => {
	if (e.keyCode === 9 || e.keyCode === 13) {
		const form = e.target.form;
		console.log("form")
		console.log(form)
		if (form) {
			if (!(e.keyCode === 13 && e.target.tagName && e.target.tagName.toLowerCase() === "textarea")) {
				e.preventDefault();
				const elements = Array.prototype.filter.call(e.target.form.elements, el => {
					return allowedElements[el.tagName];
				});
				console.log("elements")
				console.log(elements)
				const index = Array.prototype.indexOf.call(elements, e.target);
				console.log("index: " + index);
				const step = e.shiftKey ? -1 : 1;
				for (let i = index + step; i > -1 && i < elements.length; ) {
					const element = elements[i];
					i += step;
					if (!element) break;


					if (element.previousSibling) {
						if (focusOnElement(element.previousSibling)) break;
					} else {
						if (focusOnElement(element)) break;
					}
				}
			}
		}
	}
};


const GridInputRaw = ({ component: Component, xs=12, sm, md, lg, children, enabledFields, updateDependency, ...props }) => {
	return (
		<Grid item xs={xs} sm={sm} md={md} lg={lg}>
			{children ?
				React.cloneElement(children, {
					...props,
					fullWidth: true,
					// variant: "outlined"
				})
				:
				<Component {...props}
					fullWidth
					onKeyDown={handleFocusOnNextOrPreviousInput}
					// variant="outlined"
				/>
			}
		</Grid>
	);
}


export const GridInput = withHidden(GridInputRaw);

export const MultiChangesStateEnum = {
	NONE: 0,
	IS_INIT_DIFF: 1,
	IS_DIFF: 2,
	NOT_REVERT: 4,
	IS_CALC: 8,
}

export const isMultiDiff = (initVal, val, state) => {
	let isDiff = false;
	if (initVal === null) {
		if (state & MultiChangesStateEnum.IS_INIT_DIFF) {
			// isDiff = !(recordData.state[key] & MultiChangesStateEnum.IS_CALC);
			isDiff = val !== null;
		} else {
			isDiff = !isNullUndEmpty(val);
		}
	} else {
		// isDiff = !(recordData.values[key] === initVal || (isNullUndEmpty(recordData.values[key]) && isNullUndEmpty(initVal)));
		isDiff = val !== initVal;
	}
	return isDiff;
};

const reference_fields = {
	"terminal_id": "terminal_name", 
	"area_id": "area_name",
	"company_id": "customer_name",
	"assignee_1": "assignee_1_name", 
	"assignee_2": "assignee_2_name", 
	"eta_3rd_party": "eta_3rd_party_name",
	"etd_3rd_party": "etd_3rd_party_name",
	"eta_driver_id": "eta_driver_name", 
	"etd_driver_id": "etd_driver_name", 
	"eta_truck_id": "eta_truck_name",
	"etd_truck_id": "etd_truck_name",
	"eta_trailer_id": "eta_trailer_name",
	"etd_trailer_id": "etd_trailer_name",
	"port_in": "port_in_name", 
	"port_out": "port_out_name",
	"status": "status_name", 
	"service": "service_name",
	"cooler_type_id": "cooler_type_name",
	"label_source": "label_source_name",
}

const GridInputRawMultiChanges = ({ component: Component, xs=12, sm, md, lg, children, enabledFields, updateDependency, multiData, isMultiDataParent, ...props }) => {
	const notify = useNotify();

	const recordData = multiData?.recordData;
	const cbMulti = multiData?.cbMulti;
	const key = props.source;

	const addProps = {};	
	let isDiff = false;
	let notRevert = false;
	let multiInfo = null;
	let isResettable = false;
	if (isMultiDataParent) {
		addProps["multiData"] = multiData;
	} else {
		if (recordData) {
			if (recordData.state) {
				isDiff = recordData.state[key] & MultiChangesStateEnum.IS_DIFF;
				notRevert = recordData.state[key] & MultiChangesStateEnum.NOT_REVERT;
				
				if (isDiff) {
					console.log("changed-field: " + key);					
					addProps["className"] = "changed-field";
				}		
			}
	
			if (recordData.multiInfo && recordData.multiInfo[key]) {
				if(reference_fields.hasOwnProperty(key)){
					multiInfo = recordData.multiInfo[reference_fields[key]];
				  }
				  else{
					multiInfo = recordData.multiInfo[key];
				  }  
			}
	
			isResettable = !!props.resettable && !props.disabled && (!isNullUndEmpty(recordData.initialValues[key]) || !isNullUndEmpty(recordData.values[key]) || (recordData.state[key] & MultiChangesStateEnum.IS_INIT_DIFF));
		}
	}	
	
	return (
		<Grid item xs={xs} sm={sm} md={md} lg={lg}>
			{!isMultiDataParent ? <Toolbar className="toolbar-flat" >
				<Tooltip title="Revert" placement="top">
					<IconButton aria-label="Revert" size="small" disabled={!isDiff || notRevert} onClick={e => cbMulti("revert", props.source)} >
						<UndoIcon/>
					</IconButton>
				</Tooltip>
				{/* <Button disabled={!isDiff} size="small" onClick={e => props.onClear(props.source)} >
					diff
				</Button> */}
				<Tooltip title="Info" placement="top">
					<IconButton aria-label="Info" size="small" disabled={!multiInfo} onClick={e => notify(multiInfo, {type: 'info', multiLine: true, anchorOrigin: { vertical: 'center', horizontal: 'center' }})} >
						<NotesIcon/>
					</IconButton>
				</Tooltip>
				<Tooltip title="Clear" placement="top">
					<IconButton aria-label="Clear" size="small" disabled={!isResettable} onClick={e => cbMulti("clear", props.source)} >
						<ClearIcon/>
					</IconButton>
				</Tooltip>
				{/* <Tooltip title="test" placement="right">
					<Button disabled={!isDiff} size="small" onClick={e => props.onClear(props.source)} >
						clear
					</Button>
				</Tooltip> */}
				
			</Toolbar> : null}
			
			{children ?
				React.cloneElement(children, {
					...props,
					...addProps,
					fullWidth: true,
					// variant: "outlined"
				})
				:
				<Component
					{...props}
					{...addProps}
					fullWidth
					onKeyDown={handleFocusOnNextOrPreviousInput}
					// variant="outlined"
				/>
			}
		</Grid>
	);
};


export const GridInputMultiChanges = withHidden(GridInputRawMultiChanges);


// export const GridInput = React.memo(withHidden(GridInputRaw), (prev, next) => {
// 	if (prev.updateDependency !== next.updateDependency
// 		|| prev.validate !== next.validate
// 		|| prev.disabled !== next.disabled
// 		|| prev.hidden !== next.hidden
// 		|| prev.xs !== next.xs
// 		|| prev.sm !== next.sm
// 		|| prev.md !== next.md
// 		|| prev.lg !== next.lg
// 	) {
// 		return false;
// 	}
// 	return true;
// });

