import React from 'react';
import { ReferenceInput, ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';
import { AutocompleteInput } from 'ra-ui-materialui/lib/input/AutocompleteInput';

import { withStyles } from '@material-ui/core/styles'

import { TextReadonlyReferenceInput } from './TextReadonlyInput';


const defaultSort = {
	field: "full_name",
	order: "ASC"
};


export const CompanyText = (props) => {
	return (
		<ReferenceInput source="company_id" reference="customer-companies" 
						sort={defaultSort} {...props} filter={{ id: [props.record[props.source]] }}>
			<TextReadonlyReferenceInput optionText="full_name" />
		</ReferenceInput>
	);
};


export const CompanySelect = ({ size, refer="customer-companies", ...props }) => {
	return (
		<ReferenceInput source="company_id" reference={refer} 
						sort={defaultSort} {...props} filter={{ no_range: true, ...props.filter }}>
			<AutocompleteInput optionText="full_name" resettable options={{ InputProps: { size } }} />
		</ReferenceInput>
	);
};


const CustomAutocompleteArrayInput = withStyles({
	// chip: {
	// 	margin: '2px', 
	// 	whiteSpace: 'nowrap', 
	// 	overflow: 'hidden', 
	// 	textOverflow: 'ellipsis', 
	// },
	// container: {
	// 	width: '100%', 
	// 	maxWidth: 500, 
	// },
})(AutocompleteArrayInput);


export const ExcludeCompanySelect = ({ size, ...props }) => {
	return (
		<ReferenceArrayInput source="exclude_company_id" reference="customer-companies" 
							 sort={defaultSort} {...props} filter={{ no_range: true, ...props.filter }}>
			<CustomAutocompleteArrayInput optionText="full_name" debounce={500} 
									options={{ InputProps: { size } }} resettable />
		</ReferenceArrayInput>
	);
};
