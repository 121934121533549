import React from 'react';
import { FunctionField } from 'react-admin';


const renderNoOrValue = (record, source) => record[source] ? record[source] : 'NO';

export const NoOrValueField = (props) => {
	return (
		<FunctionField {...props} render={renderNoOrValue} />
	);
};


NoOrValueField.defaultProps = {
    addLabel: true,
};


const renderNoneOrValue = (record, source) => record[source] ? record[source] : '';

export const NoneOrValueField = (props) => {
	return (
		<FunctionField {...props} render={renderNoneOrValue} />
	);
};


NoneOrValueField.defaultProps = {
    addLabel: true,
};
