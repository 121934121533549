import React from 'react';
import { useInput, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { FilterToggleButtonGroup, StyledToggleButton } from './FilterToggleInput';
import { filterColors } from '../../../utils/constants';


export const ToggleStateEnum = {
	ACTIVE: "active",
	NOT_ACTIVE: "notActive",
}

const useStyles = makeStyles({
	filterState: {
		backgroundColor: filterColors.state
	},
});


export const ToggleStateFilter = (props) => {
	const translate = useTranslate();
	const classes = useStyles();
	const { input } = useInput(props);
	const value = input.value;

	const handleChange = (e, newValue) => {
		input.onChange(newValue);
	}

	return (
		<FilterToggleButtonGroup value={value} onChange={handleChange} label={input.label} exclusive className={classes.filterState}>
			<StyledToggleButton value={ToggleStateEnum.NOT_ACTIVE}>
				{translate('osfc.filters.state.not_active')}
			</StyledToggleButton>
			<StyledToggleButton value={ToggleStateEnum.ACTIVE}>
				{translate('osfc.filters.state.active')}
			</StyledToggleButton>
		</FilterToggleButtonGroup>
  );
}
