import TransporterIcon from '@material-ui/icons/EmojiTransportation';

import { TransporterCreate } from './Create/Create';
import { TransporterShow } from './Show/Show';
import { TransporterEdit } from './Edit/Edit';
import { TransportersList } from './List/List';


export {
	TransportersList,
	TransporterCreate,
	TransporterEdit,
	TransporterShow,
}

export const transportersResource = {
	list: TransportersList,
	// edit: TransporterEdit,
	// create: TransporterCreate,
	show: TransporterShow,
	icon: TransporterIcon,
}
