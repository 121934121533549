import React from 'react';

import { Confirm } from 'react-admin';
import { useFormState } from 'react-final-form';

import SaveButtonEx from '../_extensions/react-admin/ra-ui-materialui/src/button/SaveButtonEx';


const getWarningsIfExist = (values, warningValidations) => {
    for(const [key, validator] of Object.entries(warningValidations)) {
        const warningMsg = validator(values)
        if (warningMsg) return warningMsg;
    }    
};

export const SaveWithWarningsButton = ({warningValidations, ...props}) => {

    const { values } = useFormState();
    const [ confirmOpen, setConfirmOpen ] = React.useState(false);
    const [ warningInfo, setWarningInfo ] = React.useState();
    const [ customClickProp, setCustomClickProp ] = React.useState();
    const [ handleSubmitProps, setHandleSubmitProps ] = React.useState();

    const handleConfirmSave = () => {
		setConfirmOpen(false);
        setCustomClickProp(handleSubmitProps);
	};

    const handleConfirmClose = () => {
		setConfirmOpen(false);
	};

    const handleSubmitWithWarnings = (onSaveProps) => {
        const warningsContent = getWarningsIfExist(values, warningValidations);

        if (warningsContent) {
            setWarningInfo(warningsContent);
            setHandleSubmitProps(onSaveProps);
            setConfirmOpen(true);
        } else {
            setCustomClickProp(onSaveProps);
        }
    };

    return (
        <React.Fragment>
            <SaveButtonEx {...props} customHandleClick={handleSubmitWithWarnings} customClickProp={customClickProp} />
			<Confirm
				isOpen={confirmOpen}
				content={warningInfo}
				title="osfc.dialogs.order_warnings.title"
                confirm="osfc.dialogs.order_warnings.actions.confirm"
                cancel="osfc.dialogs.order_warnings.actions.cancel"
				onConfirm={handleConfirmSave}
				onClose={handleConfirmClose}
			/>
        </React.Fragment>
    );
};
