import React, { useEffect } from 'react';
import { useQuery, useTranslate, SimpleShowLayout, RichTextField } from 'react-admin';

import { Box, Typography, makeStyles } from '@material-ui/core';

import { LoadingSpinner } from '../LoadingSpinner';
import { useNotifyError } from '../../utils/notifiers/useNotifyError';

import QuickPreview from '../CustomDrawer';


const useStyles = makeStyles(theme => ({
	notParsed: {
		color: "#D3D3D3",
		overflowWrap: "anywhere", 
		fontWeight: "bold",
	},
	parsed: {
		cursor: "pointer", 
		overflowWrap: "anywhere", 
		color: "#006400", 
		fontWeight: "bold",
	},
	content: {
		borderRadius: '10px',
		backgroundColor: "#cfd8dc",
		position: "sticky",
		whiteSpace: "pre-wrap",
		overflowY: "auto",
		height: '100vh',
		margin: theme.spacing(2),
	}
}));

const DocumentsPreviewView = ({ translate, data }) => {
	const classes = useStyles();
	return (
		<Box className={classes.content}>
			<SimpleShowLayout>
				<RichTextField label={translate("osfc.fields.document.text_popup.title")}/>
				<Typography variant="h6" style={{ wordWrap: "break-word" }}>
					{data.text}
				</Typography>
			</SimpleShowLayout>
		</Box>
	);
};


const DocumentTextPreviewFetch = ({ orderId, record, translate }) => {
    const notifyError = useNotifyError();

	const { data, loading, error } = useQuery({
		type: "getOne",
		resource: `orders/${orderId}/documents`,
		payload: {id: record.id},
	});

    useEffect(() => {
		if (error) notifyError(error);
	}, [ error ]);

	if (loading) {
		return <LoadingSpinner />;
	}

    return <DocumentsPreviewView data={data} translate={translate} />;
};


const ContentComponent = (props) => (
	<DocumentTextPreviewFetch 
		{...props}
		basePath="/orders" 
		resource="orders" 
	/>
);

const DocumentsPreview = (props) => {
	const classes = useStyles();

	const PreviewOpenButton = (openButtonProps) => (
		<Typography variant="body2" component="span" className={classes.parsed} {...openButtonProps}>
			{props.translate(
				props.parsed_status ? 
					"osfc.fields.document.text_popup.parsed" 
				: 
					"osfc.fields.document.text_popup.is_empty"
			)}
		</Typography>
	);

	return (
		<QuickPreview 
			contentProps={props}
			PreviewOpenButtonComponent={PreviewOpenButton}
			ContentComponent={ContentComponent}
		/>
	);
};


const DocumentTextParsedStatus = ({ data, ...props }) => {
    const translate = useTranslate();
	const classes = useStyles();

	if (data?.parsed_status === undefined) return null;
	
	return (
		<React.Fragment>
			{data.parsed_status < 0 ? 
                <Typography variant="body2" component="span" className={classes.notParsed}>
                    {translate("osfc.fields.document.text_popup.not_parsed")}
                </Typography> 
			:
                <DocumentsPreview 
					orderId={data.order_id} record={data} 
					parsed_status={data.parsed_status} 
					translate={translate} 
				/>
			}
		</React.Fragment>
	);
};


export default DocumentTextParsedStatus;
