import React from 'react';
import { useState } from 'react';
import {Confirm, useRefresh, useNotify, useMutation, useTranslate} from 'react-admin';

import {makeStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box'
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';

import { OrderDocumentOutputType } from '../../../entities';

// TODO: move codes color to sahred place
const useStyles = makeStyles(t => ({	
	unloadButton: {
		backgroundColor: "rgba(183,255,125,0.2)"
	},
	loadButton: {
		backgroundColor: "rgba(255,140,140,0.1)"
	},
}));

export const GenerateDocumentsOutputButton = ({type, disabled, order, buttonText, margin, isOutputDoc}) => {
    const [open, setOpen] = useState(false);
	const refresh = useRefresh();
    const notify = useNotify();
	const translate = useTranslate();
	const classes = useStyles();

	const type_name = Object.keys(OrderDocumentOutputType).find(key => OrderDocumentOutputType[key] === type);

	let date = new Date();
	let offset = date.getTimezoneOffset();

	const [approve, { isLoading }] = useMutation({
        type: 'create',
        resource: `orders/${order.id}/documents-output`,
    }, {
		onSuccess: () => {
			handleDialogClose();
			notify(`osfc.dialogs.confirm_generate_doc.${type_name}.success`, "info");
			refresh();
		},
		onFailure: (error) => {
			notify(error.message, "error");
			refresh();
		}
	});

	const handleClick = (e) => {
		e.stopPropagation();
		if (isOutputDoc){
			setOpen(true)
		}
		else{
			approve({
				payload: {
					data: {
						type: type, 
						offset: (offset / 60) * -1
					},
				}
			})
		}	
	};

    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
		approve({
			payload: {
				data: {
					type: type,
					offset: (offset / 60) * -1
				},
			}
		})
        setOpen(false);
    };
	return (
        <Box m={margin}>
			{/* <Button variant="contained" className={isOutputDoc ? classes.loadButton : classes.unloadButton} onClick={handleClick} endIcon={type === OrderDocumentOutputType.loading ? <GetAppIcon/> : <PublishIcon/>}>{translate(buttonText)}</Button> */}

			<Button variant="contained" disabled={disabled} className={isOutputDoc ? classes.loadButton : classes.unloadButton} onClick={handleClick}>{translate(buttonText)}</Button>
				{isOutputDoc &&
				<Confirm
					isOpen={open}
					loading={isLoading}
					title={`osfc.dialogs.confirm_regenerate_doc.${type_name}.confirm.title`}
					content={`osfc.dialogs.confirm_regenerate_doc.${type_name}.confirm.content`}
					onConfirm={handleConfirm}
					onClose={handleDialogClose}
					confirm="osfc.buttons.confirm"
					confirmColor='warning'
				/>}
		</Box>	
	);
};