export function resizeElementHeight(element, shift = 0) {
	let height = 0;
	const body = window.document.body;
	if (window.innerHeight) {
		height = window.innerHeight;
	} else if (body.parentElement.clientHeight) {
		height = body.parentElement.clientHeight;
	} else if (body && body.clientHeight) {
		height = body.clientHeight;
	}
	element.style.height = ((height - element.offsetTop + shift) + "px");
	return height;
}
