import React from 'react';

import { OrderService } from '../../entities/OrderService';
import { isEmpty } from '../../utils/validation/required';


const orderWarningsMessageByFieldName = {
    "pallets": "osfc.dialogs.order_warnings.content.pallets_empty",
    "kilos": "osfc.dialogs.order_warnings.content.kilos_empty",
};

export const orderWarningValidations = {
	"pallets": (values => (isEmpty(values["pallets"]) && [OrderService.reloadCarCar, OrderService.reloadCarTerminalCar].includes(values["service"]) ? orderWarningsMessageByFieldName["pallets"] : null)),	
    "kilos": (values => (isEmpty(values["kilos"]) && [OrderService.intoPlukkStorage].includes(values["service"]) ? orderWarningsMessageByFieldName["kilos"] : null))
};