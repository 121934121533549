export const DatagridWithBlockProps = {
	rowStyle: (record, index) => {
		return {
			backgroundColor: record.is_blocked && "silver",
		};
	}
};

export const DatagridWithBlockDeleteProps = {
	rowStyle: (record, index) => {
		return {
			backgroundColor: (record.is_blocked) && "silver",
			color: (record.is_deleted) && "#616161",
		};
	}
};

