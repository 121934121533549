import React from "react";

import { Create, SimpleForm } from 'react-admin';

import { UserRole } from '../../../entities/';

import { SaveWithCancelToolbar } from '../../../components/toolbars/SaveWithCancelToolbar';
import { TrailersCreateForm } from './CreateForm';


export const TrailersCreate = ({permissions, ...props}) => {
	const isAdminOrOSFCManager = permissions && (
		permissions.role === UserRole.osfc_manager || 
		permissions.role === UserRole.admin
	);

	return (
		<Create {...props}>
			<SimpleForm submitOnEnter={false} toolbar={<SaveWithCancelToolbar />} redirect="list">
				<TrailersCreateForm isAdminOrOSFCManager={isAdminOrOSFCManager} />
			</SimpleForm>
		</Create>
	);
};
