import React from "react";
import {
    TextInput, required
} from 'react-admin';
import { GridForm, GridInput } from '../../../components/GridForm';
import { PhoneInput } from "../../../components/inputs";

const requiredValidator = [required()];

export const SMSCreateForm = props => (

	<GridForm {...props}>
		<GridInput sm={4} component={TextInput} source="sender" />
        <GridInput sm={4} source="recipient" component={PhoneInput} validate={requiredValidator}/>
        <GridInput sm={10} component={TextInput} source="text" multiline rows={6}/>
	</GridForm>
);
