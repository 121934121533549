
import React from 'react';

// import MaterialUiPhoneNumber from 'material-ui-phone-number';

import PhoneNumberInput from '../PhoneNumber/PhoneNumberInput';

import { CustomTextInput } from './_helpers/CustomTextInput';

// const isValid = (inputNumber) => {   
//     return true;
// }

const validatePhone = (inputNumber, allValues) => {
    if (!inputNumber) return;
    inputNumber = inputNumber.replace(/\D/g, '');
    // to many countries
    // TODO: gat coutry data from MaterialUiPhoneNumber
    // for (const key in dataValidation) {
    //     if (inputNumber.startsWith(dataValidation[key].dialCode)){
    //         let data = dataValidation[key];
    //         if (inputNumber.length != data.length){
    //             return { message: 'Phone number for this country must contains %{length} digits.', args: { length: data.length } }; 
    //         } else {
    //             return;
    //         }
    //     }        
    // }
    if (inputNumber.length < 8 || inputNumber.length > 12){
        return { message: 'Phone number must contains %{length} digits.', args: { length: '[8-12]' } }; 
    }
    return;
}

export const PhoneInput = props => {
    return (
        <CustomTextInput
            muiTextField={PhoneNumberInput}			
            // onlyCountries={Object.keys(dataValidation)}
            regions='europe'
            // defaultCountry='no'            
            // isValid={isValid}
            validate={validatePhone}
            // prase doesn't work correct with MaterialUiPhoneNumber
            // parse on server-side
            // parse={parseDigits}
            disableDropdown={false}
            {...props}
        />
    );
}
