export const OrderService = {
	reloadCarCar: 1,
	reloadCarTerminalCar: 2,
	intoPlukkStorage: 3,
}

export const OrderServiceNames = {
	[OrderService.reloadCarCar]: "Reload Car - Car",
	[OrderService.reloadCarTerminalCar]: "Reload Car - Terminal - Car",
	[OrderService.intoPlukkStorage]: "Plukk",
}


export const OrderServices = {
	[OrderService.reloadCarCar]: {
		full_name: "Reload Car - Car",
		short_name: "C - C",
	},
	[OrderService.reloadCarTerminalCar]: {
		full_name: "Reload Car - Terminal - Car",
		short_name: "C - T - C",
	},
	[OrderService.intoPlukkStorage]: {
		full_name: "Plukk",
		short_name: "Plukk",
	},
}
