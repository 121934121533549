import React from 'react';

import { 
	SimpleShowLayout, ReferenceField, TextField, 
	EmailField, usePermissions, useGetOne
} from 'react-admin';

import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { formatPhoneNumber, PhoneField } from './PhoneField';
import { LoadingSpinner } from '../LoadingSpinner';
import { UserRoleByCompanyType } from '../../entities';

import QuickPreview from '../CustomDrawer';


export const formatDriverWithPhone = (record) => (
	`${record.first_name} ${record.last_name} | ${formatPhoneNumber(record.phone)}`
);

export const formatDriverLabel = (record) => (
	`${record.first_name} ${record.last_name}`
);


const DriverPreviewView = ({ classes, ...props }) => (
	<SimpleShowLayout {...props}>
		<TextField source="first_name" className={classes.field} />
		<TextField source="last_name" className={classes.field} />
		<ReferenceField source="truck_id" reference="trucks" link={false} className={classes.field}>
			<TextField source="name" />
		</ReferenceField>
		<EmailField source="email" className={classes.field} />
		<PhoneField source="phone" className={classes.field} addLabel={true} />
	</SimpleShowLayout>
);

const driverStyles = withStyles(theme => ({
	field: {
		'& span': {
			display: 'inline-block',
			maxWidth: '20em'
		}
	}
}));

const DriverPreview = driverStyles(DriverPreviewView);


export const DriverPreviewFetch = ({ orderData, driverData, ...props }) => {
	const driverId = driverData.id
	const { data, loading } = useGetOne(props.resource, driverId);

	if (loading) {
		return <LoadingSpinner />;
	}

	return (
		<DriverPreview {...props} id={driverId} record={data} />
	);
};


const ContentComponent = ({ record, refSource, ...props}) => (
	<DriverPreviewFetch
		{...props}
		basePath="/drivers"
		resource="drivers"
		driverData={{ id: record[refSource] }}
		orderData={record}
	/>
);

const TransDriverField = ({ source, refSource, record, prefix, formatLabel, ...props }) => {
	const { permissions } = usePermissions();

	if (!permissions || !prefix) return null;

	const is3RdPartyBlock = UserRoleByCompanyType.customer_and_transporter_roles.includes(permissions.role) &&
	record[`${prefix}_3rd_party`] === permissions.company_id;

	if (is3RdPartyBlock) return null;

	if (!record[refSource]) {
		return (
			<Typography component="span" variant="body2">
				{record[source]}
			</Typography>
		);
	};

	return (
		<QuickPreview 
			formatLabel={() => formatLabel(record)}
			contentProps={{ record, refSource }}
			ContentComponent={ContentComponent}
		/>
	);
};

TransDriverField.defaultProps = {
	addLabel: true,
};

export default TransDriverField;