export const OutDocumenStatus = {
    none: 0,
    inhouse: 1,
    intime: 2,
    in2hours: 3,
    late: 4,
    missing: 5,
    missingtime: 6,
    missingpn: 7
};
