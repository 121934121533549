import React from "react";
import { ReferenceInput, TextInput, SelectInput, required, useTranslate, BooleanInput } from 'react-admin';

import { FormGroupGrid, GridForm, GridInput } from '../../../components/GridForm';
import { PhoneInput, EmailInput } from '../../../components/inputs';
import { SelectUppercaseInput } from '../../../components/inputs/SelectUppercaseInput';
import { SysNotifTypeChoices, SysNotifEventsChoices } from '../../../entities/SysNotifTypes';


const requiredValidator = [required()];


export const CustomerDriverEditForm = props => {
	const t = useTranslate();

	const notifTypeChoices = SysNotifTypeChoices.map(el => ({id: el.id, name: t(`osfc.enums.sysnotiftype.${el.name}`)}));
	const notifEventChoices = SysNotifEventsChoices.map(el => ({id: el.id, name: t(`osfc.enums.sysnotifevent.${el.name}`)}));

	return <GridForm {...props}>
		<FormGroupGrid>
			<GridInput sm={2} component={TextInput} source="id" disabled />
			<GridInput sm={5} component={TextInput} source="first_name" validate={requiredValidator} autoFocus />
			<GridInput sm={5} component={TextInput} source="last_name" validate={requiredValidator} />
		</FormGroupGrid>

		<FormGroupGrid>
			<GridInput sm={6}>
				<ReferenceInput source="truck_id" reference="trucks" filter={{ no_range: true, is_deleted: false }} allowEmpty>
					<SelectUppercaseInput emptyValue="" resettable optionText="name"  />
				</ReferenceInput>
			</GridInput>
			<GridInput sm={6} component={TextInput} source="trailer_number" />
		</FormGroupGrid>

		<FormGroupGrid>
			<GridInput sm={6} component={PhoneInput} source="phone" validate={requiredValidator} />
			<GridInput sm={6} component={EmailInput} source="email" validate={requiredValidator} />
		</FormGroupGrid>

		<FormGroupGrid>
			<GridInput sm={3} component={SelectInput} source="sys_notif_type" choices={notifTypeChoices} />
			<GridInput sm={3} component={SelectInput} source="sys_notif_events" choices={notifEventChoices} />
		</FormGroupGrid>
		<FormGroupGrid>
			<GridInput sm={6}>
				<BooleanInput source="can_edit_only_time"/>
			</GridInput>
		</FormGroupGrid>
	</GridForm>
};
