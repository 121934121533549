import React from 'react';
import { format } from '@buttercup/react-formatted-input';


export const makeFormattedHook = (formatter) => (str) => {
	return React.useMemo(() => formatter(str), [str]);
}

export const makeFormatter = (pattern, postFormatted) => (str) => {
	if (!str) return '';
	const result = format(str, pattern).formatted;
	if (result && postFormatted !== undefined) {
		return postFormatted(result);
	}
	return result || '';
}
