import DriveEtaIcon from '@material-ui/icons/DriveEta';

import { TrucksCreate } from './Create/Create';
import { TrucksEdit } from './Edit/Edit';
import { TrucksList } from './List/List';
import { TrucksShow } from './Show/Show';


export {
	TrucksList,
	TrucksCreate,
	TrucksEdit,
	TrucksShow,
};

export const transportTrucksResource = {
	list: TrucksList,
	edit: TrucksEdit,
	create: TrucksCreate,
	show: TrucksShow,
	icon: DriveEtaIcon,
};
