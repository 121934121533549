import React from "react";
import { TextInput, NumberInput } from 'react-admin';
import { required, minValue, minLength, maxLength } from 'react-admin';

import { GridForm, GridInput } from '../../../components/GridForm/';

const templateParse = (v) => {
	if (v) {
		v = v.substring(0, 4);
		const match = v.match(/\b[\d|x]{1,4}\b/gi);
		// const match = v.match(/\d|x/gi);
		// const match = v.match(/^([\dxX]{1,4}).*$/gi);
		return match && match.join('');
	}
	return v;
};

export const ArticleTemplateBaseForm = props => (
	<GridForm {...props}>		
		<GridInput sm={6} component={TextInput} source="template" parse={templateParse} validate={[required(), minLength(4), maxLength(4)]} autoFocus />
		<GridInput sm={6} component={NumberInput } source="load_time" min={0} validate={[required(), minValue(0)]} />		
	</GridForm>
);
