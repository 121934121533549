import React from "react";

import { ReferenceManyField, Pagination } from "react-admin";

import { HistoryDatagrid } from './HistoryGrid';
import { OrderArticleHistoryGrid } from './OrderPricelistHistoryGrid';


export const HistoryField = ({children, ...props}) => (
	<ReferenceManyField {...props} perPage={15} 
						pagination={<Pagination rowsPerPageOptions={[5, 15, 25, 50, 100]} />}>
		<HistoryDatagrid children={children} />
	</ReferenceManyField>
);


export const OrderPricelistHistoryField = (props) => (
	<ReferenceManyField {...props} perPage={-1} 
						source="original_id" reference="order-pricelist-history" target="original_id">
		<HistoryDatagrid children={<OrderArticleHistoryGrid />} noWraps={true} />
	</ReferenceManyField>
)


HistoryField.defaultProps = {
	addLabel: true,
	className: "ra-field-datagrid",
};

OrderPricelistHistoryField.defaultProps = {
	addLabel: true,
	className: "ra-field-datagrid",
};
