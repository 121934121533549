import React from 'react';

import { List, Datagrid, TextField, SelectField, Pagination } from 'react-admin';

import { ScrollingWrapper } from '../../../components/ScrollingWrapper';
import { DatagridWithBlockDeleteProps } from '../../../components/_helpers/props/DatagridProps';
import { DateTimeField } from '../../../components/fields/DateFormatField';

import { AdminLogsFilter } from './ListFilters';

import { useEnumSelectChoices } from '../../../components/_helpers/useEnumSelectChoices';
import { AdminLogLevelType } from '../../../entities/AdminLogLevelType';
import { AdminLogModuleType } from '../../../entities/AdminLogModuleType';


const defaultSort = { field: 'created_at', order: 'DESC' };


export const AdminLogsList = props => {
	const adminLogLevelChoices = useEnumSelectChoices(AdminLogLevelType, "log-level")
	const adminModuleChoices = useEnumSelectChoices(AdminLogModuleType, "log-module")

	return (
		<List
			{...props}
			sort={defaultSort}
			exporter={false}
			pagination={<Pagination rowsPerPageOptions={[100, 200, 500]} />}
			bulkActionButtons={false}
			filters={<AdminLogsFilter />}
			// empty={<Empty/>}
		>
			<ScrollingWrapper>
				<Datagrid
					{...DatagridWithBlockDeleteProps}
					rowClick='show'
				>
					<TextField source='id' sortable={false}/>
					<DateTimeField source='created_at' withLocalTimeZone/>
					<TextField source='order_id'/>
					<SelectField source='log_level' choices={adminLogLevelChoices}/>
					<SelectField source='module' choices={adminModuleChoices}/>
					<TextField source='description' sortable={false}/>
				</Datagrid>
			</ScrollingWrapper>
		</List>
	);
};
