import { useCallback } from 'react';
import { useMutation } from 'react-admin';

const useUpdatePartial = (
    resource,
    id,
    data,
    previousData,
    options,
    requestMethod = "updatePartial"
) => {
    const [mutate, state] = useMutation(
        { type: requestMethod, resource, payload: { id, data, previousData } },
        options
    );

    const update = useCallback(
        (
            resource,
            id,
            data,
            previousData,
            options
        ) => {
            if (typeof resource === 'string') {
                const query = {
                    type: requestMethod,
                    resource,
                    payload: {
                        id,
                        data,
                        previousData,
                    },
                };
                return mutate(query, options);
            } else {
                return mutate(
                    resource,
                    id
                );
            }
        },
        [mutate] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return [update, state];
};

export default useUpdatePartial;
