export const SysNotifType = {
	none: 0,
	email: 1, 
	sms: 2, 
	both: 3, 
};

export const SysNotifEvents = {
	assign: 1, 
	slot: 2, 
	both: 3, 
};

export const SysNotifTypeChoices = [
	{
		id: 0, 
		name: 'none', 
	}, 
	{
		id: 3, 
		name: 'both', 
	}, 
	{
		id: 1, 
		name: 'email', 
	}, 
	{
		id: 2, 
		name: 'sms', 
	}, 
];

export const SysNotifEventsChoices = [
	{
		id: 0, 
		name: 'none', 
	}, 
	{
		id: 1, 
		name: 'assign', 
	}, 
	{
		id: 2, 
		name: 'slot', 
	},
	{
		id: 3, 
		name: 'both', 
	}, 
];
