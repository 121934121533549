import React from 'react';
import { TextInput, required } from 'react-admin';
import { useFormState, useForm } from 'react-final-form';
import Button from '@material-ui/core/Button';

import { GridForm, GridInput, FormGroupGrid } from '../../../components/GridForm';
import { DateInput } from '../../../components/inputs';
import { AdminActionName } from '../../../entities/AdminActionName';
import { AdminActionNameSelect } from '../components/AdminActionNameSelect';
import { DocumentOutputTypeSelect } from '../components/DocumentOutputTypeSelect';

const AdminActionSectoin = ({onApply, children, ...props}) => {
	const { values } = useFormState();

	const handleClickApply = (ev) => {
		console.log(values);
		const res = {};
		const arrayChildren = React.Children.toArray(children);
		React.Children.map(arrayChildren, (child, index) => {
			const key = child.props["source"];
			res[key] = values[key] !== undefined ? values[key] : null
		});
		// for (const child of arrayChildren) {
		// 	const key = child["source"];
		// 	res[key] = values[key] !== undefined ? values[key] : null
		// }		
		onApply(res);
	};
	return <FormGroupGrid sm={12} title="test" {...props}>
		{children}		
		<Button variant="text" color="primary" onClick={handleClickApply} >Apply</Button>
	</FormGroupGrid>;
};

export const AdminActionCreateForm = (props) => {
	const form = useForm();
	const { values } = useFormState();

	const handleApply = (res) => {
		console.log(res);
		const json = JSON.stringify(res);
		form.change("data", json)
	};

	const handleActionNameChange = (event) =>{
		// const val = event.target.value
		for (const key of Object.keys(values)) {
			if (key !== "name") {
				form.change(key, null)
			}
		}
	};

	return <GridForm {...props}>		
		<GridInput sm={12} component={AdminActionNameSelect} source='name' onChange={handleActionNameChange} validate={[required()]} />

		{values["name"] === AdminActionName.regen_out_doc ?
		<AdminActionSectoin onApply={handleApply}>
			<GridInput xs={12} sm={6} component={DateInput} source="date_from" />
			<GridInput xs={12} sm={6} component={DateInput} source="date_to" />
			<GridInput xs={12} sm={8} component={DocumentOutputTypeSelect} source="doc_out_type" />
		</AdminActionSectoin> : null}

		{values["name"] === AdminActionName.try_gen_out_doc ?
		<AdminActionSectoin onApply={handleApply}>
			<GridInput xs={12} sm={6} component={DateInput} source="date_from" />
			<GridInput xs={12} sm={6} component={DateInput} source="date_to" />
			<GridInput xs={12} sm={8} component={DocumentOutputTypeSelect} source="doc_out_type" resettable={false} validate={[required()]} />
		</AdminActionSectoin> : null}	

		<GridInput sm={12} component={TextInput} source='data' multiline rows={6}/>		
	</GridForm>;
};