import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import {
	parseDateFromDatetimeString,
	parseTimeFromDatetimeString,
	compareDatesFromDatetimeString
} from '../../../components/_helpers/dateTime';

const useStyles = makeStyles(({palette, spacing}) => {
	const radius = spacing(2.5);

	return {
		date: {
			margin: spacing(1, 0, 0, 0),
			padding: spacing(1, 2),
			borderRadius: radius,
			display: 'inline-block',
			wordBreak: 'break-word',
			backgroundColor: palette.grey[100],
		},
		message: {
			padding: spacing(1, 2),
			borderRadius: radius,
			display: 'inline-block',
			wordBreak: 'break-word',
		},
		messageLeft: {
			backgroundColor: palette.info.light,
		},
		messageRight: {
			backgroundColor: palette.success.light,

		},
		timeLeft: {
			color: palette.info.dark,
		},
		timeRight: {
			color: palette.success.dark,
		},
	};
});
export const UsernameBlock = ({fullName, side, isMyMessage}) => {
	const styles = useStyles();
	if (fullName) {
		return (<Typography align={side} variant="body2" className={isMyMessage ? styles.timeRight : styles.timeLeft}>
			{fullName}:
		</Typography>);
	} else {
		return null;
	}
}

export const ChatMessage = ({message, time, fullName, side, isMyMessage}) => {
	const styles = useStyles();
	return (<Grid container item xs={11} sm={10} md={8} lg={6} justify={isMyMessage ? 'flex-end' : 'flex-start'}>
		<Box className={`${styles.message} ${isMyMessage ? styles.messageRight : styles.messageLeft}`}>
			<UsernameBlock fullName={fullName} side={side} isMyMessage={isMyMessage}/>
			<Typography>
				{message}
			</Typography>
			<Typography align={side} variant="body2" className={isMyMessage ? styles.timeRight : styles.timeLeft}>
				{time}
			</Typography>
		</Box>
	</Grid>);
};

export const ChatMessageBlock = ({message, messages, myUserId, index}) => {
	const styles = useStyles();
	const isMyMessage = message.sender_id === myUserId;
	const side = isMyMessage ? "right" : "left";
	const justify = isMyMessage ? 'flex-end' : 'flex-start';
	const isNeedDate = index === 0 || (compareDatesFromDatetimeString(messages[index - 1].created_at, message.created_at)) !== true;
	const fullNameOnMessage = !isMyMessage && (isNeedDate || messages[index - 1].sender_id !== message.sender_id) ?
		message.full_name : null;
	return (
		<Grid
			container
			spacing={2}
			justify={justify}
		>
			{isNeedDate &&
				<Grid container item xs={12} justify={"center"}>
					<Typography align={"center"} className={styles.date}>
						{parseDateFromDatetimeString(message.created_at)}
					</Typography>
				</Grid>
			}
			<ChatMessage
				message={message.body}
				time={parseTimeFromDatetimeString(message.created_at)}
				fullName={fullNameOnMessage}
				side={side}
				isMyMessage={isMyMessage}
			/>
		</Grid>
	);
}

ChatMessageBlock.propTypes = {
	message: PropTypes.shape(
		{
			id: PropTypes.number,
			full_name: PropTypes.string,
			sender_id: PropTypes.number,
			body: PropTypes.string,
			created_at: PropTypes.string,
		}
	).isRequired,
	messages: PropTypes.arrayOf(PropTypes.message), myUserId: PropTypes.number, index: PropTypes.number,
};

