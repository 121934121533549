import React from 'react';
import { SelectInput } from 'react-admin';
import { CoolerType, OrderService, OrderServiceNames } from '../../../../entities';
import { useForm } from 'react-final-form';


export const serviceChoices = [
	{id: OrderService.reloadCarCar, name: OrderServiceNames[OrderService.reloadCarCar]},
	{id: OrderService.reloadCarTerminalCar, name: OrderServiceNames[OrderService.reloadCarTerminalCar]},
	{id: OrderService.intoPlukkStorage, name: OrderServiceNames[OrderService.intoPlukkStorage]},
];

export const ServiceInputRaw = props => {
	return (
		<SelectInput {...props} choices={serviceChoices} />
	);
}

export const ServiceInput = props => {
	const form = useForm();

	const handleServiceChange = (e) => {
		const service = e.target.value;
		switch (service) {
			case OrderService.reloadCarCar:
				form.change('cooler_type_id', CoolerType.other);
				break;
			case OrderService.reloadCarTerminalCar:
				form.change('cooler_type_id', CoolerType.cooler);
				break;
			case OrderService.intoPlukkStorage:
				form.change('cooler_type_id', CoolerType.cooler);
				break;
			default:
				break;
		}
		form.change('service', service);
	}

	return (
		<ServiceInputRaw {...props} onChange={handleServiceChange} />
	);
};
