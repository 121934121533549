import React from 'react';

import { ImportButton } from "react-admin-import-csv";


export const ImportButtonDefaultConfig = {
	// Enable logging
	logging: false,

	// Disable the attempt to use "createMany", will instead just use "create" calls
	disableCreateMany: false,

	// Disable the attempt to use "updateMany", will instead just use "update" calls
	disableUpdateMany: false,

	// Disable the attempt to use "getMany", will instead just use "getSingle" calls
	disableGetMany: false,

	// Disable "import new" button
	disableImportNew: false,

	// Disable "import overwrite" button
	disableImportOverwrite: false,

	// A function to translate the CSV rows on import
	// preCommitCallback?: (action: "create" | "overwrite", values: any[]) => Promise<any[]>;

	// A function to handle row errors after import
	// postCommitCallback?: (error: any) => void;

	// Transform rows before anything is sent to dataprovider
	// transformRows?: (csvRows: any[]) => Promise<any[]>;

	// Async function to Validate a row, reject the promise if it's not valid
	// validateRow?: (csvRowItem: any) => Promise<void>;

	// Any option from the "papaparse" library
	parseConfig: {
		// For all options see: https://www.papaparse.com/docs#config
		dynamicTyping: true,
	},
};


const CSVImportButton = (props) => {
	return (
		<ImportButton 
			{...ImportButtonDefaultConfig} 
			{...props} 
		/>
	);
};


export default CSVImportButton;