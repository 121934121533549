import ViewColumnIcon from '@material-ui/icons/ViewColumn';

import { ArticleTemplatesList } from './List/List';
import { ArticleTemplateCreate } from './Create/Create';
import { ArticleTemplateEdit } from './Edit/Edit';

export {
	ArticleTemplatesList,
	ArticleTemplateCreate,
	ArticleTemplateEdit,
}


export const articleTemplatesResource = {
	list: ArticleTemplatesList,
	create: ArticleTemplateCreate,
	edit: ArticleTemplateEdit,	
	icon: ViewColumnIcon,
}
