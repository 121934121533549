import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SelectInput, ReferenceInput, useTranslate } from 'react-admin';


export const choices = [
    { "id": 1, "name": "1" },
    { "id": 2, "name": "2" },
    { "id": 3, "name": "3" },
    { "id": 4, "name": "4" },
    { "id": 5, "name": "5" },
    { "id": 6, "name": "6" },
    { "id": 7, "name": "7" },
    { "id": 8, "name": "8" },
    { "id": 9, "name": "9" },
    { "id": 10, "name": "10" },
    { "id": 11, "name": "11" },
    { "id": 12, "name": "12" },
    { "id": 13, "name": "13" },
    { "id": 14, "name": "14" },
    { "id": 15, "name": "15" },
    { "id": 16, "name": "16" },
];


const useStyles = makeStyles({
	list: {
		"& > .Mui-disabled": {
			// color: "red",
			opacity: 0.7,
			backgroundColor: "rgba(255,0,0,0.5)",
		}
	}
});


export const PortInput = (props) => {
	const classes = useStyles();
    const translate = useTranslate()

    return (
		<ReferenceInput {...props} allowEmpty reference="ports" label={translate("resources.orders.fields." + props.source)}
            //  format={value => value === 0 ? '' : value} parse={value => value === '' ? 0 : value}>
            >
			<SelectInput SelectProps={{ MenuProps: { classes: classes } }} emptyValue="" optionText="name" disableValue="disabled_by_any" />
		</ReferenceInput>
	);
};
