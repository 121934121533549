import TranslateIcon from '@material-ui/icons/Translate'

import { CustomersList } from './List/List';

export {
	CustomersList,
}

export const translationsResource = {
	list: CustomersList,
	icon: TranslateIcon,
}
