import React, { useRef, useEffect } from 'react';

export const CssFieldCellWrapper = ({children, cssFns, cssClass, ...props}) => {  
    const ref = useRef(null);
    const parent = ref?.current?.parentNode;
    const record = props["record"];
    
    useEffect(() => {
        if (parent && record && cssFns) {
            // const fnsClass = cssFns.map(fn => fn(record)).filter(x => x);
            const fnsClass = cssFns.map(fn => fn(record));
            if (fnsClass.length) {
                parent.classList.add(...fnsClass);
                if (cssClass && cssClass.length) {
                    parent.classList.add(...cssClass);
                }
            }
            
        }
    }, [parent, record, cssFns, cssClass]);

    return (
        <div ref={ref}>
            {React.cloneElement(children, props)}
        </div>
    );
};

export const CssRowFieldCellWrapper = ({cssFn, ...props}) => {  
    const ref = useRef(null);
    const parent = ref?.current?.parentNode.parentNode;
    const record = props["record"];
    
    useEffect(() => {
        if (parent && record) {
            const css = cssFn(record);
            if (css.length) {
                parent.classList.add(...css);                
            }
            
        }
    }, [parent, record, cssFn]);

    return (
        <div ref={ref}></div>
    );
};
  
export const CssFieldCellWrapperOrderAsTrans = ({userCompanyId, prefix, ...props}) => {  
    const cssFns = [
        rec => (userCompanyId && userCompanyId !== rec["company_id"]) && 
               (rec[`${prefix}_3rd_party`] && userCompanyId === rec[`${prefix}_3rd_party`]) ? "order-as-trans" : null
    ];

    return <CssFieldCellWrapper cssFns={cssFns} {...props} />;
};