import React from 'react';

import { Show, TextField, EmailField, ReferenceField, SimpleShowLayout, SelectField, useTranslate } from 'react-admin';

import { PhoneField } from '../../../components/fields/PhoneField';
import { ActionsWithBackButton } from '../../../components/actionbars/ActionsWithBackButton';

import { SysNotifTypeChoices, SysNotifEventsChoices } from '../../../entities/SysNotifTypes';


export const DriverShow = (props) => {
	const t = useTranslate();

	const notifTypeChoices = SysNotifTypeChoices.map(el => ({id: el.id, name: t(`osfc.enums.sysnotiftype.${el.name}`)}));
	const notifEventChoices = SysNotifEventsChoices.map(el => ({id: el.id, name: t(`osfc.enums.sysnotifevent.${el.name}`)}));

	return (
		<Show actions={<ActionsWithBackButton />} {...props}>
			<SimpleShowLayout>
				<TextField source="id" />
				<TextField source="first_name" />
				<TextField source="last_name" />
				<ReferenceField source="truck_id" reference={"trucks"} link={false}>
					<TextField source="name" />
				</ReferenceField>
				<EmailField source="email" />
				<PhoneField source="phone" />
				<SelectField source="sys_notif_type" choices={notifTypeChoices} />
				<SelectField source="sys_notif_events" choices={notifEventChoices} />
			</SimpleShowLayout>
		</Show>
	);
};
