import React from "react";
import { Edit, SimpleForm } from 'react-admin';

import { SaveWithCancelWithPasswordChangeToolbar } from '../../../components/toolbars/SaveWithCancelWithPasswordChangeToolbar';
import { CustomerEditForm } from './EditForm';


export const CustomerEdit = (props) => (
	<Edit {...props} undoable={false}>
		<SimpleForm submitOnEnter={false} toolbar={<SaveWithCancelWithPasswordChangeToolbar />}>
			<CustomerEditForm />
		</SimpleForm>
	</Edit>
);
