import React from "react";
import { List, Datagrid, TextField, Loading, ReferenceField } from 'react-admin';

import { ScrollingWrapper } from '../../../components/ScrollingWrapper';
import { DatagridWithBlockProps } from '../../../components/_helpers/props/DatagridProps';
import { PriceField } from '../../../components/fields/PriceField';

import { SpecialPricesField } from './SpecialPriceField';
import { ArticlesActions } from './Actions';
import { ArticlesFilters, useArticlesFilterDefaultValues } from './ListFilters';


const defaultSort = { field: 'code', order: 'DESC' };


const articleRowStyle = (record) => {				
	return {
		backgroundColor: record.color,
	};
};


export const ArticlesList = props => {
	const filterDefaultValues = useArticlesFilterDefaultValues(props.entityName);

	if (!props.permissions || filterDefaultValues === undefined) {
		return <Loading loadingSecondary="" />;
	};

	return (
		<List
			{...props}
			exporter={false}
			empty={false}
			bulkActionButtons={false}
			sort={defaultSort}
			pagination={null}
			filterDefaultValues={filterDefaultValues}
			filters={<ArticlesFilters />}
			actions={props.options.hasUpload ? <ArticlesActions /> : null}
		>
			<ScrollingWrapper>
				<Datagrid
					{...DatagridWithBlockProps}
					// rowClick="show"
					rowStyle={articleRowStyle}
					rowClick={null}
					size="small"
				>
					{/* <TextField source="id" sortable={false} /> */}
					<TextField source="code" sortable={false} />

                    <ReferenceField source="terminal_id" reference="terminal" link={false} sortable={false}>
                        <TextField label="Terminal" source="name" />
                    </ReferenceField>

					<TextField source="name" sortable={false} />
					<TextField source="calculation" sortable={false} />
					<PriceField source="price_a" sortable={false} />
					<PriceField source="price_b" sortable={false} />
					<PriceField source="price_c" sortable={false} />
					<PriceField source="price_d" sortable={false} />
					<PriceField source="price_e" sortable={false} />
					<TextField source="service_description" sortable={false} />
					<SpecialPricesField source="special_prices" sortable={false} />
				</Datagrid>
			</ScrollingWrapper>
		</List>
	);
};
