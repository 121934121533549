import React from 'react';

// import { NumberField } from 'react-admin';


export const currencyLocales = 'en-US';
// export const currencyOptions = { style: 'currency', currency: 'Nkr', maximumSignificantDigits: 3 };
export const currencyOptions = { maximumFrictionDigits: 3 };


export const toPrice = (value) => {
	if (value === null || value === undefined) {
		return value;
	}
	return 'Nkr ' + value.toLocaleString(currencyLocales, currencyOptions);
}

export const PriceField = (props) => {
	if (props.record[props.source] === null || props.record[props.source] === undefined) {
		return null;
	}

	return (
		<span>
			{toPrice(props.record[props.source])}
		</span>
	);

	// return (
	// 	<NumberField {...props} locales={currencyLocales} options={currencyOptions} />
	// );
};


PriceField.defaultProps = {
    addLabel: true,
};
