import React from 'react';
import {
	TopToolbar,
	sanitizeListRestProps,
} from 'react-admin';
import { UploadArticlesButton } from './UploadArticlesButton'


export const ArticlesActions = (props) => {
	const {
		className,
		exporter,
		filters,
		...rest
	} = props;

	return (
		<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
			<UploadArticlesButton />
		</TopToolbar>
	);
};
