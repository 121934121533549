import React, {useRef, useEffect} from "react";
import LinearProgress from '@material-ui/core/LinearProgress';
import {Grid, Typography} from '@material-ui/core';
import {usePermissions, useTranslate} from 'react-admin';

import {ChatMessageBlock} from "./ChatMessageBlock";

const ChatConversation = ({messages, loading, ...props}) => {
	const {permissions} = usePermissions();
	const translate = useTranslate();

	const messagesEndRef = useRef(null);

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({behavior: "smooth"});
	}

	useEffect(() => {
		scrollToBottom()
	}, [messages]);

	if (!permissions) {
		return null;
	}
	const myUserId = permissions.id
	if (messages && messages.length) {
		return (
			<Grid {...props}>
				{messages.map((message, index, messages) => {
					return (<ChatMessageBlock key={index} message={message} messages={messages}
											  myUserId={myUserId} index={index}/>)
				})}
				<div ref={messagesEndRef}/>
			</Grid>
		);
	} else if (loading) {
		return (<LinearProgress style={{width: '100%'}} />);
	} else {
		return (
			<Grid container justify={"center"} {...props}>
				<Typography variant="body2">
					{translate("osfc.order-chat.messages.empty")}
				</Typography>
				<div ref={messagesEndRef}/>
			</Grid>
		);
	}
};

export default ChatConversation;
