import ViewColumnIcon from '@material-ui/icons/ViewColumn';

import {PortList, AreaList} from './List/List';
import {PortCreate, AreaCreate} from "./Create/Create";
import {PortEdit, AreaEdit} from "./Edit/Edit";

export const portsResource = {
	list: PortList,
	create: PortCreate,
	edit: PortEdit,
	icon: ViewColumnIcon,
}

export const areasResource = {
	list: AreaList,
	create: AreaCreate,
	edit: AreaEdit,
	icon: ViewColumnIcon,
}
