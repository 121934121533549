import React from 'react';
import { Create, SimpleForm } from 'react-admin';

import { SaveWithCancelToolbar } from '../../../components/toolbars/SaveWithCancelToolbar';
import { HotlinksCreateForm } from './CreateForm';


export const HotlinksCreate = (props) => (
	<Create {...props}>
		<SimpleForm submitOnEnter={false} toolbar={<SaveWithCancelToolbar />} redirect='list'>
			<HotlinksCreateForm />
		</SimpleForm>
	</Create>
);
