import React from 'react';

import { useTranslate, SelectArrayInput } from 'react-admin';

import { SelectEnumInput } from '../../../components/inputs/SelectEnumInput';
import { AdminLogModuleType } from '../../../entities/AdminLogModuleType';

// Info: not used
export const AdminLogModuleSelect = (props) => (
	<SelectEnumInput
		key={"module"} 
		resettable 
		enumName="log-module" 
		enumObj={AdminLogModuleType} 
		style={{minWidth: "240px"}} 
		{...props}/>
);

export const AdminLogModuleMultipleSelect = ({enumObj, enumName, ...props}) => {
	const translate = useTranslate();
	const choices = Object.keys(enumObj).map(key => ({id: enumObj[key], name: translate(`osfc.enums.${enumName}.${key}`)}));

	return <SelectArrayInput
				choices={choices}
				resettable
				{...props}/>
};
