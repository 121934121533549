import React from 'react';
import { Toolbar, SaveButton } from 'react-admin';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import { withStyles } from "@material-ui/core/styles";

import { BackButton } from './BackButton';
import { SaveWithWarningsButton } from './SaveWithWarningsButton';

import SaveButtonEx from '../_extensions/react-admin/ra-ui-materialui/src/button/SaveButtonEx';


const SaveAndShowButton = withStyles(theme => ({
	root: {
		marginLeft: theme.spacing(1),
	}
}))(({ classes, ...props }) => <SaveButton
	{...props}
	icon={<RemoveRedEyeIcon />}
	label="ra.action.save_and_show"
	className={classes.root}
	redirect="show"	
/>);


export const SaveWithCancelToolbar = ({ 
										 children, 
										 hasShow, 
										 useSaveButtonEx = false,
										 saveButtonExProps,
										warningValidations,
										 ...props 
									 }) => {
	return (
		<Toolbar {...props} >
			{useSaveButtonEx || warningValidations ? 
				(warningValidations ? 
					<SaveWithWarningsButton {...saveButtonExProps} warningValidations={warningValidations} />  : 
					<SaveButtonEx {...saveButtonExProps} />) : 
				<SaveButton />}
			{hasShow && <SaveAndShowButton />}		
			<BackButton />
			{children}
		</Toolbar>
	);
};
