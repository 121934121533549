import React from 'react';

import {
	useMutation, FormWithRedirect,
	useTranslate,
} from 'react-admin';

import EditIcon from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useSimpleModalToggle } from '../../../components/dialogs/useSimpleModal';
import { useNotifyError } from '../../../utils/notifiers/useNotifyError';

import { AddEditMapperBody } from './AddEditMapperBody';


export const UpdateMapper = ({ mappers }) => {
	const { open, handleOpen, handleClose } = useSimpleModalToggle();
	const notifyError = useNotifyError();
	const translate = useTranslate();

	const [approve, { loading }] = useMutation({
        type: 'update',
        resource: 'customer-company-mappers',
        payload: { id: mappers.id },
    }, {
		onSuccess: () => {
			handleClose();
		},
		onFailure: (error) => {
			notifyError(error);
		}
	});

	const handleSubmit = (values) => {
		if (values['data'] === null){
			values['data'] = []
		}
		if (values['optional_data'] === null){
			values['optional_data'] = []
		}
		approve({
			payload: {
				id: mappers.id,
				data: values,
			}
		})
	}
	
	return (
		<>
			<IconButton aria-label="edit" size="small" color="default" onClick={handleOpen}>
				<EditIcon color="primary"/>
			</IconButton>
			{open && <Dialog open={true} onClose={handleClose} maxWidth="md">
				<DialogTitle>{translate("resources.order-pricelist.edit.title")} {mappers.mappers_id}</DialogTitle>
				<FormWithRedirect
					record={mappers}
					submitOnEnter={false}
					component={DialogContent}
					save={handleSubmit}
					render={({ handleSubmitWithRedirect, form, record }) => (
						<React.Fragment>
							<AddEditMapperBody form={form} record={record} />
							<DialogActions>
								<Button disabled={loading} onClick={handleClose} color="primary">
									{translate("ra.action.cancel")}
								</Button>
								<Button disabled={loading} onClick={handleSubmitWithRedirect} color="primary">
									{translate("ra.action.update")}
								</Button>
							</DialogActions>
						</React.Fragment>
					)}
				/>
			</Dialog>}
		</>
	);
};
