import React from 'react';

import { SelectInput } from 'react-admin';


export const EmployeeTypeSelectInput = (props) => (
	<SelectInput
		label="resources.osfc-employees.fields.type"
		defaultValue={false}
		choices={[
			{id: false, name: 'Employee'},
			{id: true, name: 'Manager'},
		]}
		{...props}
	/>
);
