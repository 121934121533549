import * as React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { useChoices } from 'ra-core';

const useStyles = makeStyles(
    {
        checkbox: {
            height: 32,
        },
    },
    { name: 'RaCheckboxGroupInputItem' }
);

const CheckboxGroupInputItemEx = props => {
    const {
        classes: classesOverride,
        id,
        choice,
        onChange,
        optionText,
        optionValue,
        options,
        translateChoice,
        value,
        disabled: disabledItem,
        disabledUnchecked,
        disabledOnValues,
        ...rest
    } = props;
    const classes = useStyles(props);
    const { getChoiceText, getChoiceValue } = useChoices({
        optionText,
        optionValue,
        translateChoice,
    });

    const choiceName = getChoiceText(choice);
    const checked = value ?
        value.find(v => v == getChoiceValue(choice)) !== // eslint-disable-line eqeqeq
        undefined : false;
    const disabled = disabledItem || (disabledUnchecked && !checked) || disabledOnValues;

    return (
        <FormControlLabel
            // has t ocommented. in other way it's possible to check on disabled item. strange.
            // htmlFor={`${id}_${getChoiceValue(choice)}`}
            key={getChoiceValue(choice)}
            onChange={onChange}
            disabled={disabled}
            control={
                <Checkbox
                    id={`${id}_${getChoiceValue(choice)}`}
                    color="primary"
                    className={classes.checkbox}                    
                    checked={checked}
                    value={String(getChoiceValue(choice))}
                    {...options}
                    {...rest}                    
                />
            }
            label={choiceName}
        />
    );
};

export default CheckboxGroupInputItemEx;
