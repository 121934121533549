import React from 'react';
import { Edit, SimpleForm } from 'react-admin';

import { SaveWithCancelToolbar } from '../../../components/toolbars/SaveWithCancelToolbar';
import { HotlinksEditForm } from './EditForm';


export const HotlinksEdit = ({permissions, ...props}) => (
	<Edit {...props} undoable={false}>
		<SimpleForm submitOnEnter={false} toolbar={<SaveWithCancelToolbar />}>
			<HotlinksEditForm />
		</SimpleForm>
	</Edit>
);
