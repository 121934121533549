import React from 'react';
import { TextInput, useTranslate } from 'react-admin';

import MUITextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';


export const ReadOnlyInput = (props) => {
	return <TextInput {...props} InputProps={{readOnly: true}} />
}


export const TextReadonlyInput = (props) => {
	const t = useTranslate();
	const value = props.record ? props.record[props.source] : '';
	const label = `resources.${props.resource}.fields.${props.source}`;

	return (
		<MUITextField
			margin={props.margin}
			size={props.size}
			name={props.name}
			variant={props.variant || "filled"}
			fullWidth={props.fullWidth}
			label={t(label)}
			InputProps={{readOnly: true}}
			value={props.value || value}
			disabled={props.disabled}
		/>
	);
}


export const TextReadonlyReferenceInput = ({ loading, ...props }) => {
	const record = props.choices.find(i => i.id === props.input.value);

	if (loading) {
		return (
			<Box display="flex" justifyContent="center" alignItems="flex-end" style={{height: 40}}>
				<LinearProgress style={{width: '100%'}} />
			</Box>
		);
	}

	const value = record ? record[props.optionText] : '';

	return (
		<TextReadonlyInput {...props} value={value} record={record} />
	);
}
