import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useTranslate } from 'react-admin';


export const SimpleDialog = (props) => {
	const translate = useTranslate()

	return (
		<Dialog
			onClose={props.onClose}
			maxWidth={props.size}
			open={props.open}
			scroll="body"
			fullWidth
		>
			<DialogTitle>
				<Box display="flex" flex="1" justifyContent="space-between" alignItems="center">
					<Box>
						<Typography variant="h5">{props.title}</Typography>
					</Box>
					<Box display="flex" flexDirection="row" alignItems="center">
						{props.titleButtons}
					</Box>
				</Box>
			</DialogTitle>
			<DialogContent>
				{props.children}
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose} color="secondary">
					{translate("resources.orders.export-dialog.button.close")}
				</Button>
				{/*<Button onClick={handleClose} color="primary" autoFocus>
					Agree
				</Button>*/}
			</DialogActions>
		</Dialog>
	);
}
