import get from 'lodash/get';


const correctDefaultValue = (value, defaultValue = null) => {
	if ((typeof value === 'string' && value === '') || value == null) {
		return defaultValue;
	};
	return value;
};


export const diffKey = (obj1, obj2, key, equality, prefix, defaultValue) => {
	if (Array.isArray(obj1[key])) {
		const res = obj1[key].reduce((res, el, index) => {
			const obj1val = get(obj1, [key, index]);
			const obj2val = get(obj2, [key, index]);
			return {
				...res,
				...diff(obj1val, obj2val, equality, `${key}[${index}].`)
			};
		}, {});
		return res;
	}
	const getObj1 = correctDefaultValue(get(obj1, key), defaultValue);
	const getObj2 = correctDefaultValue(get(obj2, key), defaultValue);
	return {
		[prefix + key]: getObj1 === getObj2 ? equality : !equality
	}
}

export const diff = (obj1, obj2, equality = true, prefix = '', defaultValue = null) => {
	return Object.keys(obj1).reduce((res, i) => ({
		...res,
		...diffKey(obj1, obj2, i, equality, prefix),
	}), {});
}
