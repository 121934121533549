import enLocale from "date-fns/locale/en-US";

export const props = {
    DateTimeInput: {
        options: {format: 'dd-mm-yyyy'}
        
    },
    MuiDateTimePicker: {
        format: 'dd-mm-yyyy'
    },
    DateTimePicker: {
        format: 'dd-mm-yyyy'
    },
    MuiPickersUtilsProvider: {
        locale: enLocale
    }
};