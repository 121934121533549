export const clipboardTable = (el) => {
	var body = document.body, range, sel;
	const thead = el.querySelector('thead')
	el.removeChild(thead);
	if (document.createRange && window.getSelection) {
		range = document.createRange();
		sel = window.getSelection();
		sel.removeAllRanges();
		try {
			range.selectNodeContents(el);
			sel.addRange(range);
		} catch (e) {
			range.selectNode(el);
			sel.addRange(range);
		}
		document.execCommand("copy");

	} else if (body.createTextRange) {
		range = body.createTextRange();
		range.moveToElementText(el);
		range.select();
		range.execCommand("Copy");
	}
	sel.removeAllRanges();
	el.prepend(thead);
}
