import React from 'react';

import { CheckboxGroupInput, BooleanInput } from 'react-admin';
import { makeStyles } from '@material-ui/styles'

import { Spacer } from '../dialogs/ChangePasswordDialog';
import { SaveButtonWithSaveToEditWithCancelToolbar } from './SaveButtonWithSaveToEditWithCancelToolbar';


// const useStyles = makeStyles(theme => ({
//     root: {
//         marginLeft: theme.spacing(1),
//         // '& .MuiFormGroup-root.MuiFormGroup-row': {
//         //     justifyContent: "flex-end",
//         // }, 
//     },
// }));

// const ClonedFormCopyDocumentsCheckBoxToogle = (props) => {
//     const classes = useStyles();

// 	return (
//         <div className={classes.root}>
//             <CheckboxGroupInput 
//                 {...props} 
//                 // className={classes.root}
//                 label=""
//                 source="is_copy_documents" 
//                 initialValue={[true]}
//                 choices={[
//                     { id: true, name: 'Copy Documents' },
//                 ]} 
//             />            
//         </div>
// 	);
// };

const checkIsFormCloned = () => {
	const sourceString = window.location.href.split('?')[1];
	const urlParams = new URLSearchParams(sourceString);
	const sourceParamsString = urlParams.get('source');
	const originIdValue = JSON.parse(sourceParamsString)?.origin_id;
	return !!originIdValue;
};


export const SaveButtonWithSaveToEditWithCancelWithCopyDocumentsToolbar = (props) => {
	const moreProps = {};

	if (checkIsFormCloned()) {
		moreProps.children = [
			// <Spacer />,
			// <ClonedFormCopyDocumentsCheckBoxToogle />
            <BooleanInput source="is_copy_documents" label="Copy documents" defaultValue={true} style={{ marginTop: "1em", marginLeft: "1em" }} />
		];
	};

	return <SaveButtonWithSaveToEditWithCancelToolbar {...props} {...moreProps} />;
};
