import { makeStyles } from "@material-ui/core/styles";
import { EmailField, TextField } from "react-admin";

import React from "react";
import classnames from 'classnames';


const useStyles = makeStyles({
	isDeleted: {
		textDecoration: "line-through",
	},
});


export const StyledEmailField = props => {
	const classes = useStyles();
	return (
		<EmailField
			className={classnames({
				[classes.isDeleted]: props.record.is_deleted,
			})}
			{...props}
		/>
	);
};

export const StyledTransportField = props => {
	const classes = useStyles();
	return (
		<TextField
			className={classnames({
				[classes.isDeleted]: props.record.is_deleted,
			})}
			{...props}
		/>
	);
};
