import React, { useEffect, useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { httpClient } from '../../api/httpClient';

import {
	useTranslate,
    usePermissions,
	CheckboxGroupInput,
	AutocompleteArrayInput
} from 'react-admin';
import { UpdateForm } from './UpdateForm';
import { CardBlock } from './CardBlock';
import { upperCase } from 'lodash';
import { Loading } from 'react-admin';
import { useNotifyError } from '../../utils/notifiers/useNotifyError';
const useStyles = makeStyles(theme => ({
	root: {
		
		'&>div>label>.MuiCheckbox-root': {
			padding: '0px 9px',
		},

		'&>legend': {
			marginBottom: '9px',
		}
	}
}));

export const OrderPropsBlock = () => {
	const t = useTranslate();
    const classes = useStyles();
	const { permissions } = usePermissions();
	const [choices, setChoices] = useState(null)
	const [loading, setLoading] = useState(true); // Add a loading state
	const notifyError = useNotifyError();	

	useEffect(() => {
	  const promColumns = httpClient("orders-props");
	  promColumns
		.then((promColumnsRes) => {
			console.log(promColumnsRes)
		  if (promColumnsRes.json) {
			const _columnsChoices = promColumnsRes.json.map((x) => ({
			  id: x[0],
			  name: upperCase(x[1]),
			}));
			setChoices(_columnsChoices);			
		  }
		})
		.catch((error) => {
			notifyError(error);
		})
		.finally(() => {
			setLoading(false);
		});
	}, []);

	if (loading) {
		// Return loading indicator or message while data is being fetched
		return <Loading/>;
	  }
	
	if (!permissions) {
		return null;
	};

	return (
	<AutocompleteArrayInput  source="order_props_changes"  choices={choices}/>
)}