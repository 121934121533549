import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {toggleListItemExpand} from 'react-admin';

export const useExpandDefaultForAll = (resource) => {
	const [ids, expanded]  = useSelector(
		(state) => ([state.admin.resources[resource].list.ids, state.admin.resources[resource].list.expanded])
	);
	const dispatch = useDispatch();
	useEffect(() => {
		for (let i = 0; i < ids.length; i++) {
			if (!expanded.includes(ids[i])){
				dispatch(toggleListItemExpand(resource, ids[i]));
			}
		}
	}, [ids]);
};
