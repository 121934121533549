import React from "react";
import { Edit, SimpleForm } from 'react-admin';

import { SaveWithCancelWithPasswordChangeToolbar } from '../../../components/toolbars/SaveWithCancelWithPasswordChangeToolbar';

import { ManagerEditForm } from './EditForm';


export const ManagerEdit = (props) => (
	<Edit {...props} undoable={false}>
		<SimpleForm submitOnEnter={false} toolbar={<SaveWithCancelWithPasswordChangeToolbar />}>
			<ManagerEditForm />
		</SimpleForm>
	</Edit>
);
