import React from 'react';

import {
	usePermissions,
    NumberInput,
	BooleanInput
} from 'react-admin';

import Card from '@material-ui/core/Card';

import { UpdateForm } from './UpdateForm';
import { CardBlock } from './CardBlock';

import { GridInput, GridForm } from '../../components/GridForm';
import LanguageSelect from '../../components/inputs/LanguageSelect';
import { validateTimeRefreshOrderRange } from '../../utils/validation/user';
import { OrderPropsBlock } from './OrderPropsBlock';

export const UserSettingsBlock = (props) => {
	const { permissions } = usePermissions();

    if (!permissions) {
		return null;
	};

	return (
		<UpdateForm
			id={permissions.id}
			resource="settings"
			render={({ body, buttons }) => (
				<CardBlock Component={Card} 
						   buttons={buttons} children={body} />
			)}
			permissions={permissions}
		>
            <GridForm {...props}>
                <GridInput md={3} sm={6} component={LanguageSelect} source="locale"/> 
                <GridInput md={3} sm={6}>
                    <NumberInput source="time_refresh_order" step={0.5} min={0} max={10}
                        format={value => value / 60}
                        parse={value => value * 60} validate={validateTimeRefreshOrderRange}/>
                </GridInput> 
				<GridInput md={3} sm={6} component={BooleanInput} source="is_menu_collapse"/> 
            </GridForm>
			<GridForm {...props}>
			<GridInput md={3} sm={6} component={OrderPropsBlock}/> 
			</GridForm>
		</UpdateForm> 
	);
}
