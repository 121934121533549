import React from 'react';

import { ReferenceInput } from 'react-admin';
import { AutocompleteInput } from 'ra-ui-materialui/lib/input/AutocompleteInput';


const defaultSort = {
	field: "id",
	order: "ASC"
};


export const TruckSelect = ({ size, ...props }) => {
	return (
		<ReferenceInput sort={defaultSort} source="truck_id" reference="trucks" {...props} filter={{ no_range: true, ...props.filter }}>
			<AutocompleteInput optionText="name" resettable options={{ InputProps: { size } }} />
		</ReferenceInput>
	);
};
