import React from 'react';
import { Resource } from 'react-admin';

import { ordersResource } from '../../resources/orders/';
import { pricelistArticlesResource } from '../../resources/pricelist-articles/';
import { pricelistHolidaysResource } from '../../resources/pricelist-holidays/';


export const osfcEmployeeResources = [
	<Resource name="orders" {...ordersResource} />,
	// TODO: try to develop some StaicResource that fetch data locally or if data data doesn't change often
	<Resource name="ports" intent="registration" />,
	<Resource name="users" intent="registration" />,
	<Resource name="customer-companies" intent="registration" />,
	<Resource name="order-statuses" intent="registration" />,
	<Resource name="cooler-types" intent="registration" />,
	<Resource name="order-status" intent="registration" />,
	<Resource name="outgoing-doc-statuses" intent="registration" />,
	<Resource name="order-pricelist" intent="registration" options={{ hasAdd: true, hasEdit: true }} />,
	<Resource name="drivers" intent="registration" />,
	<Resource name="transporters" intent="registration" />,
	<Resource name="trucks" intent="registration" />,
	<Resource name="trailers" intent="registration" />,
	<Resource name="terminal" intent="registration" />,
	
	<Resource {...pricelistArticlesResource} name="pricelist-articles" />,
	<Resource {...pricelistHolidaysResource} name="pricelist-holidays" />,
	<Resource name="pricelist-special-price" intent="registration" />,
	<Resource name="pricelist-articles-autocomplete" intent="registration" />,
	<Resource name="osfc-employees" intent="registration" />,
	<Resource name="hotlinks" intent="registration" />,
];
