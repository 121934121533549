import React from "react";
import { List, Datagrid, TextField, TextInput, Pagination} from 'react-admin';

import { CustomersFilter } from './ListFilters';
import { ScrollingWrapper } from '../../../components/ScrollingWrapper';
import { DatagridWithBlockProps } from '../../../components/_helpers/props/DatagridProps';
import {TextEditableField} from "../../../components/fields/EditableField";


const defaultSort = { field: 'key', order: 'DESC' };

export const CustomersList = props => {
	return (
		<List
			{...props}
			sort={defaultSort}
			exporter={false}
			bulkActionButtons={false}
			filters={<CustomersFilter />}
			perPage={200}
			pagination={<Pagination rowsPerPageOptions={[100, 200, 500]} {...props} />}
		>
			<ScrollingWrapper>
				<Datagrid {...DatagridWithBlockProps}>
					<TextField source="key" />
					<TextEditableField source="en" EditComponent={TextInput} editable={true} />
					<TextEditableField source="no" EditComponent={TextInput} editable={true} />
				</Datagrid>
			</ScrollingWrapper>
		</List>
	);
}
