import React from 'react';
import { Toolbar, SaveButton } from 'react-admin';

import { BackButton } from './BackButton';
import { SaveToEditButton } from './SaveToEditButton';
import { SaveWithWarningsButton } from './SaveWithWarningsButton';


export const SaveButtonWithSaveToEditWithCancelToolbar = ({ 
															 children,
															warningValidations,
															 ...props 
														 }) => (
	<Toolbar {...props} >
		{warningValidations ? <SaveWithWarningsButton warningValidations={warningValidations} /> : <SaveButton />}
		<SaveToEditButton />
		<BackButton />
		{children}
	</Toolbar>
);
