import * as React from "react";

import { Button, useTranslate, useRedirect } from 'react-admin';
import ChangeIcon from '@material-ui/icons/Edit';


const MultipleOrdersEditButton = ({ selectedIds = null, basePath }) => {
	const translate = useTranslate();
	const redirect = useRedirect();

	if (!selectedIds) return null;

	const label = translate('resources.orders.bulk-edit.button');

	const handleOnClick = () => {
		const query = `selected_ids=${selectedIds.join(',')}`;
		const redirectTo = `${basePath}/multiple-changes?${query}`;
		redirect(redirectTo);
	};

	return (
		<Button
			onClick={handleOnClick}
			label={label}
		>
			<ChangeIcon />
		</Button>
	);
};


const CustomBulkActionButtons = ({ selectedIds, basePath, filterValues, resource, ...props }) => (
	<React.Fragment>
		<MultipleOrdersEditButton selectedIds={selectedIds} basePath={basePath} />
	</React.Fragment>
);


export default CustomBulkActionButtons;
