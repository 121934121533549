import React from "react";
import { TextInput, required } from 'react-admin';
import { useFormState } from 'react-final-form';

import { GridForm, GridInput } from '../../../components/GridForm/';
import { PhoneInput, EmailInput, PriceCategorySelect, YesNoInput } from '../../../components/inputs/';
import LanguageSelect from "../../../components/inputs/LanguageSelect";

const requiredValidator = [required()];
export const CustomerCompanyEditForm = props => {
	const { values } = useFormState();
	const is_also_transporter = !!values["is_also_transporter"];
	return <GridForm {...props}>
		<GridInput sm={2} component={TextInput} source="id" disabled />
		<GridInput sm={4} component={TextInput} source="full_name" validate={requiredValidator} autoFocus />
		<GridInput sm={3} component={TextInput} source="short_name" validate={requiredValidator} />
		<GridInput sm={3} component={TextInput} source="accountancy_number" validate={requiredValidator} />
		<GridInput sm={3} component={PhoneInput} source="phone" validate={requiredValidator} />
		<GridInput sm={3} component={EmailInput} source="email" validate={requiredValidator} />
		<GridInput sm={3} component={EmailInput} source="notification_email" validate={requiredValidator} />
		<GridInput sm={1} component={PriceCategorySelect} source="price_category" />
		<GridInput xs={2} component={YesNoInput} source="set_order_completed" defaultValue={false} />
		<GridInput sm={3} component={EmailInput} source="deviation_notification_email" />
		<GridInput xs={2} component={YesNoInput} source="is_also_transporter" defaultValue={false} />
		{is_also_transporter && <GridInput sm={4} component={EmailInput} source="transporter_notification_email" />}
	</GridForm>
};
