import React, {useRef, useEffect} from "react";
import {useMutation} from "react-admin";
import {Grid, Box} from "@material-ui/core";

import ChatConversation from "./ChatConversation";
import ChatTextInputBar from "./ChatTextInputBar";
import {useNotifyError} from "../../../utils/notifiers/useNotifyError";
import {resizeElementHeight} from "../../../utils/htmlElementTransform";
import { utcStr2localStr } from '../../../components/_helpers/dateTime';
import {tabbedShowLayoutPadding} from "../Show/Show"


const Plug = () => (
	<Grid item xs={false} sm={1} md={2} lg={2} xl={3}/>
)

function ChatContainer({resource, record, onGetMessages, ...other}) {
	const notifyError = useNotifyError();

	const [fetchAllMessages, {data, total, loading, error}] = useMutation({
		type: 'getList',
		resource: `orders/${record.id}/messages`,
		payload: {
			pagination: {page: 1, perPage: 1},
			sort: {field: 'created_at', order: 'ASC'},
			filter: {no_range: true}
		}}, {
		onSuccess: (data) => {
			for(const item of data.data){
				item.created_at = utcStr2localStr(item.created_at);
			}
		},
	});

	const [mutate] = useMutation({
		resource: `orders/${record.id}/messages`,
	}, {
		onSuccess: fetchAllMessages,
		onFailure: (error) => {
			notifyError(error);
		}
	});

	const sendMessage = (message) => {
		mutate({
			type: 'create',
			payload: {
				data: {
					body: message,
				}
			},
		});
	};

	const chatConversationRef = useRef(null);

	useEffect(() => {
		fetchAllMessages();
	}, [fetchAllMessages]);

	useEffect(() => {
		if (onGetMessages && total) {
			onGetMessages(resource, record, total)
		}
	}, [total]);

	useEffect(() => {
		function handleResize() {
			if (chatConversationRef.current) resizeElementHeight(chatConversationRef.current, -tabbedShowLayoutPadding);
		}

		handleResize()
		window.addEventListener('resize', handleResize)
		return _ => {
			window.removeEventListener('resize', handleResize)
		}
	}, []);

	useEffect(() => {
		if (error) notifyError(error);
	}, [error]);

	return (
		<Box style={{
			display: "flex",
			flexFlow: "column nowrap",
			margin: 0
		}}
			 ref={chatConversationRef}
		>
			<Grid container justify={"center"}
				  style={{
					  flex: "0 0 auto",
				  }}>
				<Plug/>
				<Grid item xs={12} sm={10} md={8} lg={8} xl={6}>
					<ChatTextInputBar sendMessage={sendMessage}/>
				</Grid>
				<Plug/>
			</Grid>
			<Grid container justify={"center"}
				  style={{
					  flex: "1 auto",
					  height: "100%",
					  overflow: "hidden",
					  overflowY: "scroll"

				  }}>
				<Plug/>
				<ChatConversation
					messages={data}
					loading={loading}
					item
					xs={12} sm={10} md={8} lg={8} xl={6}/>
				<Plug/>
			</Grid>

		</Box>
	);
}

export default ChatContainer;
