import React from 'react';

import { useTranslate } from 'react-admin';
import { ChangePasswordForm } from '../../components/dialogs/ChangePasswordDialog';
import { CardBlock } from './CardBlock';


export const ChangePasswordBlock = () => {
	const t = useTranslate();
	return (
		<ChangePasswordForm>
			{({ body, buttons }) => (
				<CardBlock
					buttons={buttons}
					children={body}
				/>
			)}
		</ChangePasswordForm>
	);
}
