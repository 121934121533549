import {useSelector} from 'react-redux';
import isEmpty from 'lodash/isEmpty';


export const useIsSearchInDocs = (filterDefaultValues) => {
	const isSearchInDocs = useSelector(state => (state.admin.resources['orders'] &&
		!isEmpty(state.admin.resources['orders'].list.params.filter) ?
		state.admin.resources['orders'].list.params.filter["is_search_in_docs"] :
		filterDefaultValues && filterDefaultValues["is_search_in_docs"]))

	return isSearchInDocs;
};
