import AssignmentIcon from '@material-ui/icons/Assignment';

import { ArticleShow } from './Show/Show';
import { ArticlesList } from './List/List';


export {
	ArticlesList,
	ArticleShow,
}


export const pricelistArticlesResource = {
	list: ArticlesList,
	// show: ArticleShow,
	icon: AssignmentIcon,
}
