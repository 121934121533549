import React, { useEffect } from 'react';
import { Title, useTranslate } from 'react-admin';
import { useHistory, useParams } from "react-router-dom";

import { httpClient } from '../../../api/httpClient';
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  CardContent,
  Typography,
  Link,
  Paper,
  TableContainer,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  compareDatesFromDatetimeString,
  parseDateFromDatetimeString,
  parseTimeFromDatetimeString
} from '../../../components/_helpers/dateTime';
import { MessageType } from '../../../entities/MessageType';

const defaultRowsPerPage = 20;

// TODO: can we get this list from backend?
const ordersProps = {
  "reference": "REFERENCE",
  "terminal_id": "Terminal",
  "eta_date": "ETA-A DATE",
  "eta_time": "ETA-A TIME",
  "eta_slot_time": "ETA-A SLOT TIME",
  "eta_3rd_party": "ETA-A 3rd party",
  "eta_driver": "ETA-A DRIVER",
  "eta_driver_phone": "ETA-A DRIVER PHONE",
  "trip_number": "SPLIT TRIP NO",
  "port_in": "PORT IN",
  "etd_trailer_id": "ETA-A TRAILER",
  "etd_truck_id": "ETA-A TRUCK ",
  "etd_trailer": "ETA-A TRAILER",
  "etd_truck": "ETA-A TRUCK ",
  "place_from": "Place from",
  "assignee_1": "ASSIGNEE_1",
  "eta_trailer_id": "ETA-D TRAILER",
  "eta_truck_id": "ETA-D TRUCK",
  "eta_trailer": "ETA-D TRAILER",
  "eta_truck": "ETA-D TRUCK ",
  "etd_date": "ETA-D DATE",
  "etd_time": "ETA-D TIME",
  "etd_slot_time": "ETA-D SLOT TIME",
  "etd_3rd_party": "ETA-D 3rd party",
  "etd_driver": "ETA-D DRIVER",
  "etd_driver_phone": "ETA-D DRIVER PHONE",
  "port_out": "PORT OUT",
  "place_to": "Place to",
  "loading_order": "LOADING ORDER",
  "assignee_2": "ASSIGNEE_2",
  "plumb_seal": "SEAL/PLUMB",
  "plumb_number": "PLUMB NUMBER",
  "printed_doc": "DOC",
  "notes": "NOTES",
  "company_id": "CUSTOMER",
  "split_load": "SPLIT",
  // "article": "ARTICLE",
  // "units": "UNITS",
  // "price": "PRICE",
  "status": "STATUS",
  "service": "SERVICE",
  "priority": "PRIORITY",
  "boxes": "BOXES",
  "pallets": "PALLETS",
  "kilos": "KILOS",
  "commodity": "COMMODITY",
  "label_source": "LABEL SOURCE",
  "label": "LABEL",
  // "set_order_completed": "AUTOCOMPLITE",
  // "total": "TOTAL",
  "area_id": "STORAGE",
  "is_area_blocked": "IS AREA BLOCKED",
  // "extra_loading_time": "EXTRA LOADING TIME",
  // "is_extra_loading_time_billable": "EXTRA LOADING TIME BILLABLE",
  "cooler_type_id": "COOLER",
  "load_status_worse": "LOAD STATUS TO WORSE",
};

const useStyles = (rowNewBackground, rowOldBackground) => makeStyles(({ palette, spacing }) => {
  // const radius = spacings(2.5);

  return {
    date: {
      margin: spacing(1, 0, 2, 0),
      padding: spacing(1, 2),
      // borderRadius: radius,
      // display: 'inline-block',
      wordBreak: 'break-word',
      backgroundColor: palette.grey[100],
    },
    tableContent: {
      marginBottom: "30px",
      maxWidth: "170vh",
      overflow: "auto",

      "& thead": {
        // color: "red",
        borderBottom: "2px solid"
      }
    },
    rowNew: {
      backgroundColor: rowNewBackground,
    },
    rowOld: {
      backgroundColor: rowOldBackground,
    },
  };
});



const MessagesItem = ({ message, messages, index }) => {
  const jsonString = message.body;
  const orderId = message.order_id
  const messageBody = jsonString.replace(/None/g, 'null').replace(/'/g, '"');
  const validMessage = JSON.parse(messageBody);
  // TODO: do we need this, frontend should not handle what to show
  const user_fields = validMessage.user_fields
  // const new_fields = validMessage.new.map(item => Object.keys(item));
  const new_values = validMessage.new
  const old_values = validMessage.old
  const isMessageTypeLoadStatusChanges = message.type === MessageType.load_doc_status_changes;
  const styles = useStyles(isMessageTypeLoadStatusChanges ? validMessage['new_load_status_background'] : "yellow",
                           isMessageTypeLoadStatusChanges ? validMessage['old_load_status_background'] : "lightgray")();
  const countExistingValues = user_fields.filter((field) => (
    new_values.hasOwnProperty(field) 
  )).length;
  const tableWidth = countExistingValues > 1 ? 260 * countExistingValues : 260
  const isNeedDate = index === 0 || (compareDatesFromDatetimeString(messages[index - 1].created_at, messages[index].created_at)) !== true;
  if (countExistingValues === 0) {
    return null;
  }
  return (
    <>
      {isNeedDate &&
        <Typography align={"center"} className={styles.date}>
          {parseDateFromDatetimeString(message.created_at)}
        </Typography>
      }
      <Typography sx={{ fontSize: 8 }} color="text.secondary">
        {parseTimeFromDatetimeString(message.created_at)}, <span style={{ color: 'grey' }}> Creation time</span>
      </Typography>
      <Typography sx={{ fontSize: 8 }} component="div">
        <Link color="inherit">
          Message {message.id}
        </Link>
      </Typography>
      <Typography sx={{ fontSize: 8 }} component="div">
        <Link href={`/#/orders/${orderId}`} color="inherit">
          Order {orderId}
        </Link>
      </Typography>
      <TableContainer component={Paper} className={styles.tableContent} style={{ width: tableWidth }}>
        <Table sx={{ padding: 2 }} size="small">
          <TableHead>
            <TableRow>
              {user_fields.map((field) => (
                new_values.hasOwnProperty(field) ? (
                  <TableCell key={field}>
                    {new_values[field] !== null ? ordersProps[field] : ordersProps[field]}
                  </TableCell>
                ) : null
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={styles.rowNew}>
              {user_fields.map((field) => (
                new_values.hasOwnProperty(field) ? (
                  <TableCell key={field}>
                    {new_values[field] !== null ? new_values[field] : new_values[field]}
                  </TableCell>
                ) : null
              ))}
            </TableRow>
            <TableRow className={styles.rowOld}>
              {user_fields.map((field) => (
                old_values.hasOwnProperty(field) ? (
                  <TableCell key={field}>
                    {old_values[field] !== null ? old_values[field] : old_values[field]}
                  </TableCell>
                ) : null
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};



const Messages = (props) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  function handleChangePage(event, newpage) {
    setPage(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const [messages, setMessages] = React.useState([]);
  const [totalMessages, setTotalMessages] = React.useState(0);

  const fetchMessages = () => {
    httpClient(`messages?range=[${page * rowsPerPage},${page * rowsPerPage + rowsPerPage - 1}]`, {
      method: 'GET',
    }).then((response) => {
      setMessages(response.json);
      const [start, end, total] = response.headers.get('Content-Range').split(/[\/-]/).map(Number);
      setTotalMessages(total);
    })
  };
  useEffect(() => {
    fetchMessages();
  }, [page, rowsPerPage]);


  return (
    <>
      <Title title="Messages" />
      <Card>
        <CardContent>
          <Table>
            {messages.map((message, index) => (
              <MessagesItem message={message} messages={messages} index={index} />
            ))}
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={totalMessages}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table >
        </CardContent>
      </Card>
    </>
  );
};

export default Messages;
