import React from 'react';
import { useInput } from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";

import {StyledToggleButton, StyledNoToggleButton} from './FilterToggleInput';


const useStyles = makeStyles(t => ({
	root: {
		// marginBottom: t.spacing(1),
		// marginBottom: 15,
		marginBottom: 4,
	},	
}));


export const ToggleInput = (props) => {
	const classes = useStyles();
	const {input} = useInput(props);

	return (
		<StyledToggleButton
			classes={classes}
			className={props.className}
			value={input.value}
			selected={!!input.value}
			onChange={() => {
				const newVal = input.value ? null : true;
				input.onChange(newVal);
				if (props.cb) props.cb(newVal);
			}}
		>
			{props.label}
		</StyledToggleButton>
	);
}

export const ToggleTitleInput = (props) => {
	const classes = useStyles();
	const {input} = useInput(props);

	return (
		<StyledNoToggleButton
			classes={classes}
			className={props.className}
			value={input.value}
			selected={!!input.value}
			onChange={() => {
				const newVal = input.value ? null : true;
				input.onChange(newVal);
				if (props.cb) props.cb(newVal);
			}}
		>
			{input.value ? props.label1: props.label0}
		</StyledNoToggleButton>
	);
}