import React, { useState } from 'react';

import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import LinearProgress from '@material-ui/core/LinearProgress';
import ExportIcon from '@material-ui/icons/TableChart';
import { ProgressIcon } from '../../ProgressIcon';
import ColumnIcon from '@material-ui/icons/ViewColumn';
import { Button, usePermissions, useMutation, useQuery, useDataProvider, useTranslate } from 'react-admin';

import { useSimpleModal } from '../useSimpleModal';

import { httpClient } from '../../../api/httpClient';

import { SimpleDialog } from '../Dialog';
import { OrdersExportTable } from './OrdersExportTable';
import { ExportServerCSVButton, ExportServerXLSXButton } from './ExportServerButton';
import { CopyCSVButton } from './CopyCSVButton';

import { DateFilterField } from './DateFilterField';
import { StatusFilterField } from './StatusFilterField';
import { OrderStatus } from '../../../entities/OrderStatus';
import {ToolbarButton} from "./ToolbarButton";

import {CheckboxGroupDrawer} from "../CheckboxGroupDrawer";


const getKeyByStatus = (status) => {
	if (status === OrderStatus.ORDER_COMPLETED) {
		return "completed_at";
	}
	return "created_at";
}

const titleByKey = {
	completed_at: "COMPL Date",
	created_at: "REG Date",
};



const OrdersExportModalBase = ({ userId, filters, titleButtons, onNodalClose, exportDate, setExportDate, setDateFromFilter }) => {
	const tableRef = React.useRef(null);
	const translate = useTranslate()

	return (
		<SimpleDialog
			size="lg"
			open={true}
			onClose={onNodalClose}
			title={translate("resources.orders.export-dialog.title")}
			titleButtons={
				<React.Fragment>
					{titleButtons}					
					<CopyCSVButton table={tableRef} userId={userId} exportDate={exportDate} setDateFromFilter={setDateFromFilter} />
					<ExportServerCSVButton filters={filters} />
					<ExportServerXLSXButton filters={filters} />					
				</React.Fragment>
			}
		>
			<TableContainer component={Paper} variant="outlined" elevation={0}>
				<OrdersExportTable
					ref={tableRef}
					filters={filters}
					setExportDate={setExportDate}
				/>
			</TableContainer>
		</SimpleDialog>
	);
}


export const OrdersExportWithFilters = ({ filters }) => {
	const exportModal = useSimpleModal();

	return (
		<React.Fragment>
			<Button label="resources.orders.export" alignIcon="left" onClick={exportModal.handleOpen} startIcon={<ExportIcon />} />
			{exportModal.opened && <OrdersExportModalBase filters={filters} onNodalClose={exportModal.handleClose} />}
		</React.Fragment>
	);
};

const OrdersExportModal = ({ userId, onNodalClose, initDateFrom, columnsChoices, columns }) => {
	
	const [ exportDate, setExportDate ] = React.useState(null);
	const [ dateFromFilter, setDateFromFilter ] = React.useState(initDateFrom);
	const [ dateToFilter, setDateToFilter ] = React.useState(null);
	const [ statusId, setStatusId ] = React.useState(undefined);

	const [ columnsSelected, setColumnsSelected ] = React.useState(columns);

	const columnsModal = useSimpleModal();

	const translate = useTranslate()

	const onColumnsClose = (val) => {
		setColumnsSelected(val);
		columnsModal.handleClose();
	};

	const filterKey = getKeyByStatus(statusId);
	const filterTitle = titleByKey[filterKey];

	const filters = React.useMemo(() => ({
		[`${filterKey}:gt`]: dateFromFilter,
		[`${filterKey}:lt`]: dateToFilter,
		'status': statusId,
		excluded_columns: columns.filter(x => !columnsSelected.includes(x))
	}), [dateFromFilter, dateToFilter, statusId, filterKey, columns, columnsSelected]);
	

	return (
		<OrdersExportModalBase filters={filters} onNodalClose={onNodalClose} titleButtons={
			<React.Fragment>
				<StatusFilterField value={statusId} onChange={setStatusId} />
				{/* <DateFromFilterField label={filterTitle + " from"} userId={userId} onChange={setDateFromFilter} value={dateFromFilter} /> */}
				<DateFilterField label={translate("resources.orders.export-dialog.fields.date_from")} onChange={setDateFromFilter} value={dateFromFilter} />
				<DateFilterField label={translate("resources.orders.export-dialog.fields.date_to")} onChange={setDateToFilter} value={dateToFilter} />
				<ToolbarButton onClick={columnsModal.handleOpen} icon={<ColumnIcon/>}>{translate("resources.orders.export-dialog.button.columns")}</ToolbarButton>
				{columnsModal.opened && (
						<CheckboxGroupDrawer
							choices={columnsChoices}
							value={columnsSelected}						
							title={"Columns"}
							onClose={onColumnsClose}
						/>
					)}
			</React.Fragment>
		} userId={userId} setExportDate={setExportDate} exportDate={exportDate} setDateFromFilter={setDateFromFilter} />
	);
};

export const OrdersExport = ({userId}) => {
	const exportModal = useSimpleModal();
	const dataProvider = useDataProvider();
	const [ isLoading, setIsLoading ] = useState(false);
	const [ initDateFrom, setInitDateFrom ] = useState(new Date(Date.now()));
	const [ columns, setColumns ] = React.useState([]);
	const [ columnsChoices, setColumnsChoices ] = React.useState([]);

	const onOpen = () => {
		setIsLoading(true);
		
		const promLastDate = dataProvider.getOne('orders/last-export-date', { id: userId });
		const promColumns = httpClient("orders-export-header");
		Promise.all([promLastDate, promColumns]).then((values) => {
			const [promLastDateRes, promColumnsRes] = values;
			if (promLastDateRes.data && promLastDateRes.data.date) setInitDateFrom(promLastDateRes.data.date);
			if (promColumnsRes.json) {
				const _columnsChoices = promColumnsRes.json.map(x => ({id: x[0], name: x[1]}));			
				const _columnsSelected = promColumnsRes.json.map(x => x[0]);
				setColumnsChoices(_columnsChoices);
				setColumns(_columnsSelected);				
			}
			setIsLoading(false);
			exportModal.handleOpen();	
		});
	};

	return (
		<React.Fragment>
			<Button label="resources.orders.export_search" disabled={isLoading} startIcon={isLoading ?  <ProgressIcon /> : <ExportIcon />} alignIcon="left" onClick={onOpen} />
			{exportModal.opened && <OrdersExportModal userId={userId} onNodalClose={exportModal.handleClose} initDateFrom={initDateFrom} columns={columns} columnsChoices={columnsChoices} />}
		</React.Fragment>
	);
};