import React from 'react';

import { SelectEnumInput } from '../../../components/inputs/SelectEnumInput';
import { AdminActionName } from '../../../entities/AdminActionName';


export const AdminActionNameSelect = (props) => (
	<SelectEnumInput
		key={"name"} 
		resettable 
		enumName="admin-action" 
		enumObj={AdminActionName} 
		style={{minWidth: "240px"}}
		{...props}/>
);
