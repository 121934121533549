import AssignmentIcon from '@material-ui/icons/Assignment';

import { ArticlesList } from './List/List';


export {
	ArticlesList,
}


export const pricelistArticlesResource = {
	list: ArticlesList,
	icon: AssignmentIcon,
}
