import React from 'react';
import { Resource } from 'react-admin';

import { ordersResource } from '../../resources/orders/';
import { smsLogsResource } from '../../resources/sms-logs';
import { pricelistArticlesResource } from '../../resources/pricelist-articles/';
import { pricelistHolidaysResource } from '../../resources/pricelist-holidays/';
import { osfcManagersResource } from '../../resources/osfc-employees/';
import { customerCompaniesResource } from '../../resources/customer-companies/';
import { adminCustomerEmployees } from '../../resources/admin-customer-employees/';
import { transportersResource } from '../../resources/transporters';
import { transportTrucksResource } from '../../resources/trucks';
import { transportTrailersResource } from '../../resources/trailers';
import { customerDriversResource } from '../../resources/drivers';
import { portsResource, areasResource } from '../../resources/ports-areas';
// import { areasResource } from '../../resources/areas';
import { articleTemplatesResource } from '../../resources/pricelist-article-templates';
import { orderDocumentStatusResource } from '../../resources/order-document-status/';
import { trucksTrafficResource } from '../../resources/trucks-traffic';

export const osfcManagerResources = [
	<Resource name="orders" {...ordersResource} />,
	<Resource {...osfcManagersResource} name="osfc-employees" />,
	<Resource {...customerCompaniesResource} name="customer-companies" />,
	<Resource {...adminCustomerEmployees} name="admin-customer-employees" />,
	// <Resource {...transportersResource} name="transporters" />,
	<Resource name="transporters" intent="registration" />,
	// <Resource {...transportTrucksResource} name="trucks" />,
	// <Resource {...transportTrailersResource} name="trailers" />,
	<Resource name="trucks" intent="registration" />,
	<Resource name="trailers" intent="registration" />,
	<Resource {...customerDriversResource} create={null} edit={null} name="drivers" />,
	// TODO: try to develop some StaicResource that fetch data locally or if data data doesn't change often
	<Resource name="users" intent="registration" />,
	<Resource name="order-statuses" intent="registration" />,
	<Resource name="cooler-types" intent="registration" />,
	<Resource name="order-status" intent="registration" />,
	<Resource name="outgoing-doc-statuses" intent="registration" />,
	<Resource name="order-history" intent="registration" />,
	<Resource name="order-pricelist-history" intent="registration" />,
	<Resource name="order-pricelist" intent="registration" options={{ hasAdd: true, hasEdit: true }} />,
	<Resource name="companies" intent="registration"/>,
	<Resource name="company-notifications" intent="registration" />,
	<Resource name="terminal" intent="registration" />,
	<Resource name="hotlinks" intent="registration" />,

	<Resource {...pricelistArticlesResource} name="pricelist-articles" />,
	<Resource {...pricelistHolidaysResource} name="pricelist-holidays" />,
	<Resource {...portsResource} name="ports" intent="registration" />,
	<Resource {...areasResource} name="areas" intent="registration" />,
	<Resource {...articleTemplatesResource} name="pricelist-article-templates" intent="registration" />,
	<Resource name="pricelist-special-price" intent="registration" />,
	<Resource name="pricelist-articles-autocomplete" intent="registration" />,
	<Resource {...orderDocumentStatusResource} name="order-document-status" />,
	<Resource name="sms-logs" {...smsLogsResource} />,
	<Resource name="trucks-traffic" {...trucksTrafficResource}/>
];
