import * as React from 'react';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import { FieldTitle, useInput, warning } from 'ra-core';

import classnames from 'classnames';

import {
	sanitizeInputRestProps,
	InputHelperText,
	Labeled,
	LinearProgress
} from 'react-admin';

import CheckboxGroupInputItemEx from './CheckboxGroupInputItemEx';


const CheckboxGroupInputEx = props => {
    const {
        choices = [],        
        className,
        classes: classesOverride,
        format,
        helperText,
        label,
        loaded,
        loading,
        margin = 'dense',
        onBlur,
        onChange,
        onFocus,
        optionText,
        optionValue,
        options,
        parse,
        resource,
        row,
        source,
        translate,
        translateChoice,
        validate,
        maxChoices,
        disabled,
        disabledValues,
        ...rest
    } = props;

    const classes = useStyles(props);

    warning(
        source === undefined,
        `If you're not wrapping the CheckboxGroupInput inside a ReferenceArrayInput, you must provide the source prop`
    );

    warning(
        choices === undefined,
        `If you're not wrapping the CheckboxGroupInput inside a ReferenceArrayInput, you must provide the choices prop`
    );

    const {
        id,
        input: { onChange: finalFormOnChange, onBlur: finalFormOnBlur, value },
        isRequired,
        meta: { error, submitError, touched },
    } = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        validate,
        ...rest,
    });

    const isDisabled = maxChoices && value.length >= maxChoices;

    const handleCheck = useCallback(
        (event, isChecked) => {
            if (maxChoices && isChecked && isDisabled) return false;
            let newValue;
            try {
                // try to convert string value to number, e.g. '123'
                newValue = JSON.parse(event.target.value);
            } catch (e) {
                // impossible to convert value, e.g. 'abc'
                newValue = event.target.value;
            }
            if (isChecked) {
                finalFormOnChange([...(value || []), ...[newValue]]);
            } else {
                finalFormOnChange(value.filter(v => v != newValue)); // eslint-disable-line eqeqeq
            }
            finalFormOnBlur(); // HACK: See https://github.com/final-form/react-final-form/issues/365#issuecomment-515045503
        },
        [finalFormOnChange, finalFormOnBlur, value, maxChoices, isDisabled]
    );

    if (loading) {
        return (
            <Labeled
                label={label}
                source={source}
                resource={resource}
                className={className}
                isRequired={isRequired}
            >
                <LinearProgress />
            </Labeled>
        );
    }

    return (
        <FormControl
            component="fieldset"
            margin={margin}
            error={touched && !!(error || submitError)}
            className={classnames(classes.root, className)}
            {...sanitizeRestProps(rest)}
        >
            <FormLabel component="legend" className={classes.label}>
                <FieldTitle
                    label={label}
                    source={source}
                    resource={resource}
                    isRequired={isRequired}
                />
            </FormLabel>
            <FormGroup row={row}>
                {choices.map(choice => (                    
                    <CheckboxGroupInputItemEx
                        key={get(choice, optionValue)}
                        choice={choice}
                        id={id}                        
                        onChange={handleCheck}
                        options={options}
                        optionText={optionText}
                        optionValue={optionValue}
                        translateChoice={translateChoice}
                        value={value}
                        disabled={disabled}
                        disabledUnchecked={isDisabled}
                        disabledOnValues={disabledValues && disabledValues.length && disabledValues.indexOf(choice.id) !== -1}
                    />                    
                ))}
            </FormGroup>
            <FormHelperText>
                <InputHelperText
                    touched={touched}
                    error={error || submitError}
                    helperText={helperText}
                />
            </FormHelperText>
        </FormControl>
    );
};

const sanitizeRestProps = ({
    refetch,
    setFilter,
    setPagination,
    setSort,
    loaded,
    touched,
    ...rest
}) => sanitizeInputRestProps(rest);

const useStyles = makeStyles(
    theme => ({
        root: {},
        label: {
            transform: 'translate(0, 8px) scale(0.75)',
            transformOrigin: `top ${
                theme.direction === 'ltr' ? 'left' : 'right'
            }`,
        },
    }),
    { name: 'RaCheckboxGroupInput' }
);

CheckboxGroupInputEx.propTypes = {
    choices: PropTypes.arrayOf(PropTypes.object),    
    className: PropTypes.string,
    label: PropTypes.string,
    source: PropTypes.string,
    options: PropTypes.object,
    optionText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.element,
    ]),
    optionValue: PropTypes.string,
    row: PropTypes.bool,
    resource: PropTypes.string,
    translateChoice: PropTypes.bool,
    maxChoices: PropTypes.number,
};

CheckboxGroupInputEx.defaultProps = {
    options: {},
    optionText: 'name',
    optionValue: 'id',
    translateChoice: true,
    fullWidth: true,
    row: true,
    maxChoices: 0,
};

export default CheckboxGroupInputEx;