import React from "react";
import { Filter, } from 'react-admin';
import TextInputEx from "../../../components/_extensions/react-admin/input/TextInputEx";


export const CustomersFilter = props => (
	<Filter {...props}>
		<TextInputEx debounceTime={750} source="_query" alwaysOn />
		<TextInputEx debounceTime={750} source="first_name" />
		<TextInputEx debounceTime={750} source="last_name" />
		<TextInputEx debounceTime={750} source="email" />
		<TextInputEx debounceTime={750} source="phone" />
	</Filter>
);
