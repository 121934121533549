import React from "react";
import { Link, EditButton, ShowButton } from 'react-admin';



export const LinkField = (props) => {
	return (
		<Link to={`${props.resource}/${props.record.id}`}>
			{props.record[props.source]}
		</Link>
	)
}

export const LinkFieldToOther = (props) => {
	return (
		<Link to={`${props.otherResource}/${props.record[props["otherIdProp"]]}`}>
			{props.record[props.source]}
		</Link>
	)
}

export const EditButtonField = (props) => {
	return (
		<EditButton {...props} label={props.record[props.source]} icon={null} />
	);
}

export const ShowButtonField = ({ formatter=(v) => v, sortable, ...props }) => {
	return (
		<ShowButton {...props} label={formatter(props.record[props.source])} icon={null} />
	);
}
