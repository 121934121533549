import React from 'react';
import { SimpleShowLayout, TextField, FunctionField, useGetOne } from 'react-admin';

import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

import EmailFieldEx from '../_extensions/react-admin/ra-ui-materialui/src/field/EmailFieldEx';

import { formatPhoneNumber, PhoneField } from './PhoneField';
import QuickPreview from '../CustomDrawer';
import { LoadingSpinner } from '../LoadingSpinner';


export const formatCustomer = record => `${record.first_name} ${record.last_name} | ${formatPhoneNumber(record.phone)}`;


const withPreviewStyles = withStyles(theme => ({
	field: {
		'& span': {
			display: 'inline-block',
			maxWidth: '20em'
		}
	}
}));

const getMailMessageDict = (orderId, orderReference) => {
	const mailMessageDict = { 
		"body": `Order id: %0D%0A${orderId} %0D%0AOrder reference: %0D%0A${orderReference}`, 
	};
	return mailMessageDict;
};


export const CustomerPreviewView = ({ classes, mailMessageDict, ...props }) => (
	<SimpleShowLayout {...props}>
		<TextField source="id" disabled className={classes.field} />
		<TextField source="full_name" autoFocus className={classes.field} />
		<TextField source="short_name" className={classes.field} />
		<TextField source="accountancy_number" className={classes.field} />
		<EmailFieldEx source="email" className={classes.field} mailMessageDict={mailMessageDict} />
		<PhoneField source="phone" className={classes.field} addLabel={true} />
	</SimpleShowLayout>
);

export const CustomerPreview = withPreviewStyles(CustomerPreviewView);


export const CustomCreatorView = ({ mailMessageDict, ...props }) => (
	<SimpleShowLayout {...props}>
		<FunctionField label="resources.users.fields.placed_by" addLabel={true} render={
			(record) => `${record.first_name} ${record.last_name}`
		} />
		<PhoneField source="phone" addLabel={true} />
		<EmailFieldEx source="email" addLabel={true} mailMessageDict={mailMessageDict} />
	</SimpleShowLayout>
);


export const CustomCreatorPreview = (props) => {
	const { data, loading } = useGetOne(props.resource, props.id);

	if (loading) {
		return <LoadingSpinner />;
	}

	return <CustomCreatorView {...props} record={data} />;
};


export const CustomerPreviewComplex = ({ orderData, customerData, displayCreator = true, ...props }) => {
	const createdBy = orderData.created_by;
	const customerId = customerData.id;
	const mailMessageDict = getMailMessageDict(orderData.id, orderData.reference);

	const { data, loading } = useGetOne(props.resource, customerId);

	if (loading) {
		return <LoadingSpinner />;
	}

	return (
		<React.Fragment>
			<CustomerPreview {...props} id={customerId} record={data} 
							 mailMessageDict={mailMessageDict} />
			{displayCreator && createdBy ? 
				[
					<Divider />,
					<CustomCreatorPreview 
						id={createdBy} basePath="/users" resource="users" 
						mailMessageDict={mailMessageDict}
					/>,
				]
			: null}
		</React.Fragment>
	);
};


const ContentComponent = ({ record, source, ...props}) => (
	<CustomerPreviewComplex 
		{...props}
		basePath="/customer-companies" 
		resource="customer-companies" 
		customerData={{ id: record[source] }}
		orderData={record} 
	/>
);

const CustomerField = ({ record, source, formatLabel, ...rest }) => {
	if (!record[source]) return null;

	return (
		<QuickPreview 
			formatLabel={() => formatLabel(record)}
			contentProps={{ record, source }}
			ContentComponent={ContentComponent}
		/>
	);
};


CustomerField.defaultProps = {
	addLabel: true,
};


export default CustomerField;
