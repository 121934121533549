import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, TextareaAutosize, IconButton} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import {useTranslate} from "react-admin"

const useStyles = makeStyles(({palette, spacing}) => {
	const radius = spacing(1.5);

	return {
		input: {
			flex: "auto",
			borderRadius: radius,
			padding: spacing(1.5),
			backgroundColor: "rgba(0,0,0,0.04)",
			marginTop: spacing(1),
			marginLeft: spacing(1),
			marginRight: spacing(1),
			fontSize: spacing(2),
			resize: "none",
		}
	};
});

const ChatTextInputBar = ({sendMessage}) => {
	const styles = useStyles();
	const translate = useTranslate();
	const [message, setMessage] = useState("");

	const handleChange = (event) => {
		setMessage(event.target.value);
	};

	const handleClick = () => {
		sendMessage && message && sendMessage(message);
		setMessage("");
	};

	return (
		<Box display="flex" alignItems="flex-end" px={2}>
			<TextareaAutosize
				name={"message"}
				rowsMax={10}
				maxLength={4096}
				placeholder={translate("osfc.order-chat.input.placeholder")}
				className={styles.input}
				onChange={handleChange}
				value={message}
			/>
			<IconButton edge="end" color="inherit" onClick={handleClick}>
				<SendIcon/>
			</IconButton>
		</Box>
	);
};

export default ChatTextInputBar;
