import React from 'react';

import { SelectInput } from 'react-admin';

import { useCommodityChoices } from '../../../../entities/Commodity';


export const CommodityInput = (props) => {
	const choices = useCommodityChoices();

	return (
		<SelectInput
			{...props}
			choices={choices}
			// parse={parse}
		/>
	);
};
