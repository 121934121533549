import React from 'react';
import { FunctionField } from 'react-admin';

import { OrderServiceNames } from '../../entities/OrderService';


export const OrderServiceField = (props) => {
	return (
		<FunctionField {...props} addLabel={true} render={record => OrderServiceNames[record[props.source]]} />
	);
};


OrderServiceField.defaultProps = {
    addLabel: true,
};
