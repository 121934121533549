import React from 'react';

import { usePermissions, useTranslate, TabbedShowLayout } from 'react-admin';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';

import { ChangePasswordBlock } from './ChangePasswordBlock';
import { NotificationsBlock } from './NotificationsBlock';
import { CompanyNotificationsBlock } from './NotificationsBlock';
import { CompanySettingsBlock } from './CompanySettingsBlock';
import { UserSettingsBlock } from './UserSettingsBlock';
import { UserRole } from '../../entities/UserRole';
import { CompanySettings } from './CompanySettings';
import { OrderPropsBlock } from './OrderPropsBlock';
import { TabPanel } from './TabPanel';
// import { UserInfoBlock } from './UserInfoBlock';

const useStyles = makeStyles(() => ({
	tabs: {
		borderBottom: '1px solid #e0e0e0', 
	}
}));


export const Settings = () => {
	const { permissions } = usePermissions();
	const translate = useTranslate();
	const isNotificationsSettingsActive = permissions && permissions.role !== UserRole.admin;

	const classes = useStyles();

	const [value, setValue] = React.useState(0);
  
	const handleChange = (event, newValue) => {
	  setValue(newValue);
	};

	return (
		<>
			<Tabs
				value={value}
				onChange={handleChange}
				indicatorColor="primary"
				textColor="primary"
				className={classes.tabs} 
			>
				{isNotificationsSettingsActive && <Tab label={translate("tab.notifications.name")} />}
				<Tab label={translate("tab.settings.name")}/>
				<Tab label={translate("tab.change_password.name")} />
			</Tabs>
			
			{isNotificationsSettingsActive && <TabPanel value={value} index={isNotificationsSettingsActive ? 0 : null}>
				<Grid item xs={12} md={6} lg={4}>
					<NotificationsBlock />
				</Grid>
			</TabPanel> }
			<TabPanel value={value} index={isNotificationsSettingsActive ? 1 : 0}>
				<UserSettingsBlock />
			</TabPanel>
			<TabPanel value={value} index={isNotificationsSettingsActive ? 2 : 1}>
				<Grid item xs={12} md={6} lg={4}>
					<ChangePasswordBlock />
				</Grid>
			</TabPanel>
		  </>
	);
}

export const Notifications = CompanyNotificationsBlock;
export const CompanySettingsTab = CompanySettingsBlock;
export const CompanySettingsPage = CompanySettings;

