import React, { Children, isValidElement } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import CardContentInner from 'ra-ui-materialui/lib/layout/CardContentInner';
import Labeled from 'ra-ui-materialui/lib/input/Labeled';


export const FlexBreak = (props) => {
	return <div style={{ flexBasis: '100%', height: 0, }}></div>;
};


const useFlexRowStyles = (flexBasisValue) => makeStyles(theme => ({
	flexRow: {
		display: 'flex',
		flexBasis: flexBasisValue,
		columnGap: '2em',

		[theme.breakpoints.down('sm')]: {
			flexBasis: '100%',
			flexWrap: "wrap",
		}
	},
}));


export const FlexRow = ({ basePath, record, resource, version, flexLayoutClasses, isNot3rdPartyRole, ...props }) => {
	const classes = useFlexRowStyles(isNot3rdPartyRole ? '63%' : '70%')();
	return (
		<div className={classes.flexRow}>{React.Children.map(props.children, field => cloneField(field, flexLayoutClasses, { basePath, record, resource, version }))}</div>
	);
}


const useFlexLayoutStyles = makeStyles(theme => ({
	wrapper: {
		display: 'flex',
		flexWrap: 'wrap',
		columnGap: '2em',
		paddingLeft: '0 !important',
		paddingRight: '0 !important',
		paddingBottom: '0 !important',
	},
	// item: {
	// 	flexBasis: '100%',

	// 	[theme.breakpoints.up('sm')]: {
	// 		flexBasis: '15%',
	// 	}
	// },
	break: {
		flexBasis: '100% !important',
		height: 0,
	},
	base: {
		flexBasis: '90%',
	},
	item: {
		maxWidth: '100%',
		minWidth: '100%',
		
		[theme.breakpoints.up('sm')]: {
			maxWidth: '15%',
			minWidth: '15%',
		},
	},
	flex: {
		flex: 1,
		maxWidth: '100%',
		minWidth: '100%',

		[theme.breakpoints.up('sm')]: {
			maxWidth: 'auto',
			minWidth: 'auto',
		},
	},
	warning: {
		backgroundColor: 'rgba(255,0,0,0.1)',
		boxSizing: "border-box",
		paddingLeft: 8,
	},
}), { name: "FlexLayout" });


const sanitizeRestProps = ({
    children,
    className,
    record,
    resource,
    basePath,
    version,
    initialValues,
    translate,
    ...rest
}) => rest;


const cloneField = (field, classes, { basePath, record, resource, version }) => {
	if (!field) return null;

	const divClasses = classnames(
		`ra-field ra-field-${field.props.source}`,
		field.props.className,
		// field.props.fullWidth ? classes.base : classes.item,
		field.props.flex ? classes.flex : classes.item,
		// classes.flex,
		field.props.styleWarning ? classes.warning : undefined
	);

	return (
		<div key={field.props.source} className={divClasses}>
			{field.props.addLabel ? (
				<Labeled
					record={record}
					resource={resource}
					basePath={basePath}
					label={field.props.label}
					source={field.props.source}
					disabled={false}
					fullWidth={field.props.fullWidth}
				>
					{field}
				</Labeled>
			) : typeof field.type === 'string' ? (
				field
			) : (
				React.cloneElement(field, { record, resource, basePath })
			)}
		</div>
	);
}


export const FlexLayout = ({
    basePath,
    className,
    children,
    record,
    resource,
    version,
    ...rest
}) => {
	const classes = useFlexLayoutStyles();
	return (
		<CardContentInner
			className={classnames(className, classes.wrapper)}
			key={version}
			{...sanitizeRestProps(rest)}
		>
			{Children.map(children, field => {
				if (!field) {
					return null;
				}

				if (field.type === FlexBreak) {
					return <div>{field}</div>;
				}

				if (field.type === FlexRow) {
					return React.cloneElement(field, {
						...field.props,
						flexLayoutClasses: classes,
						basePath,
						record,
						resource,
						version,
						...rest,
					});
				}

				if (!isValidElement(field)) {
					return null
				}

				return cloneField(field, classes, { basePath, record, resource, version });
			})}
		</CardContentInner>
	);
};


FlexLayout.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    record: PropTypes.object,
    resource: PropTypes.string,
    version: PropTypes.number,
};
