import React from "react";
import { Edit, SimpleForm } from 'react-admin';

import { SimpleFormAdv } from '../../../components/forms/SimpleFormAdv';

import { ArticleTemplateBaseForm } from '../Forms/ArticleTemplateBaseForm';


export const ArticleTemplateEdit = (props) => (
	<Edit {...props} undoable={false}>
		<SimpleFormAdv>
			<ArticleTemplateBaseForm />
		</SimpleFormAdv>
	</Edit>
);
