import PeopleIcon from '@material-ui/icons/People'

import { ManagerCreate } from './Create/Create';
import { ManagerEdit } from './Edit/Edit';
import { ManagersList } from './List/List';


export {
	ManagersList,
	ManagerCreate,
	ManagerEdit,
}


export const osfcManagersResource = {
	list: ManagersList,
	edit: ManagerEdit,
	create: ManagerCreate,
	icon: PeopleIcon,
}
