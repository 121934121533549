import React from 'react';

import { Show, TextField, SimpleShowLayout, SelectField } from 'react-admin';

import { ActionsWithBackButton } from '../../../components/actionbars/ActionsWithBackButton';
import { DateTimeField } from '../../../components/fields/DateFormatField';

import { useEnumSelectChoices } from '../../../components/_helpers/useEnumSelectChoices';
import { AdminLogLevelType } from '../../../entities/AdminLogLevelType';
import { AdminLogModuleType } from '../../../entities/AdminLogModuleType';
import { JsonTextField } from '../../../components/fields/JsonTextField';

export const AdminLogsShow = (props) => {
	const adminLogLevelChoices = useEnumSelectChoices(AdminLogLevelType, "log-level")
	const adminModuleChoices = useEnumSelectChoices(AdminLogModuleType, "log-module")

	return (
		<Show actions={<ActionsWithBackButton />} {...props}>
			<SimpleShowLayout>
					<TextField source='id' />
					<DateTimeField source='created_at' withLocalTimeZone addLabel />
					<TextField source='order_id' />
					<SelectField source='log_level' choices={adminLogLevelChoices} />
					<SelectField source='module' choices={adminModuleChoices} />
					<TextField source='description' />
					<JsonTextField source='data' label="resources.admin-logs.fields.data" />
			</SimpleShowLayout>
		</Show>
	);
};
