import React from 'react';
import { SelectInput } from 'react-admin';

import { upperCaseGetter } from '../../utils/getter';

export const SelectUppercaseInput = ({optionText, ...rest}) => {
    if (typeof optionText === 'string'){
        let source = optionText;
        optionText = (record) => upperCaseGetter(record, source);
    }
    return <SelectInput {...rest} optionText={optionText}></SelectInput>;
};

export const SelectDisabledInput = ({allowedIds, ...rest}) => {
    if (rest.choices && rest.choices.length) {
        for (const choice of rest.choices.filter(x => allowedIds.indexOf(x.id) === -1)) {
            choice.disabled = true;
        }
    }
    return <SelectUppercaseInput {...rest} />;
}
