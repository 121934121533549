import React from 'react';

import Popover from '@material-ui/core/Popover';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import IconButton from '@material-ui/core/IconButton';
import MUIList from '@material-ui/core/List';
import MUIListItem from '@material-ui/core/ListItem';
import MUIListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';


export const IconButtonPopover = ({ children, ...rest }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (e) => {
		setAnchorEl(e.currentTarget);
		// e.preventDefault();
		// e.stopPropagation();
	};

	const handleClose = (e) => {
		setAnchorEl(null);
		// e.preventDefault();
		// e.stopPropagation();
	};

	const open = Boolean(anchorEl);
	const id = open ? 'iconbutton-popover' : undefined;

	return (
		<div style={{ textAlign: "center" }}>
			<IconButton aria-label="delete" size="small" aria-describedby={id} color="default" onClick={handleClick}>
				<InfoIcon fontSize="inherit" />
			</IconButton>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
				}}
				transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
				}}
			>
				{children}
			</Popover>
		</div>
	);
};

export const TextPopover = (props) => {	
	return props.text ?
		<IconButtonPopover>
			<MUIList dense>
				<MUIListItem button={false} key={`null`}>
					<MUIListItemText secondary={<Typography noWrap variant="body2">
						<span>{props.text}</span>
					</Typography>} />
				</MUIListItem>
			</MUIList>
		</IconButtonPopover> :
		<></>;
};
