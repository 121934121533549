import React from 'react';

import { SelectEnumInput } from '../../../components/inputs/SelectEnumInput';
import { AdminLogLevelType } from '../../../entities/AdminLogLevelType';


export const AdminLogLevelSelect = (props) => (
	<SelectEnumInput
		key={"log-level"} 
		resettable 
		enumName="log-level" 
		enumObj={AdminLogLevelType} 
		style={{minWidth: "240px"}}
		{...props}/>
);
