import React from 'react';

import { Show, TextField, SimpleShowLayout, FunctionField } from 'react-admin';

import { ActionsWithBackButton } from '../../../components/actionbars/ActionsWithBackButton';
import { formatLinkPreview } from '../../../components/_helpers/parsers';


export const HotlinksShow = (props) => {
	return (
		<Show actions={<ActionsWithBackButton />} {...props}>
			<SimpleShowLayout>
				<TextField source='id' />
                <TextField source='name' />
				<FunctionField source='link' render={
					record => formatLinkPreview(190)(record.link)
				} />
                <TextField source='sort_order' />
			</SimpleShowLayout>
		</Show>
	);
};
