import React, { cloneElement, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import ContentSave from '@material-ui/icons/Save';
import classnames from 'classnames';
import {
    useTranslate,
    useNotify,
    useSaveContext,
    useFormContext,
} from 'ra-core';

import { diff } from '../../../../../../utils/diff';
import { isMultiDiff } from '../../../../../GridForm';


export const sanitizeButtonRestProps = ({
    // The next props are injected by Toolbar
    basePath,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    onSave,
    pristine,
    record,
    redirect,
    resource,
    saving,
    submitOnEnter,
    undoable,
    ...rest
}) => rest;


const SaveButton = props => {
    const {
        className,
        classes: classesOverride,
        invalid,
        label = 'ra.action.save',
        disabled,
        redirect,
        saving,
        submitOnEnter,
        variant = 'contained',
        icon = defaultIcon,
        onClick,
        handleSubmitWithRedirect,
        onSave,
        onSuccess,
        onFailure,
        transform,
        customHandleClick,
        customClickProp,
        IsChangedRecordOnSavingCheckNeeded = false,
        multiRecordData,
        ...rest
    } = props;
    const classes = useStyles(props);
    const notify = useNotify();
    const translate = useTranslate();
    const formContext = useFormContext();
    const { setOnSuccess, setOnFailure, setTransform } = useSaveContext(props);
	const { values, initialValues } = useFormState();

    useEffect(() => {
		if (customClickProp) {
            handleClick(customClickProp);
        }
	}, [customClickProp]);

    const handleClick = ({ event, ...handleClickProps }) => {
        // deprecated: use onSuccess and transform instead of onSave
        if (typeof onSave === 'function') {
            if (process.env.NODE_ENV !== 'production') {
                console.warn(
                    '<SaveButton onSave> prop is deprecated, use the onSuccess prop instead.'
                );
                if (!formContext || !formContext.setOnSave) {
                    console.warn(
                        'Using <SaveButton> outside a FormContext is deprecated.'
                    );
                }
            }
            if (formContext && formContext.setOnSave) {
                const customOnSaveHandler = handleClickProps.values ? 
                    (_, redirect) => onSave(handleClickProps.values, redirect) : onSave;
                formContext.setOnSave(customOnSaveHandler);
            }
        } else {
            if (
                process.env.NODE_ENV !== 'production' &&
                (!formContext || !formContext.setOnSave)
            ) {
                console.warn(
                    'Using <SaveButton> outside a FormContext is deprecated.'
                );
            }

            if (formContext && formContext.setOnSave) {
                // we reset to the Form default save function
                formContext.setOnSave();
            }
        }
        if (onSuccess) {
            setOnSuccess(onSuccess);
        }
        if (onFailure) {
            setOnFailure(onFailure);
        }
        if (transform) {
            setTransform(transform);
        }
        if (saving) {
            // prevent double submission
            event.preventDefault();
        } else {
            if (invalid) {
                notify('ra.message.invalid_form', 'warning');
            }
            // always submit form explicitly regardless of button type
            if (event) {
                event.preventDefault();
            }
            handleSubmitWithRedirect(redirect);
        }

        if (typeof onClick === 'function') {
            onClick(event);
        }
    };

    const handleOnSaveButtonClick = (event) => {
        let handleClickProps = { event };

        if (IsChangedRecordOnSavingCheckNeeded === true) {
            const valuesEquals = diff(values, initialValues);
            const isNoChanges = Object.values(valuesEquals).every(statement => statement);

            if (isNoChanges) {
                notify('ra.action.update_warnings.no_changes', 'warning');
                return;
            } else {
                const filtredRecordValues = Object.fromEntries(Object.entries(values).filter(([field, value]) => !valuesEquals[field]));
                handleClickProps = { event, values: filtredRecordValues };
            };
        }
        if (multiRecordData) {
            const newData = {};
            let hasChanges = false;
            for (const [key, val] of Object.entries(initialValues)) {
				if (isMultiDiff(val, values[key], multiRecordData.state[key])) {
                    newData[key] = values[key] !== undefined ? values[key] : null;
                    hasChanges = true;
                }
			}
            
            if (hasChanges) {
                handleClickProps = { event, values: newData };                
            } else {                
                notify('ra.action.update.warnings.no_changes', 'warning');
                return;
            };
        }

        if (typeof customHandleClick === 'function') {
            customHandleClick({...handleClickProps});
        } else {
            handleClick(handleClickProps);
        };
    };

    const type = submitOnEnter ? 'submit' : 'button';
    const displayedLabel = label && translate(label, { _: label });
    
    return (
        <Button
            className={classnames(classes.button, className)}
            variant={variant}
            type={type}
            onClick={handleOnSaveButtonClick}
            color={saving ? 'default' : 'primary'}
            aria-label={displayedLabel}
            disabled={disabled}
            {...sanitizeButtonRestProps(rest)}
        >
            {saving ? (
                <CircularProgress
                    size={18}
                    thickness={2}
                    className={classes.leftIcon}
                />
            ) : (
                cloneElement(icon, {
                    className: classnames(classes.leftIcon, classes.icon),
                })
            )}
            {displayedLabel}
        </Button>
    );
};

const defaultIcon = <ContentSave />;

const useStyles = makeStyles(
    theme => ({
        button: {
            position: 'relative',
        },
        leftIcon: {
            marginRight: theme.spacing(1),
        },
        icon: {
            fontSize: 18,
        },
    }),
    { name: 'RaSaveButton' }
);


SaveButton.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    handleSubmitWithRedirect: PropTypes.func,
    // @deprecated
    onSave: PropTypes.func,
    invalid: PropTypes.bool,
    label: PropTypes.string,
    redirect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.func,
    ]),
    saving: PropTypes.bool,
    submitOnEnter: PropTypes.bool,
    variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
    icon: PropTypes.element,
};

export default SaveButton;
