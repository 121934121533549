import MenuBookIcon from '@material-ui/icons/MenuBook';

import { AdminLogsCreate } from './Create/Create';
import { AdminLogsList } from './List/List';
import { AdminLogsShow } from './Show/Show';


export {
	AdminLogsList,
	// AdminLogsCreate,
	AdminLogsShow,
};

export const adminLogsResource = {
	list: AdminLogsList,
	// create: AdminLogsCreate,
	show: AdminLogsShow,
	icon: MenuBookIcon,
};
