import React from "react";

import { List, Datagrid, ReferenceField, TextField, EditButton, Pagination } from 'react-admin';

import { UserRole } from '../../../entities/';

import { ScrollingWrapper } from '../../../components/ScrollingWrapper';
import { StyledTransportField } from '../../../components/fields/StyledEmailField';
import { DatagridWithBlockDeleteProps } from "../../../components/_helpers/props/DatagridProps";
import { stylesForColorInheritInList } from "../../../components/_helpers/useStyles";
import DeleteRestoreButtons from '../../../components/toolbars/DeleteRestoreButtons';

import { TrucksFilter } from './ListFilters';
import { TrucksActions } from './ListActions';

const defaultSort = { field: 'name', order: 'ASC' };
const TruckPagination = props => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;


export const TrucksList = ({permissions, ...props}) => {
	const classes = stylesForColorInheritInList();

	const isAdminOrOSFCManager = permissions && (
		permissions.role === UserRole.osfc_manager || 
		permissions.role === UserRole.admin
	);
	const isAdmin = permissions && (
		permissions.role === UserRole.admin
	);

	return (
		<List
			{...props}
			classes={classes}
			sort={defaultSort}
			pagination={<TruckPagination/>}
			exporter={false}
			bulkActionButtons={false}
			filters={<TrucksFilter hasCompanyFilter={isAdminOrOSFCManager} />}
			actions={<TrucksActions />}
		>
			<ScrollingWrapper>
				<Datagrid
					{...DatagridWithBlockDeleteProps}
					rowClick="show"
				>
					<TextField source="id" />
					{isAdminOrOSFCManager && <ReferenceField source="company_id" reference="companies" link={false}>
						<TextField source="full_name" />
					</ReferenceField>}
					<StyledTransportField source="name" />
					<EditButton />
					{!isAdmin && <DeleteRestoreButtons resourceForUpdate="trucks" />}
				</Datagrid>
			</ScrollingWrapper>
		</List>
	);
};
