import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  CloneButton,
  useTranslate,
  ReferenceField,
  SingleFieldList,
  ChipField,
  ReferenceManyField,
  ReferenceArrayField,
  FunctionField,
  Link
} from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { TrucksTrafficFilters } from './ListFilters';
import { SelectEnumField } from '../../../components/fields/SelectEnumField';
import { DirectionType } from '../../../entities/DirectionType';
import { CameraName } from '../../../entities/CameraName';
import { DateTimeField } from '../../../components/fields/DateFormatField';
const useStyles = makeStyles(theme => ({
  label: {
    display: 'block',
    textAlign: 'center',
    width: '60px !important',
    border: '0.2px solid black',
    height: '30px',
    borderRadius: '50px',
    padding: '5px 5px',
    fontWeight: 400,
  },
  datagrid: {
    height: '100%',
  },
}));


const defaultSort = {
  field: 'event_date_time',
  order: 'DESC',
};

const TrucksTraffic = ({ permissions, ...props }) => {
  const classes = useStyles();

  return (
    <List {...props}
      exporter={false}
      bulkActionButtons={false}
      sort={defaultSort}
      filters={<TrucksTrafficFilters />}
    >
      <Datagrid className={classes.datagrid}>
        <TextField source="id" sortable={false}/>
        <ReferenceField source="terminal_id" reference="terminal" link={false} sortable={false}>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="vehicle_license_plate"sortable={false} />
        <SelectEnumField source="vehicle_direction_type"  enumName="vehicle_direction_type" enumObj={DirectionType} sortable={false}/>
        <DateTimeField source="event_date_time" withLocalTimeZone={true}/>
        <TextField source="vehicle_country" sortable={false} />
        <TextField source="anpr_confidence_level" sortable={false} />
        <TextField source="camera_ip_address" sortable={false}/>
        <SelectEnumField source="camera_name" enumName="camera_name" enumObj={CameraName} sortable={false}/>
      <ReferenceArrayField reference='orders' source='vehicle_orders' sortable={false} allowEmpty={true} > 
          <SingleFieldList style={{ display: "block" }}>
              <ChipField source="reference" size="small" />
          </SingleFieldList>
      </ReferenceArrayField>
      </Datagrid>
    </List>
  );
};

export default TrucksTraffic;