import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import TableChartIcon from '@material-ui/icons/TableChart';


export const SelectDialog = ({ title, items, handleSelect, open, onClose }) => {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <List>
        {items.map((item) => (
          <ListItem button onClick={() => handleSelect(item)} key={item}>
			<ListItemIcon>
				<TableChartIcon />
			</ListItemIcon>
            <ListItemText primary={item} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}
