import React from 'react';
import { SelectInput } from 'react-admin';


export const yesNoChoices = [{id: false, name: 'NO'}, {id: true, name: 'YES'}];


export const YesNoInput = (props) => {
	return (
		<SelectInput {...props} choices={yesNoChoices} />
	);
};
