export const filterColors = {
	period: "#FFC100",
	day: "#FFE794",
	today: "#90D246",
	in_terminal: "#C5E1B2",
	terminal: "#CCFFFD",
	// state: "#DE350B",
    state: "rgba(255, 140, 140, 0.1)",    
	// search: "#A5A5A5",
    search: "rgb(165, 165, 165, 0.4)",
};