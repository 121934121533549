import React from 'react';
import { useTranslate } from 'react-admin';
import { SelectInput } from 'react-admin';

export const SelectEnumInput = ({enumObj, enumName, ...props}) => {
	const translate = useTranslate();
	// TODO: find out more better approach to translate enum
	const choices = Object.keys(enumObj).map(key => ({id: enumObj[key], name: translate(`osfc.enums.${enumName}.${key}`)}));
	return (
		<SelectInput
			{...props}
			choices={choices}
			// parse={parse}
		/>
	);
};
