import { createMuiTheme } from '@material-ui/core/styles';

import { palette } from './palette';
import { props } from './props';
import { overrides } from './overrides';

export const osfcDefault = createMuiTheme({
    palette: palette,
    props: props,
	overrides: overrides,
    sidebar: {
        width: 250,
    }
});
