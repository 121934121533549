import React, { useEffect, useState } from 'react';
import { Filter, useDataProvider } from 'react-admin';

import { ToggleTerminalsFilter, TERMINAL_ALL_VALUE } from '../../../components/filters/ToggleTerminalsFilter';
import { getUserDataState } from '../../_shared/helpers';


export const filterDefaultValues = {
	"terminal_id": [TERMINAL_ALL_VALUE],
	"save_filters": true,
};


export const usePortAreaFilterDefaultValues = (entityName) => {
	const dataProvider = useDataProvider();
	const [ filters, setFilters ] = useState(undefined);

	useEffect(() => {
		getUserDataState(dataProvider, `${entityName}-filters`, setFilters, filterDefaultValues);
	}, [ dataProvider ]);

	return filters;
};


export const PortAreaFilters = (props) => {
	return (
		<Filter {...props}>
			<ToggleTerminalsFilter source="terminal_id" defaultValue={null} alwaysOn key={"terminal_id"} />
		</Filter>
	);
};
