import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { TextField } from 'react-admin'
import { format as dateTimeFormat } from '../_helpers/dateTime';

import { FunctionField } from 'react-admin';

import { DATE_FORMAT, TIME_FORMAT, DATE_TIME_FORMAT, format, parseISO } from '../_helpers/dateTime';


export const DateFormatField = ({ format, getValue = get, ...props }) => {
	const handleRecordValue = React.useCallback(record => {
		const value = getValue(record, props.source);
		if (!value) { return ''; }
		const date = value instanceof Date ? value : new Date(value);
		if (typeof format === 'function') {
			return format(date);
		}
		return dateTimeFormat(date, format);
	}, [props.source, format, getValue])

	return (
		<FunctionField
			{...props}
			render={handleRecordValue}
		/>
	);
}

DateFormatField.defaultProps = {
    addLabel: true,
    format: DATE_FORMAT,
};

DateFormatField.propTypes = {
    ...TextField.PropTypes,    
    showTime: PropTypes.bool,
    format: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
};


export const TimeFormatField = ({ format, getValue = get, ...props }) => {
	const handleRecordValue = React.useCallback(record => {
		const value = getValue(record, props.source);
		if (!value) { return ''; }
		const date = value instanceof Date ? value : new Date(value);
		if (typeof format === 'function') {
			return format(date);
		}
		return dateTimeFormat(date, format);
	}, [props.source, format, getValue])

	return (
		<FunctionField
			{...props}
			render={handleRecordValue}
		/>
	);
}

TimeFormatField.defaultProps = {
    addLabel: true,
    format: TIME_FORMAT,
};

TimeFormatField.propTypes = {
    ...TextField.PropTypes,    
    showTime: PropTypes.bool,
    format: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
};


export const DateTimeField = ({ source, withLocalTimeZone, ...props }) => {
	const cache = React.useRef({});

	return (
		<FunctionField	
			{...props}
			render={record => {
				if (record[source]) {
					const k = record[source];
					if (!cache.current[k]) {
						if (k[k.length - 1] !== "Z" && withLocalTimeZone) {
							cache.current[k] = format(parseISO(k + "Z"), DATE_TIME_FORMAT);
						} else {
							cache.current[k] = format(parseISO(k), DATE_TIME_FORMAT);
						}
					}
					return cache.current[k];
				}
				return '';
			}}
		/>
	);
}


export const DateTimeFormatField = ({ getValue = get, ...props }) => {
	const handleRecordDate = React.useCallback(record => {
		const value = getValue(record, props.source + "_date");
		if (!value) { return ''; }
		const date = value instanceof Date ? value : new Date(value);
		return dateTimeFormat(date, DATE_FORMAT);
	}, [props.source, getValue])

	const handleRecordTime = React.useCallback(record => {
		const value = getValue(record, props.source + "_time");
		if (!value) { return ''; }
		return value;
		// const date = value instanceof Date ? value : new Date(value);
		// return dateTimeFormat(date, TIME_FORMAT);
	}, [props.source, getValue])

	return (
		<FunctionField
			{...props}
			render={record => {
				const date = handleRecordDate(record);
				const time = handleRecordTime(record);
				if (!time) {
					return date;
				}
				return date + ', ' + time;
			}}
		/>
	);
}

DateTimeFormatField.defaultProps = {
    addLabel: true,
};

DateTimeFormatField.propTypes = {
    ...TextField.PropTypes,    
};
