import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  DateField,
  CloneButton,
  RichTextField,
  ReferenceField
} from 'react-admin';
import { makeStyles } from '@material-ui/core';

import { DateTimeField } from '../../../components/fields/DateFormatField';
import { LongTextField } from '../../../components/fields/LongTextField';
import { LinkFieldToOther } from '../../../components/fields/LinkField';
import { SelectEnumField } from '../../../components/fields/SelectEnumField';
import { SMSStatus } from '../../../entities/SMSStatus';
import { ResendButton } from '../Buttons/ResendButton';
import CustomerField from '../../../components/fields/CustomerField';
import Filters from '../Filter/Filters';


const useStyles = makeStyles(theme => ({
  label: {
    display: 'block',
    textAlign: 'center',
    width: '60px !important',
    border: '0.2px solid black',
    borderRadius: '50px',
    padding: '5px 5px',
    fontWeight: 400,
  },
  datagrid: {
    height: '100%',
  },
}));

const getStatusColorClass = status => {
  switch (status) {
    case 1:
      return '#FFFFFF';
    case 2:
      return '#00AFF3';
    case 3:
      return '#b7ff7d';
    case 4:
      return '#ff8c8c';
    default:
      return '#000000';
  }
}

const rowStyle = (record) => ({
  backgroundColor: getStatusColorClass(record.status),
});

const defaultSort = {
  field: 'time',
  order: 'DESC',
};

const SMSLogs = ({ permissions, ...props }) => {
  const classes = useStyles();
  return (
    <List {...props}
      exporter={false}
      bulkActionButtons={false}
      sort={defaultSort}
      filters={<Filters />}>
      <Datagrid rowStyle={rowStyle} className={classes.datagrid}>        
        <TextField source="id" />
        {/* <TextField source="order_id" /> */}
        <LinkFieldToOther source="order_id" otherResource="orders" otherIdProp="order_id" />
        <LinkFieldToOther source="customer_name" otherResource="customer-companies" otherIdProp="customer_id" />
        <CustomerField source="eta_3rd_party" formatLabel={(record) => record["eta_3rd_party_name"]}/>		
        <CustomerField source="etd_3rd_party" formatLabel={(record) => record["etd_3rd_party_name"]}/>		
        {/* <TextField source="sender" /> */}
        
        {/* <DateTimeField source="time" withLocalTimeZone /> */}
        <TextField source="recipient" sortable={false}/>
        <TextField source="recipient_name" />
        <CustomerField source="recipient_company" formatLabel={(record) => record["recipient_company_name"]} sortable={false}/>
        <DateTimeField source="time_sent" withLocalTimeZone />
        <TextField source="text" style={{ whiteSpace: "normal" }} sortable={false}/>
        <SelectEnumField source="status" enumName="status" enumObj={SMSStatus} sortable={false}/>
        <CloneButton />
        <ResendButton />
      </Datagrid>
    </List>
  );
};

export default SMSLogs;