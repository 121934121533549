import React from 'react';
import { Resource } from 'react-admin';

import { ordersResource, OrderEditByCustomerTransporter, OrderCreateByCustomer, OrderShowForCustomerTransporter, OrderShowTabsWrapper } from '../../resources/orders/';

import { customersEmployeesResource } from '../../resources/customer-employees/';
import { adminCustomerEmployees } from '../../resources/admin-customer-employees/';
import { customerDriversResource } from '../../resources/drivers/';
import { transportTrucksResource } from '../../resources/trucks';
import { transportTrailersResource } from '../../resources/trailers';
import { pricelistArticlesResource } from '../../resources/customer-pricelist-articles';
import { pricelistHolidaysResource } from '../../resources/pricelist-holidays/';
import {  areasResource } from '../../resources/ports-areas';


export const customerAndTransporterManagerResources = [
	<Resource {...ordersResource} edit={OrderEditByCustomerTransporter} create={OrderCreateByCustomer} show={OrderShowTabsWrapper} options={{label: "Orders"}} name="orders" />,
	// <Resource {...customersEmployeesResource} name="customer-employees" />,
	<Resource {...adminCustomerEmployees} name="admin-customer-employees" options={{ label: 'Employees' }} />,
	// <Resource {...transportersResource} create={null} edit={null} name="transporters" />,
	<Resource {...transportTrucksResource} name="trucks" />,
	<Resource {...transportTrailersResource} name="trailers" />,
	<Resource name="drivers" {...customerDriversResource} />,
	<Resource {...pricelistArticlesResource} name="customer-pricelist-articles" options={{hasUpload: false}} />,
	<Resource {...pricelistHolidaysResource} name="pricelist-holidays" />,
	<Resource name="ports" intent="registration" />,
	<Resource name="areas" intent="registration" />,
	<Resource name="users" intent="registration" />,
	<Resource name="customer-companies" intent="registration" />,
	<Resource name="company-notifications" intent="registration" />,
	<Resource name="order-statuses" intent="registration" />,
	<Resource name="outgoing-doc-statuses" intent="registration" />,
	<Resource name="cooler-types" intent="registration" />,
	<Resource name="order-pricelist" intent="registration" />,
	<Resource name="order-history" intent="registration" />,
	<Resource name="transporters" intent="registration" />,
	<Resource name="terminal" intent="registration" />,
	<Resource name="osfc-employees" intent="registration" />,
];
