import React from 'react';
import {connect} from 'react-redux';

import { FunctionField, CRUD_UPDATE_SUCCESS, UPDATE, FETCH_END } from 'react-admin';

import { makeStyles, withStyles } from "@material-ui/core/styles";

import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';

import ChatContainer from "../../resources/orders/Chat";
import QuickPreview from '../CustomDrawer';


const useStyles = makeStyles({
	customBadgeUnread: {
		backgroundColor: "#e74c3c", // red
		color: "white"
	},
	customBadge: {
		// backgroundColor: "rgb(165, 165, 165, 1)", // grey
		backgroundColor: "rgb(119, 136, 153, 0.8)", // lightslategray
		color: "white"
	},
});

const drawerStyles = withStyles(theme => ({
	paper: {
		[theme.breakpoints.up('md')]: {
			width: '50vw',
		},
		[theme.breakpoints.only('sm')]: {
			width: '75vw',
		},
		[theme.breakpoints.only('xs')]: {
			width: '95vw',
		},
	},
}));


const CustomDrawer = drawerStyles(Drawer);

const ContentComponent = ({ resource, record, onGetMessages, ...props }) => (
	<ChatContainer 
		{...props}
		resource={resource}
		record={record} 
		onGetMessages={onGetMessages}
	/>
);

const QuickChatButtonBase = ({resource, record, onOpenChat, onGetMessages}) => {
	const classes = useStyles();

	const onDrawerOpen = () => {
		onOpenChat(resource, record);
	};

	const PreviewOpenButton = (openButtonProps) => {
		const messageCountStyle = (
			record["unread_message_count"] 
				? {badge: classes.customBadgeUnread} 
				: {badge: classes.customBadge}
		);
		const messageCount = (
			record["unread_message_count"] 
				? record["unread_message_count"] 
				: record["message_count"]
		);
		return (
			<IconButton {...openButtonProps}>
				<Badge classes={messageCountStyle} badgeContent={messageCount} showZero />
			</IconButton>
		);
	};

	return (
		<QuickPreview 
			contentProps={{ resource, record, onGetMessages }}
			onDrawerOpen={onDrawerOpen} 
			ContentComponent={ContentComponent}
			DrawerComponent={CustomDrawer}
			PreviewOpenButtonComponent={PreviewOpenButton}
		/>
	);
};

const mapStateToProps = (state, {record, source}) => ({
	record: record,
	source: source
});

const mapDispatchToProps = (dispatch) => ({
	onOpenChat: (resource, record) => {
		dispatch({
			type: CRUD_UPDATE_SUCCESS,
			payload: {
				data: {
					...record,
					unread_message_count: 0,
				}
			},
			meta: {
				resource,
				fetchResponse: UPDATE,
				fetchStatus: FETCH_END
			}
		})
	},
	onGetMessages: (resource, record, message_count) => {
		dispatch({
			type: CRUD_UPDATE_SUCCESS,
			payload: {
				data: {
					...record,
					message_count: message_count
				}
			},
			meta: {
				resource,
				fetchResponse: UPDATE,
				fetchStatus: FETCH_END
			}
		})
	},
});

export const QuickChatButton = connect(mapStateToProps, mapDispatchToProps)(QuickChatButtonBase);


const ChatMessageCountField = (props) => {
	return (
		<FunctionField
			{...props}
			align='center'
			render={record =>
				<QuickChatButton
					resource={props.resource}
					record={record}
					source={props.source}
				/>
			}
		/>
	);
};


export default ChatMessageCountField;
