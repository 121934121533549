import React from 'react';
import { Resource } from 'react-admin';

import { adminResources } from './admin';
import { osfcManagerResources } from './osfc_manager';
import { osfcEmployeeResources } from './osfc_employee';

import { customerEmployeeResources } from './customer_employee';
import { customerManagerResources } from './customer_manager';

import {customerAndTransporterEmployeeResources} from "./customer_and_transporter_employee";
import {customerAndTransporterManagerResources} from "./customer_and_transporter_manager";

import { transporterAdminResources } from './transporter_admin';
import { transporterEmployeeResources } from "./transporter_employee";
import { transporterDriverResources } from "./transporter_driver";

import { UserRole } from '../../entities/UserRole';


export const resources ={
	[UserRole.admin]: adminResources,

	[UserRole.osfc_manager]: osfcManagerResources,
	[UserRole.osfc_employee]: osfcEmployeeResources,

	[UserRole.customer_manager]: customerManagerResources,
	[UserRole.customer_employee]: customerEmployeeResources,

	[UserRole.customer_and_transporter_manager]: customerAndTransporterManagerResources,
	[UserRole.customer_and_transporter_employee]: customerAndTransporterEmployeeResources,
	[UserRole.customer_and_transporter_driver]: transporterDriverResources,

	[UserRole.transporter_manager]: transporterAdminResources,
	[UserRole.transporter_employee]: transporterEmployeeResources,
	[UserRole.transporter_driver]: transporterDriverResources,
};


export const resourcesByPermissions = permissions => {
	return [
		...resources[permissions.role],
		<Resource name="settings" />,
	];
}
