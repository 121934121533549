import merge from 'lodash/merge';

import polyglotI18nProvider from 'ra-i18n-polyglot';

import englishMessages from 'ra-language-english';
import englishDomainMessages from './lang/en.json';

const englishTranslation = merge(englishMessages, englishDomainMessages);

export const defaultLocale = 'en';

export const i18nProvider = polyglotI18nProvider(locale => {
    switch(locale){
        case 'no':            
            return import('ra-language-norwegian').then(norwegianMessages => import('./lang/no.json').then(norwegianDomainMessages => {
				const translation = merge(norwegianMessages, norwegianDomainMessages);
				return translation;
			}));
        case 'en': default:
            return englishTranslation;

    }
}, defaultLocale);
