import * as React from 'react';
import {useHistory} from "react-router-dom";
import {Field, Form} from 'react-final-form';
import {Input, useStyles} from "../components/_extensions/react-admin/ra-ui-materialui/src/auth/LoginFormEx"

import {Button, CardActions} from '@material-ui/core';
import {useTranslate, useNotify, useSafeSetState} from 'ra-core';
import {authProvider} from "../api"


export const ForgotPasswordForm = props => {
	const [loading, setLoading] = useSafeSetState(false);
	const translate = useTranslate();
	const notify = useNotify();
	const classes = useStyles(props);
	const history = useHistory();

	const validate = (values) => {
		const errors = {username: undefined};

		if (!values.username) {
			errors.username = translate('ra.validation.required');
		}
		return errors;
	};

	const submit = values => {
		setLoading(true);
		authProvider.sendResetPasswordRequest(values)
			.then((data) => {
				notify("Check your email for reset password", {type: "success", autoHideDuration: 10000})
				setLoading(false);
			})
			.catch(error => {
				setLoading(false);
				notify(
					typeof error === 'string'
						? error
						: typeof error === 'undefined' || !error.message
							? 'ra.auth.sign_in_error'
							: error.message,
					'warning',
					{
						_:
							typeof error === 'string'
								? error
								: error && error.message
									? error.message
									: undefined,
					}
				);
			});
	};

	return (
		<Form
			onSubmit={submit}
			validate={validate}
			render={({handleSubmit}) => (
				<form onSubmit={handleSubmit} noValidate>
					<div className={classes.form}>
						<div className={classes.input}>
							<Field
								autoFocus
								id="username"
								name="username"
								component={Input}
								label={translate('ra.auth.username')}
								disabled={loading}
								defaultValue={props.history.location.state &&
									props.history.location.state.email}
							/>
						</div>
					</div>
					<CardActions>
						<Button
							variant="contained"
							type="submit"
							color="primary"
							className={classes.button}
							disabled={loading}
						>
							Send password reset email
						</Button>
					</CardActions>
					<CardActions>
						<Button
							href="/#/login"
							variant="contained"
							type="link"
							color="secondary"
							className={classes.button}
							onClick={() => {
								history.push({
									pathname: "/login",
									//email from first input
									state: {email: document.getElementsByTagName("input")[0].value}
								});
							}}
						>
							Login page
						</Button>
					</CardActions>
				</form>
			)}
		/>
	);
};
