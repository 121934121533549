import React from "react";
import { Filter, } from 'react-admin';
import TextInputEx from "../../../components/_extensions/react-admin/input/TextInputEx";
import { YesNoInput } from "../../../components/inputs/YesNoInput";

export const CompaniesFilter = props => (
	<Filter {...props}>
		<TextInputEx debounceTime={750} source="_query" alwaysOn />
		<TextInputEx debounceTime={750} source="full_name" />
		<TextInputEx debounceTime={750} source="short_name" />
		<TextInputEx debounceTime={750} source="email" />
		<TextInputEx debounceTime={750} source="phone" />
		<YesNoInput debounceTime={750} source="is_also_transporter" />
	</Filter>
);
