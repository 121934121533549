import React from "react";
import { Create, SimpleForm } from 'react-admin';

import { SaveWithCancelToolbar } from '../../../components/toolbars/SaveWithCancelToolbar';

import { CustomerDriverCreateForm } from './CreateForm';


export const DriverCreate = ({permissions, ...props}) => {
	const companyId = permissions && permissions.company_id;
	
	return (
		<Create {...props}>
			<SimpleForm submitOnEnter={false} toolbar={<SaveWithCancelToolbar />} redirect="list">
				<CustomerDriverCreateForm companyId={companyId}/>
			</SimpleForm>
		</Create>
	);
}
