import React from 'react';

import {
    TextInput, required, ArrayInput, useTranslate
} from 'react-admin';


import { 
    makeStyles
} from '@material-ui/core';

import SimpleFormIteratorEx from '../../../components/_extensions/react-admin/ra-ui-materialui/src/form/SimpleFormIteratorEx'; 

import { validateUnique } from '../../../utils/validation/general'; 

const useArrayInputStyle = makeStyles(() => ({
	root: {
		'& div.ra-input': {
			display: 'inline-block',
			marginLeft: '0.7em',
		},
	}
}));



export const MapperDataInput = props => {
  const arrayInputClasses = useArrayInputStyle();

	return <>
        <ArrayInput {...props}>
          <SimpleFormIteratorEx classes={arrayInputClasses}>
            <TextInput source="key" label="resources.customer-companies-mapper.fields.data.key" validate={[required(), validateUnique("key")]} />
            <TextInput source="value" label="resources.customer-companies-mapper.fields.data.value" validate={required()} />
          </SimpleFormIteratorEx>
        </ArrayInput>
  </>;
}

export const MapperDataInputPredefined =({optionalKeys, formData, ...rest}) => {
  const translate = useTranslate();
  const arrayInputClasses = useArrayInputStyle();

  const getValidator = (record, member, index, input, index2) => {
    if (input.props.source === "value" && optionalKeys.indexOf(formData['data'][index]["key"]) === -1) return required();
  };

	return <>
      <ArrayInput {...rest}>
        <SimpleFormIteratorEx classes={arrayInputClasses} disableAdd disableRemove disableReordering getValidator={optionalKeys && getValidator}>
          <TextInput source="key" label="resources.customer-companies-mapper.fields.data.key" validate={[required(), validateUnique("key")]} disabled
                    format={val => translate(`resources.customer-companies-mapper.fields.data.${val}`)} />
          <TextInput source="value" label="resources.customer-companies-mapper.fields.data.value" />
        </SimpleFormIteratorEx>
      </ArrayInput>
  </>;
}