import React from 'react';

import { TextInput } from 'react-admin';


const integerNumberParse = (v) => {
	if (v) {
		const match = String(v).match(/\d/gi);
		return Number(match && match.join(''));
	}
	return v;
};

const floatNumberParse = (v, counter = '+') => {
	if (v) {
		const regRaw = `\\d+[,.]?(\\d${counter})?`;
		const reg = new RegExp(regRaw);

		const match = String(v).replace(/\s/g, '').match(reg);
		if (match == null) 
			return '';

		const parsedFloatString = match && match[0].replace(/,/g, '.');
		if (parsedFloatString === '' || parsedFloatString[parsedFloatString.length - 1] === '.')
			return parsedFloatString;

		return parseFloat(parsedFloatString);
	}
	return v;
}


export const NumberInputEx = ({ type, digitsCount, ...props }) => {
	const floatParser = React.useCallback((v) => floatNumberParse(v, digitsCount ? `{0,${digitsCount}}` : '+'), [digitsCount]);

	return (
    	<TextInput
			parse={type === "float" ? floatParser : integerNumberParse}
    		{...props}
    	/>
    );
}
