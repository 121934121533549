import React from 'react';

import { GridInput, GridInputMultiChanges } from './GridInput'
import { FormGroup, FormGroupGrid } from './FormGroup'


export const cloneGridInput = ({ enabledFields, ...parentProps }, child) => {
	return React.cloneElement(child, {
		resource: parentProps.resource,
		basePath: parentProps.basePath,
		record: parentProps.record,
		multiData: parentProps.multiData,
		...child.props,
		disabled: child.props.disabled || (enabledFields && !(((child.props.source && enabledFields[child.props.source]) || (child.props.sourceText && enabledFields[child.props.sourceText]) || (child.props.sourceSelect && enabledFields[child.props.sourceSelect])))),
		margin: "none",
		size: "small",
	});
}


export const cloneFormGroup = ({ enabledFields, ...parentProps }, child) => {
	return React.cloneElement(child, {
		resource: parentProps.resource,
		basePath: parentProps.basePath,
		record: parentProps.record,
		multiData: parentProps.multiData,
		...child.props,
		enabledFields,
	});
}


export const cloneFormGridItem = (parentProps, child) => {
	if (!child) {
		return child;
	}

	if (child.type === GridInput || child.type === GridInputMultiChanges) {
		return cloneGridInput(parentProps, child);
	} else if (child.type === FormGroupGrid || child.type === FormGroup) {
		return cloneFormGroup(parentProps, child);
	}

	return child;
}
