import React from 'react';

import { Filter, useTranslate } from 'react-admin';

import TextInputEx from '../../../components/_extensions/react-admin/input/TextInputEx';
import { AdminLogLevelSelect } from '../components/AdminLogLevelSelect';
import { AdminLogModuleMultipleSelect } from '../components/AdminLogLevelModule';
import { AdminLogModuleType } from '../../../entities/AdminLogModuleType';
import { DateTimeInput } from '../../../components/inputs';


export const AdminLogsFilter = (props) => {
	const translate = useTranslate()

	return <Filter {...props} >
		<DateTimeInput source='created_at.created_at_from' label={translate('osfc.filters.created_at.from')}/>
		<DateTimeInput source='created_at.created_at_to' label={translate('osfc.filters.created_at.to')}/>
		<TextInputEx source='order_id'/>
		<AdminLogLevelSelect source='log_level'/>
		<AdminLogModuleMultipleSelect source='module' enumObj={AdminLogModuleType} enumName="log-module" />
	</Filter>
};
