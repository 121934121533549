import React from 'react';

import MuiPhoneNumberField from '../../components/PhoneNumber/PhoneNumberField';

export const PhoneField = props => {
  if (!props.record) return null;
  return <MuiPhoneNumberField {...props} addLabel={true} regions='europe' />;
};

// import Typography from '@material-ui/core/Typography';

const PHONE_REGEXP = /^(\d+?)?(\d{3})(\d{3})(\d{2})(\d{2})$/;

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(PHONE_REGEXP);
  if (match) {
    var intlCode = (match[1] ? '+' + match[1] + ' ' : '')
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4], '-', match[5]].join('')
  }
  return null
}


// export const PhoneField = ({ record = {}, source }) => {
// 	const formattedPhone = record[source] ? formatPhoneNumber(record[source]) : '-';
// 	return (
// 		<Typography variant="body2">
// 			{formattedPhone}
// 		</Typography>
// 	);
// }

PhoneField.defaultProps = {
    addLabel: true,
};

