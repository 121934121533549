import React from 'react';
import { SelectInput } from 'react-admin';

import * as PriceCategory from '../../entities/PriceCategory';


export const PriceCategorySelect = (props) => {
	return (
		<SelectInput {...props} choices={PriceCategory.list} />
	);
};
