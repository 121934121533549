import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';


export const ToolbarButton = (props) => (
	<Box ml={1}>
		<Button
			disabled={props.disabled}
			startIcon={props.icon}
			// size="small"
			size="medium"
			color={props.color || "primary"}
			variant="outlined"
			onClick={props.onClick}
			href={props.href}
			target={props.target}
		>{props.children}</Button>
	</Box>
);
