import React, {useEffect, useState} from "react";
import {SimpleShowLayout, RichTextField, useTranslate} from "react-admin";
import {Box, Typography, Accordion, AccordionSummary, AccordionDetails, Grid} from "@material-ui/core"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useWindowSizeWhenResize} from "../../../components/_helpers/useWindowSizeWhenResize";
import {DocumentItem} from "../../../components/fields/DocumentsField/Item"

// export const SearchResultRowExpand = ({aboveListRef, ...props}) => {
// 	const translate = useTranslate();
// 	const windowSize = useWindowSizeWhenResize();
// 	const [width, setWidth] = useState(null);


// 	useEffect(() => {
// 		function calculateWidth() {
// 			return aboveListRef.current ? aboveListRef.current.clientWidth - 16 : 600;
// 		}
// 		const setWidthWithCalculation = () => setWidth(calculateWidth());

// 		setWidthWithCalculation()

// 	}, [aboveListRef, windowSize]);

// 	return (
// 		<Box width={width}
// 			 style={{
// 				 borderRadius: '5px',
// 				 backgroundColor: "#cfd8dc",
// 				 position: "sticky",
// 				 left: 8,
// 				 marginRight: -8,
// 				 whiteSpace: "pre-wrap",
// 			 }}>
// 			<SimpleShowLayout>

// 				<RichTextField source={"highlight"} label={translate("osfc.fields.search_in_docs.found_text")}/>

// 				<Accordion>
// 					<AccordionSummary
// 						expandIcon={<ExpandMoreIcon/>}
// 						aria-controls="panel1a-content"
// 						id="panel1a-header"
// 					>
// 						<Typography>
// 							{/*{props.record.rank}*/}
// 							{translate("osfc.fields.search_in_docs.full_text")}...</Typography>
// 					</AccordionSummary>
// 					<AccordionDetails>
// 						<Typography>
// 							{props.record.text}
// 						</Typography>
// 					</AccordionDetails>
// 				</Accordion>

// 			</SimpleShowLayout>
// 		</Box>
// 	);
// };

// export const SearchResultSimpleRowExpand = ({aboveListRef, ...props}) => {
// 	// const translate = useTranslate();
// 	const windowSize = useWindowSizeWhenResize();
// 	const [width, setWidth] = useState(null);


// 	useEffect(() => {
// 		function calculateWidth() {
// 			return aboveListRef.current ? aboveListRef.current.clientWidth - 16 : 600;
// 		}
// 		const setWidthWithCalculation = () => setWidth(calculateWidth());

// 		setWidthWithCalculation()

// 	}, [aboveListRef, windowSize]);

// 	const documentData = {
// 		order_id: props.record["id"],
// 		id: props.record["doc_id"],		
// 		title: props.record["doc_title"],
// 		type: props.record["doc_type"],
// 		src: props.record["doc_src"],
// 		thumbnail: props.record["doc_thumbnail"],
// 		parsed_status: 1
// 	};

// 	return (
// 		<Box width={width}
// 			 style={{
// 				 borderRadius: '5px',
// 				//  backgroundColor: "#cfd8dc",
// 				 position: "sticky",
// 				 left: 8,
// 				 marginRight: -8,
// 				 whiteSpace: "pre-wrap",
// 			 }}>
// 			{/* <SimpleShowLayout> */}
// 			<Grid key={documentData.id} item xs={12} sm={10} md={8} lg={6}>
// 				<DocumentItem isReadOnly={true} data={documentData} />
// 			</Grid>
// 			{/* </SimpleShowLayout> */}
// 		</Box>
// 	);
// };


export const SearchResultDocumentsRowExpand = (props) => {	
	const data = props.record["documents"];
	const order_id = props.record["id"];

	return (		
		<Grid container spacing={2}>
			{data && data.map(item => 
				<Grid key={item.id} item xs={12} sm={6} md={3}>
					<DocumentItem isReadOnly={true} data={{...item, order_id: order_id, parsed_status: 1}} />
				</Grid>
			)}
		</Grid>
	);
};
