import EventIcon from '@material-ui/icons/Event';

import { HolidaysList } from './List/List';


export { HolidaysList }


export const pricelistHolidaysResource = {
	list: HolidaysList,
	icon: EventIcon,
}
