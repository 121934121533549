import React from 'react';
import { FunctionField } from 'react-admin';

import { LabelSourceNames } from '../../entities/LabelSource';
import { useTranslate } from 'react-admin';

export const LabelSourceField = (props) => {
    const t =useTranslate()
    return (
        <FunctionField {...props} addLabel={true} render={record => t('resources.orders.fields.' + LabelSourceNames[props.record.label_source].toLowerCase())} />
    );
};


LabelSourceField.defaultProps = {
    addLabel: true,
};
