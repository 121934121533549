import React from 'react';
import DatagridContext from './DatagridContext';

const DatagridContextProvider = ({
    children,
    value,
}) => (
    <DatagridContext.Provider value={value}>
        {children}
    </DatagridContext.Provider>
);

export default DatagridContextProvider;
