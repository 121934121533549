import React from 'react';
import { useMutation, useNotify, useRedirect, useUnselectAll } from 'react-admin';

import { useNotifyError } from '../utils/notifiers/useNotifyError';

export const useMutateForm = (type, resource, basePath, id, options) => {
	const notify = useNotify();
	const notifyError = useNotifyError();
	const redirect = useRedirect();
	const unselectAll = useUnselectAll();

	const updateManyTypes = ['updateMany', 'updateManyArray'];
	const payload = (updateManyTypes.includes(type) ? { ids: id } : { id });

	const [mutate, state] = useMutation(
		{ type, resource, payload },
		{
			...options,
			returnPromise: true
		}        
    );

	const msgSuccess = (
		type === 'create' ? 
			'ra.notification.created' 
		: 
			['update', ...updateManyTypes].includes(type) ? 
				'ra.notification.updated' 
			: 'Done'
	);

	const mutateForm = React.useCallback(
		async (values, redirectTo) => {
			try {
				await mutate({payload: { data: values }}, {
					onSuccess: (_a) => {
						if (updateManyTypes.includes(type)) {
							unselectAll(resource);
							redirect(redirectTo, basePath);
						} else {
							const record = (type === 'create' ? _a.data : (type === 'update' ? values : null));
							redirect(redirectTo, basePath, record.id, record);
						};
						notify(msgSuccess, 'info', { smart_count: 1 });
					}
				});			
			} catch (error) {
				if (Array.isArray(error.body)) {
					const result = {}
					for (const item of error.body){
						if (item.location === "body") {
							result[item.name] = item.description;
						};
					}
					notify('ra.notification.bad_item', 'error');
					return result;
				} else {
					notifyError(error);
				}
			}
		},
		[mutate] // eslint-disable-line react-hooks/exhaustive-deps
	);

	return [mutateForm, state];
};