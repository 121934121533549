import React from 'react';
import { useState } from 'react';

import { Button, Confirm, useRecordContext, useDelete, useRefresh, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useDeleteButtonStyles = makeStyles({
	label: {
		paddingLeft: '0',
	},
	button: {
		backgroundColor: '#f44336',
		color: 'white',
		'&:hover': {
			backgroundColor: '#ff7961',
		},
	},
});

export const DeleteButton = ({notifyMessage, title, content, ...props}) => {
    const record = useRecordContext();
    const [open, setOpen] = useState(false);
	const refresh = useRefresh();
    const notify = useNotify();

    const deleteButtonClasses = useDeleteButtonStyles();

    const [remove, { isLoading }] = useDelete(
        props.resource,
		record.id
    );

	const handleClick = (e) => {
		e.stopPropagation();
		setOpen(true)
	};

    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        remove({}, {
          onSuccess: () => {
            refresh()
            notify(notifyMessage, "info");
          },
          onFailure: (error) => {
            notify(error.message, "error");
          }
        });
        setOpen(false);
    };

    return (
        <>
            <Button 
                label={"osfc.buttons.delete"}
                classes={deleteButtonClasses}
                onClick={handleClick} />
            <Confirm
                isOpen={open}
                loading={isLoading}
                title={title}
                content={content}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
				confirm="osfc.buttons.confirm"
            />
        </>
    );
};