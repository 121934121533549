import React from 'react';
import { TextInput, useMutation, linkToRecord } from 'react-admin';
import { useFormState } from 'react-final-form';
import { Link } from 'react-router-dom';

import { ProgressIcon } from '../../../../components/ProgressIcon';
import { LargerTooltip } from '../../../../components/LargerTooltip';
import NotOkIcon from '@material-ui/icons/Warning';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import { useDebouncedCallback } from 'use-debounce';


export const useStyles = makeStyles({
	link: {
		color: '#fff',
		cursor: 'pointer',
		marginRight: 4,
		
		'&:last-child': {
			marginRight: 0,
		}
	}
});


export const OrderReferenceInput = (props) => {
	const classes = useStyles();
	const [tooltipIsOpen, setTooltipIsOpen] = React.useState(true);
	const { values } = useFormState();
	const value = values[props.source];
	const company_id = values["company_id"] ?? "";
	const [mutate, { loading, data }] = useMutation({
		type: 'getOne',
		resource: 'order-reference',
	}, {
		onSuccess() {
			setTooltipIsOpen(true);
		}
	});

	const handleCheckReference = useDebouncedCallback(() => {
		mutate({
			payload: {
				id: company_id + ";" + value				
			}
		});
	}, 1000);

	const orders = React.useMemo(() => {
		if (data?.orders) {
			return data?.orders.filter(o => o !== values.id);
		}
		return [];
	}, [data?.orders, values.id]);
	const hasIcon = !!(loading || orders.length);


	const endAdornment = (hasIcon &&
		<InputAdornment position="end">
			{loading ? <ProgressIcon color="primary" /> : (
				orders.length ? (
					<LargerTooltip
						onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
						title={(
							<span>
								Reference '{data?.id}' is currently used in {orders.length > 1 ? 'orders' : 'order'}: {orders.map(o => 
									<Link key={o} to={linkToRecord("/orders", o)} className={classes.link}>{o}</Link>
								)}
							</span>
						)}
						open={tooltipIsOpen}
						interactive
						arrow
						position="bottom" color="primary"
					>
						<NotOkIcon style={{ color: '#f39c12', cursor: 'pointer' }} />
					</LargerTooltip>
				) : null
			)}
		</InputAdornment>
	);

	return (
		<TextInput
			{...props}
			onChange={handleCheckReference}
			InputProps={{
				endAdornment: endAdornment,
			}}
		/>
	);
};
