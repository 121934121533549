import React from "react";
import { List, Datagrid, TextField } from 'react-admin';

import { ScrollingWrapper } from '../../../components/ScrollingWrapper';
import { DatagridWithBlockProps } from '../../../components/_helpers/props/DatagridProps';
import { DateFormatField } from '../../../components/fields/DateFormatField';

const defaultSort = { field: 'id', order: 'DESC' };


export const HolidaysList = props => {

	return (
		<List
			{...props}
			exporter={false}
			bulkActionButtons={false}
			sort={defaultSort}
			pagination={null}
			actions={null}
		>
			<ScrollingWrapper>
				<Datagrid
					{...DatagridWithBlockProps}
					rowClick={null}
					size="small"
				>
					{/* <TextField source="id" sortable={false} /> */}
					<DateFormatField source="date" sortable={false} />
					<TextField source="name" sortable={false} />
				</Datagrid>
			</ScrollingWrapper>
		</List>
	);
}
