import React from 'react';
import get from 'lodash/get';
import { ReferenceField, TextField, useRecordContext } from 'react-admin';


export const SelectTextField = ({ refSource, ...props }) => {
	const record = useRecordContext(props);
	const emptyText = get(record, props.source);

	return (
		<ReferenceField link={false} {...props} source={refSource} emptyText={emptyText}>
			<TextField source="name" />
		</ReferenceField>
	);
};


SelectTextField.defaultProps = {
	addLabel: true,
};
