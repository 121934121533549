import * as React from 'react';
import { useTranslate } from 'react-admin';

import { 
    List, ListItem, ListItemText, ListItemIcon, 
    Collapse, Typography, makeStyles 
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import LinkIcon from '@material-ui/icons/Link';


const useStyles = makeStyles(theme => ({
    leftIcon: { 
        minWidth: theme.spacing(5),
    },
    expandItems: {
        paddingLeft: '25px',
    },
    emptyBlock: {
        display: 'flex',
        justifyContent: 'center',
    }
}));


export const SubMenu = ({ 
    primaryText = "", 
    isDropdownOpen = false, 
    leftIcon = <LinkIcon />,
    expandMoreIcon = <ExpandMoreIcon />,
    expandLessIcon = <ExpandLessIcon />,
    children, 
    ...props 
}) => {
    const classes = useStyles();
    const translate = useTranslate();

    const [isOpen, setIsOpen] = React.useState(isDropdownOpen);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <List key={primaryText} component="nav">
            <ListItem button onClick={handleToggle}>
                <ListItemIcon className={classes.leftIcon}>
                    {leftIcon}
                </ListItemIcon>
                <ListItemText primary={translate(primaryText)} />
                {isOpen ? expandLessIcon: expandMoreIcon}
            </ListItem>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                {children?.length === 0 || children === undefined?
                    <div className={classes.emptyBlock}>
                        <Typography color="textSecondary">Empty</Typography>
                    </div>
                :
                    <List component="div" className={classes.expandItems}>
                        {children}
                    </List>
                }
            </Collapse>
        </List>
    );
};
