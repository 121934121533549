import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';


export const FlagIcon = withStyles(theme => ({
	root: {
		width: '18px',
		height: '18px',
		border: '1px solid white',
		borderRadius: '50%',
	},
}))(SvgIcon);
