import React from 'react';
import { Resource } from 'react-admin';

import { ordersResource, OrderEditByCustomer, OrderCreateByCustomer } from '../../resources/orders/';

import { pricelistArticlesResource } from '../../resources/customer-pricelist-articles';
// import { customerCompaniesResource } from '../../resources/customer-companies/';
import { pricelistHolidaysResource } from '../../resources/pricelist-holidays/';


export const customerEmployeeResources = [
	<Resource name="orders" {...ordersResource} edit={OrderEditByCustomer} create={OrderCreateByCustomer} />,
	<Resource {...pricelistArticlesResource} name="customer-pricelist-articles" />,
	<Resource {...pricelistHolidaysResource} name="pricelist-holidays" />,
	<Resource name="ports" intent="registration" />,
	<Resource name="areas" intent="registration" />,
	<Resource name="users" intent="registration" />,
	<Resource name="order-statuses" intent="registration" />,
	<Resource name="outgoing-doc-statuses" intent="registration" />,
	<Resource name="cooler-types" intent="registration" />,
	<Resource name="order-pricelist" intent="registration" />,
	<Resource name="drivers" intent="registration" />,
	<Resource name="transporters" intent="registration" />,
	<Resource name="customer-companies" intent="registration" />,
	<Resource name="trucks" intent="registration" />,
	<Resource name="trailers" intent="registration" />,
	<Resource name="terminal" intent="registration" />,
];
