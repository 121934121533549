import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import LinearProgress from '@material-ui/core/LinearProgress';


export const AutoTable = React.forwardRef(({ loading, header, rows, totalCount, rowsPerPageOptions, page, rowsPerPage, setPage, setRowsPerPage }, ref) => {
	if (loading) {
		return (
			<LinearProgress />
		);
	}

	const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

	return (
		<React.Fragment>
			<Table ref={ref} size="small">
				<TableHead>
					<TableRow>
						{header.map(([key, title]) => <TableCell key={key}>{title}</TableCell>)}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map(order =>
						<TableRow key={order.id}>
							{header.map(([key]) => <TableCell key={key}>{order[key]}</TableCell>)}
						</TableRow>
					)}
				</TableBody>
				<TableFooter>
					{rows.length ?
					<TableRow>
						<TablePagination
							rowsPerPageOptions={rowsPerPageOptions}
							// colSpan={3}
							count={totalCount}
							rowsPerPage={rowsPerPage}
							page={page}
							SelectProps={{
								inputProps: { 'aria-label': 'rows per page' },
								native: true,
							}}
							onChangePage={handleChangePage}
							onChangeRowsPerPage={handleChangeRowsPerPage}
							//   ActionsComponent={TablePaginationActions}
						/>
					</TableRow> :
						<TableRow><TableCell align="center">No orders</TableCell></TableRow>
					}
				</TableFooter>
			</Table>
		</React.Fragment>
	);
});
