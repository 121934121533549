import React from 'react';
import {
    useLocale, useSetLocale, usePermissions, useMutation, useNotify
} from "react-admin";
import {
    makeStyles, Menu, MenuItem, IconButton, ListItemIcon
} from '@material-ui/core';

import { FlagEN, FlagNO } from '../components/icons/flags'
import { setPermissionsLocale } from '../api/authProvider'

const useStyles = makeStyles(theme => ({
	listItemIcon: {
		minWidth: 'auto',
	},
}));

const dataLocale = {
    'en': {text: 'english', icon: <FlagEN style={{width: 16, height: 16}} />},
    'no': {text: 'norwegian', icon: <FlagNO style={{width: 16, height: 16}} />}
}

export const LocaleSwitcher = () => {
    const classes = useStyles();
    const notify = useNotify();
    const locale = useLocale();
    const setLocale = useSetLocale();    
    const [anchorEl, setAnchorEl] = React.useState(null);    
    const [ mutate ] = useMutation({
		type: 'update',
		resource: 'settings',
	}, {
		withDeclarativeSideEffectsSupport: true,
		onSuccess: ({ data }) => {
            // because permissions.permissions == undefined here
            // permissions.permissions.locale = data.locale;
            // setPermissions(permissions.permissions);
            setPermissionsLocale(data.locale);
		},
		onFailure: (error) => {
			notify(error.message, "error");
		}
    });    
    const permissions = usePermissions();
    const permissionsLocale = permissions.permissions ? permissions.permissions.locale : null;   
    
    React.useEffect(() => {
        if (permissionsLocale){
            setLocale(permissionsLocale);
        }
    }, [permissionsLocale, setLocale]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (key) => {        
        setAnchorEl(null);
        setLocale(key);
        mutate(
			{
				payload: {
					id: permissions.permissions.id,
					data: { locale: key },
				}
			},
		);
    };

    return (
        <React.Fragment>		
            <IconButton color="primary" component="span" onClick={handleClick}>
                {dataLocale[locale].icon}
            </IconButton>
            <Menu		  
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {Object.keys(dataLocale).map((key, i) => (
                    <MenuItem key={key} disabled={locale === key} onClick={() => handleMenuItemClick(key)}>
                        <ListItemIcon className={classes.listItemIcon}>{dataLocale[key].icon}</ListItemIcon>
                    </MenuItem>
                ))}          
            </Menu>
        </React.Fragment>
    );
}
