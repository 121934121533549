import React from 'react';

import { useNotify } from 'react-admin';
import { uploadFile } from '../../../api/uploadFile';
import { httpClient } from '../../../api/httpClient';
import { useNotifyError } from '../../../utils/notifiers/useNotifyError';

export const useOrderFileUpload = (handleAdded, orderId) => {
	const notify = useNotify(false);
	const notifyError = useNotifyError();	
	const [ uploaded, setUploaded ] = React.useState(undefined);
	const [ uploading, setUploading ] = React.useState(false);

	const resetUpload = React.useCallback(() => {
		setUploaded(undefined);
	}, []);

	const handleUploadFile = React.useCallback(async (file) => {
		// const file = e.target.files[0];
		setUploading(true);

		try {
			const uploadedFile = await uploadFile(file);
	        httpClient(`orders/${orderId}/documents`, {
	            method: 'POST',
	            body: JSON.stringify({
					title: uploadedFile.filename,
					src: uploadedFile.src,
					thumbnail: uploadedFile.thumbnail,
				}),
	        }).then(({ json }) => {
				setUploaded(json);
				setUploading(false);
				handleAdded();
			}).catch(error => {
				setUploading(false);
				notifyError(error);
			});			
		} catch(error) {			
			if (error.body && error.body.message) {
				notify(error.body.message, "error");
			} else if (error.status === 413) {
				notify("File is too large", "error");
			} else {
				notifyError(error);
			}
			console.dir(error);
		}
	}, [notify, handleAdded, orderId, setUploaded]);

	return [ uploading, handleUploadFile, uploaded, resetUpload ];
}
