import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import {
	some, find, reduce, filter, includes,
	head, tail, memoize, trim, startsWith,
	// findIndex,
} from 'lodash';
import * as countryData from './country_data';


class MuiPhoneNumberField extends React.Component {
	flags = {};

	guessSelectedCountry = memoize((inputNumber, onlyCountries, defaultCountry) => {
		const secondBestGuess = find(onlyCountries, { iso2: defaultCountry }) || {};
		if (trim(inputNumber) === '') return secondBestGuess;

		const bestGuess = reduce(onlyCountries, (selectedCountry, country) => {
			if (startsWith(inputNumber, country.dialCode)) {
				if (country.dialCode.length > selectedCountry.dialCode.length) {
					return country;
				}
				if (country.dialCode.length === selectedCountry.dialCode.length && country.priority < selectedCountry.priority) {
					return country;
				}
			}
			return selectedCountry;
		}, { dialCode: '', priority: 10001 }, this);

		if (!bestGuess.name) return secondBestGuess;
		return bestGuess;
	});

	constructor(props) {
		super(props);
		let filteredCountries = countryData.allCountries;

		if (props.disableAreaCodes) filteredCountries = this.deleteAreaCodes(filteredCountries);
		if (props.regions) filteredCountries = this.filterRegions(props.regions, filteredCountries);

		const onlyCountries = this.excludeCountries(
			this.getOnlyCountries(props.onlyCountries, filteredCountries), props.excludeCountries,
		);

		const preferredCountries = filter(filteredCountries, (country) => some(props.preferredCountries, (preferredCountry) => preferredCountry === country.iso2));

		const inputNumber = props.record[props.source] || '';
		// const inputNumber = props.value || '';

		let countryGuess;
		if (inputNumber.length > 1) {
			// Country detect by value field
			countryGuess = this.guessSelectedCountry(inputNumber.replace(/\D/g, '').substring(0, 6), onlyCountries, props.defaultCountry) || 0;
		} else if (props.defaultCountry) {
			// Default country
			countryGuess = find(onlyCountries, { iso2: props.defaultCountry }) || 0;
		} else {
			// Empty params
			countryGuess = 0;
		}

		// const countryGuessIndex = findIndex(this.allCountries, countryGuess);
		const dialCode = (
			inputNumber.length < 2
			&& countryGuess
			&& !startsWith(inputNumber.replace(/\D/g, ''), countryGuess.dialCode)
		) ? countryGuess.dialCode : '';

		const formattedNumber = (inputNumber === '' && countryGuess === 0) ? ''
			: this.formatNumber(
				(props.disableCountryCode ? '' : dialCode) + inputNumber.replace(/\D/g, ''),
				countryGuess.name ? countryGuess.format : undefined,
			);

		this.state = {
			formattedNumber,
			placeholder: props.placeholder,
			onlyCountries,
			preferredCountries,
			defaultCountry: props.defaultCountry,
		};
	}
	
	getOnlyCountries = (onlyCountriesArray, filteredCountries) => {
		if (onlyCountriesArray.length === 0) return filteredCountries;

		return filteredCountries.filter((country) => onlyCountriesArray.some((element) => element === country.iso2));
	}

	excludeCountries = (selectedCountries, excludedCountries) => {
		if (excludedCountries.length === 0) {
			return selectedCountries;
		}
		return filter(selectedCountries, (selCountry) => !includes(excludedCountries, selCountry.iso2));
	}

	filterRegions = (regions, filteredCountries) => {
		if (typeof regions === 'string') {
			const region = regions;
			return filteredCountries.filter((country) => country.regions.some((element) => element === region));
		}

		return filteredCountries.filter((country) => {
			const matches = regions.map((region) => country.regions.some((element) => element === region));
			return matches.some((el) => el);
		});
	}

	// Countries array methods
	deleteAreaCodes = (filteredCountries) => filteredCountries.filter((country) => country.isAreaCode !== true);
 

	formatNumber = (text, patternArg) => {
		const { disableCountryCode, enableLongNumbers, autoFormat } = this.props;

		let pattern;
		if (disableCountryCode && patternArg) {
			pattern = patternArg.split(' ');
			pattern.shift();
			pattern = pattern.join(' ');
		} else {
			pattern = patternArg;
		}

		if (!text || text.length === 0) {
			return disableCountryCode ? '' : '+';
		}

		// for all strings with length less than 3, just return it (1, 2 etc.)
		// also return the same text if the selected country has no fixed format
		if ((text && text.length < 2) || !pattern || !autoFormat) {
			return disableCountryCode ? text : `+${text}`;
		}

		const formattedObject = reduce(pattern, (acc, character) => {
			if (acc.remainingText.length === 0) {
				return acc;
			}

			if (character !== '.') {
				return {
					formattedText: acc.formattedText + character,
					remainingText: acc.remainingText,
				};
			}

			return {
				formattedText: acc.formattedText + head(acc.remainingText),
				remainingText: tail(acc.remainingText),
			};
		}, {
			formattedText: '',
			remainingText: text.split(''),
		});

		let formattedNumber;
		if (enableLongNumbers) {
			formattedNumber = formattedObject.formattedText + formattedObject.remainingText.join('');
		} else {
			formattedNumber = formattedObject.formattedText;
		}

		// Always close brackets
		if (formattedNumber.includes('(') && !formattedNumber.includes(')')) formattedNumber += ')';
		return formattedNumber;
	}


	getElement = (index) => this.flags[`flag_no_${index}`]

	render() {
		return (
			<Typography variant="body2">
				{this.state.formattedNumber}
			</Typography>
		);
	}
}

MuiPhoneNumberField.propTypes = {
	addLabel: PropTypes.bool,
	classes: PropTypes.object,
	
	excludeCountries: PropTypes.arrayOf(PropTypes.string),
	onlyCountries: PropTypes.arrayOf(PropTypes.string),
	preferredCountries: PropTypes.arrayOf(PropTypes.string),
	defaultCountry: PropTypes.string,

	autoFormat: PropTypes.bool,
	disableAreaCodes: PropTypes.bool,
	disableCountryCode: PropTypes.bool,
	enableLongNumbers: PropTypes.bool,
	countryCodeEditable: PropTypes.bool,
	
	regions: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
};

MuiPhoneNumberField.defaultProps = {
	addLabel: true,
	excludeCountries: [],
	onlyCountries: [],
	preferredCountries: [],
	defaultCountry: '',

	autoFormat: true,
	disableAreaCodes: false, 
	disableCountryCode: false,
	enableLongNumbers: false,
	countryCodeEditable: true,

	regions: '',
};

export default MuiPhoneNumberField;
