import React from 'react';
import { SelectInput } from 'react-admin';


export const choices = [
    { "id": 0, "name": "NO" },
    { "id": 1, "name": "1" },
    { "id": 2, "name": "2" },
    { "id": 3, "name": "3" },
    { "id": 4, "name": "4" },
    { "id": 5, "name": "5" },
];


export const PrintedDocsInput = (props) => {
	return (
		<SelectInput {...props} choices={choices} />
	);
};
