import React from "react";

import { TextInput, required } from 'react-admin';

import { GridForm, GridInput } from '../../../components/GridForm/';
import { CompanySelect } from '../../../components/inputs/CompanySelect';
import { parseDigitsAndLetters } from '../../../components/_helpers/parsers';


export const TrailersCreateForm = ({isAdminOrOSFCManager, ...props}) => (
	<GridForm {...props}>
		{isAdminOrOSFCManager && <GridInput sm={7} component={CompanySelect} 
											source="company_id" reference="companies" 
											validate={[required()]} />}
		<GridInput sm={5} component={TextInput} source="name" validate={[required()]} 
				   inputProps={{ style: {textTransform:'uppercase'} }}
				   parse={parseDigitsAndLetters(1, 10)} autoFocus />
	</GridForm>
);
