import React from 'react';

import { TopToolbar, useRedirect, useTranslate, usePermissions } from 'react-admin';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';

import { MuiButtonMainProps } from '../../../../components/_helpers/props/MuiButtonProps';


export const ActionsWithBackButton = ({ basePath }) => {
	const redirect = useRedirect();
	const t = useTranslate();
	const { permissions } = usePermissions();

	if (!permissions) return null;

	return (
		<TopToolbar>
			<Box display="flex">
				<Button startIcon={<ArrowBack/>} {...MuiButtonMainProps} size="small"
						onClick={e => redirect(basePath)}>
					{t('ra.action.back')}
				</Button>
			</Box>
			<Box display="flex" flex="1"/>
		</TopToolbar>
	);
}
