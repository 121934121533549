import React from "react";
import { TextInput, required } from 'react-admin';

import { GridForm, GridInput } from '../../../components/GridForm';
import { PhoneInput, EmailInput } from '../../../components/inputs';

const requiredValidator = [required()];

export const TransporterEditForm = props => (
	<GridForm {...props}>
		<GridInput sm={1} component={TextInput} source="id" disabled />
		<GridInput sm={6} component={TextInput} source="full_name" validate={requiredValidator} autoFocus />
		<GridInput sm={5} component={TextInput} source="short_name" validate={requiredValidator} />
		<GridInput sm={4} component={PhoneInput} source="phone" validate={requiredValidator} />
		<GridInput sm={4} component={EmailInput} source="email" validate={requiredValidator} />
		<GridInput sm={4} component={EmailInput} source="notification_email" validate={requiredValidator} />
	</GridForm>
);
