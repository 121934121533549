import React from "react";

import { TextInput, maxLength } from 'react-admin';
import { useFormState } from 'react-final-form';

import { requiredIfTrue } from '../../../../utils/validation/requiredIfNoFieldValue';
import { makeFormatter } from '../../../../components/formatter';


const tripNumberFormatter = makeFormatter([
	// { char: /\d/, repeat: 3 },
	// { exactly: "-" },
	// { char: /\d/, repeat: 2 },
	// { char: /[a-z]/i }
	{ char: /[\d\w]/i, repeat: 8 },
]);


export const TripNumberInput = ({ validate=[], ...props }) => {
	const { values } = useFormState();
	if (validate !== null) {
		validate = [maxLength(8), requiredIfTrue(!!values.split_load), ...validate];
	}
	return (
		<TextInput
			parse={tripNumberFormatter}
			validate={validate}
			{...props}
		/>
	);
};
