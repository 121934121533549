import React, { useEffect } from 'react';
import { Filter, ReferenceInput, SelectInput, useDataProvider, DateInput, useTranslate } from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";
import { ToggleTerminalsFilter, TERMINAL_ALL_VALUE } from '../../../components/filters/ToggleTerminalsFilter';
import { filterColors } from '../../../utils/constants';
import { SelectEnumInput } from '../../../components/inputs/SelectEnumInput';
import { DirectionType } from '../../../entities/DirectionType';

export const filterDefaultValues = {
    "terminal_id": [TERMINAL_ALL_VALUE],
};
const useStyles = makeStyles(t => ({
    filterTerminal: {
        backgroundColor: filterColors.in_terminal
    },
    filterSearch: {
        backgroundColor: filterColors.search
    },
}));


export const TrucksTrafficFilters = (props) => {
    const translate = useTranslate();
    const classes = useStyles();
    return (
        <Filter {...props}>
            <ToggleTerminalsFilter source="terminal_id" defaultValue={null} alwaysOn key={"terminal_id"} />
            <SelectEnumInput
            source="vehicle_direction_type"
            key={"vehicle_direction_type"}
            enumName="vehicle_direction_type"
            resettable
            enumObj={DirectionType}
            />
        </Filter>
    );
}