import * as React from 'react';
import { memo } from 'react';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';
import { Link } from '@material-ui/core';
import { useRecordContext } from 'ra-core';

import sanitizeFieldRestProps from './sanitizeFieldRestProps';
import { fieldPropTypes } from './types';

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = e => e.stopPropagation();

const EmailFieldEx = memo(props => {
    const { className, source, emptyText, mailMessageDict = {}, ...rest } = props;
    const record = useRecordContext(props);
    const value = get(record, source);
    
    const mailLink = React.useMemo(() => {
        const link = `mailto:${value}`;

        const paramsList = Object.entries(mailMessageDict).map(
            ([key, value]) => `${key}=${value}`
        );

        if (paramsList.length > 0) {
            const query = `?${paramsList.join('&')}`;
            return `${link}${query}`;
        };
        return link;
    }, [ mailMessageDict, value ]);

    if (value == null) {
        return emptyText ? (
            <Typography
                component="span"
                variant="body2"
                className={className}
                {...sanitizeFieldRestProps(rest)}
            >
                {emptyText}
            </Typography>
        ) : null;
    }

    return (
        <Link
            className={className}
            href={mailLink}
            onClick={stopPropagation}
            {...sanitizeFieldRestProps(rest)}
        >
            {value}
        </Link>
    );
});

EmailFieldEx.defaultProps = {
    addLabel: true,
};

EmailFieldEx.propTypes = fieldPropTypes;

export default EmailFieldEx;
