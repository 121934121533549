export const LabelSource = {
	none: 0,
	pallets: 1,
	boxes: 2,
};


export const LabelSourceNames = {
	[LabelSource.none]: "None",
	[LabelSource.pallets]: "Pallets",
	[LabelSource.boxes]: "Boxes",
}
