// TODO: remove unused cemmented code

// import simpleRestProvider from 'ra-data-simple-rest';
// import { httpClient } from './httpClient';

// export const API_URL = '';

// const _simpleRestProvider = simpleRestProvider(API_URL, httpClient);

// export const dataProvider = {
// 	..._simpleRestProvider,
// 	updatePartial: (resource, params) => {
// 		return httpClient(API_URL + "/" + resource + "/" + params.id, {
// 			method: 'PATCH',
// 			body: JSON.stringify(params.data),
// 		}).then(function (_a) {
// 			var json = _a.json;
// 			return ({ data: json });
// 		});
// 	}
// };

import { httpClient } from './httpClient';

import simpleRestProviderEx from '../components/_extensions/react-admin/data-simple-rest/simpleRestProviderEx';


export const API_URL = '';

export const dataProvider = simpleRestProviderEx(API_URL, httpClient);