import React, {useEffect} from 'react';
import {Filter, ReferenceInput, SelectInput, useDataProvider, useTranslate} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";

import {OrderStatusActive} from '../../../entities/OrderStatus';

import {SelectUppercaseInput, SelectDisabledInput} from '../../../components/inputs/SelectUppercaseInput';
import {formatDriverWithPhone} from '../../../components/fields/TransDriverField';
import {DateInput} from '../../../components/inputs/DateTimeInput';

import {CompanySelect, ExcludeCompanySelect} from '../../../components/inputs';
import {ToggleDatesFilter, ToggleDatesEnum} from './ToggleDatesFilter';
import {ToggleTerminalsFilter, TERMINAL_ALL_VALUE} from '../../../components/filters/ToggleTerminalsFilter';
import {ToggleStateFilter} from './ToggleStateFilter';
import {ToggleInput, ToggleTitleInput} from './ToggleInputs';
import { CommodityInput } from '../forms/inputs/CommodityInput';
import {ServiceInputRaw} from '../forms/inputs/ServiceInput';
import {ToggleTypeFilter} from "./ToggleTypeFilter";
import TextInputEx from "../../../components/_extensions/react-admin/input/TextInputEx";
import { filterColors } from '../../../utils/constants';
import { getUserDataState } from '../../_shared/helpers';

export const filterDefaultValues = {
	"eta_date|etd_date": [ToggleDatesEnum.TODAY],
	"terminal_id": [TERMINAL_ALL_VALUE],
};


export const useOrderFilterDefaultValues = () => {
	const dataProvider = useDataProvider();
	const [filters, setFilters] = React.useState(undefined);

	useEffect(() => {
		getUserDataState(dataProvider, "orders-filters", setFilters, filterDefaultValues);
	}, [dataProvider]);

	return filters;
};

const useStyles = makeStyles(t => ({	
	filterTerminal: {
		backgroundColor: filterColors.in_terminal
	},
	filterSearch: {
		backgroundColor: filterColors.search
	},
}));

export const OrdersFilter = ({hasCompanyFilter, hasOrderTypeFilter, isSearchInDocs, isOnlyDates, ...props}) => {
	const translate = useTranslate();
	const classes = useStyles();

	return <Filter {...props}>
		{/* <TextInputEx label="Search" source="_query" alwaysOn /> */}
		{!isSearchInDocs && [
			<ToggleDatesFilter source="eta_date|etd_date" defaultValue={null} alwaysOn key={"eta_date|etd_date"}/>,
			!isOnlyDates && [
				<ToggleTerminalsFilter source="terminal_id" defaultValue={null} alwaysOn key={"terminal_id"}/>,
				<ToggleInput source="in_terminal" value={true} alwaysOn key={"in_terminal"} label={translate("osfc.filters.toggles.in_terminal")} className={classes.filterTerminal} />,
				<ToggleStateFilter source="state" defaultValue={undefined} alwaysOn key={"state"}/>,
				hasOrderTypeFilter && <ToggleTypeFilter source="order_type" defaultValue={undefined} alwaysOn key={"order_type"}/>
			]
		]}

		{/* {!isOnlyDates && <ToggleInput source="is_search_in_docs" value={true} key={"is_search_in_docs"} label={translate("osfc.filters.toggles.search_in_docs")}  alwaysOn className={classes.filterSearch}/>} */}
		{!isOnlyDates && <ToggleTitleInput source="is_search_in_docs" value={true} key={"is_search_in_docs"} label0={translate("osfc.filters.toggles.search_in_docs")} label1={translate("osfc.filters.toggles.back_to_orders")} alwaysOn className={classes.filterSearch}/>}

		{!isSearchInDocs && [
			<TextInputEx source="id" key={"id"}/>,

			<TextInputEx label={"resources.orders.fields.reference"} source="reference|trip_number"
							 resettable key={"reference|trip_number"}/>,

			(hasCompanyFilter && [
				<ExcludeCompanySelect source="exclude_company_id" label="osfc.filters.exclude_company_id" 
									  allowEmpty={false} resettable key={"exclude_company_id"} />,
				<CompanySelect source="company_id" emptyValue={null} emptyText="All Companies" defaultValue={null} 
							   allowEmpty resettable key={"company_id"} />,
			]),

			<ReferenceInput source="status" reference="order-statuses" resettable key={"status"}>
				{isOnlyDates ? <SelectDisabledInput allowedIds={OrderStatusActive} optionText="name"/> : <SelectUppercaseInput optionText="name"/>}	
			</ReferenceInput>,

			<TextInputEx source="trip_number" resettable key={"trip_number"}/>,

			<ServiceInputRaw source="service" resettable key={"service"}/>,

			<TextInputEx label="Truck" source="eta_truck|eta_trailer|etd_truck|etd_trailer" resettable key={"Truck"}/>,

			<CompanySelect source="eta_3rd_party" emptyValue={null} refer="transporters" emptyText="All Companies" defaultValue={null} 
							allowEmpty resettable key={"eta_3rd_party"} filter={{all_transporters: true}} />,

			<DateInput source="eta_date" margin="dense" resettable key={"eta_date"}/>,

			<TextInputEx source="eta_truck" resettable key={"eta_truck"}/>,

			<ReferenceInput label="osfc.filters.eta_a_truck_from_list" source="eta_truck_id" reference="trucks" emptyText="All Trucks" resettable key={"eta_truck_id"} filter={{ no_range: true}} >
				<SelectInput />
			</ReferenceInput>,

			<TextInputEx source="eta_trailer" resettable key={"eta_trailer"}/>,

			<ReferenceInput source="eta_driver_id" reference="drivers" resettable key={"eta_driver_id"} filter={{ no_range: true}}>
				<SelectInput optionText={formatDriverWithPhone}/>
			</ReferenceInput>,

			<CompanySelect source="etd_3rd_party" emptyValue={null} refer="transporters" emptyText="All Companies" defaultValue={null} 
							allowEmpty resettable key={"etd_3rd_party"} filter={{all_transporters: true}}  />,

			<DateInput source="etd_date" margin="dense" resettable key={"etd_date"}/>,

			<TextInputEx source="etd_truck" resettable key={"etd_truck"}/>,

			<ReferenceInput label="osfc.filters.eta_d_truck_from_list" source="etd_truck_id" reference="trucks" emptyText="All Trucks" resettable key={"etd_truck_id"} filter={{ no_range: true}}>
				<SelectInput/>
			</ReferenceInput>,

			<TextInputEx source="etd_trailer" resettable key={"etd_trailer"}/>,
			
			<ReferenceInput source="etd_driver_id" reference="drivers" resettable key={"etd_driver_id"} filter={{ no_range: true}}>
				<SelectInput optionText={formatDriverWithPhone}/>
			</ReferenceInput>,

			<CommodityInput source="commodity" resettable style={{minWidth:"140px"}}/>,

			<ReferenceInput source="cooler_type_id" reference="cooler-types" resettable key={"cooler_type_id"}>
				<SelectUppercaseInput optionText="name"/>
			</ReferenceInput>
		]}

		{isSearchInDocs && <TextInputEx label="Search in docs" source="doc_search_text" alwaysOn/>}
	</Filter>
};
