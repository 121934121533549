import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	useTranslate,
	usePermissions,
	BooleanInput,
	ReferenceArrayInput,
	CheckboxGroupInput,
	SelectInput
} from 'react-admin';
import { FormSpy } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CheckboxGroupInputEx from "../../components/_extensions/react-admin/input/CheckboxGroupInputEx";
import { UpdateForm } from './UpdateForm';
import { CardBlock } from './CardBlock';
import { EmailInput } from '../../components/inputs/';
import { GridInput, GridForm } from '../../components/GridForm/';
import { UserRole } from '../../entities/';
import { OrderStatusSkipNotifTrans, OrderStatusSkipNotifDriver, OrderStatus } from '../../entities/OrderStatus';
import { SysNotifEventsChoices, SysNotifType } from '../../entities/SysNotifTypes';
import { SelectEnumInput } from '../../components/inputs/SelectEnumInput';

const useStyles = makeStyles(theme => ({
	root: {
		
		'&>div>label>.MuiCheckbox-root': {
			padding: '0px 9px',
		},

		'&>legend': {
			marginBottom: '9px',
		}
	}
}));


export const NotificationsBlock = () => {
	const classes = useStyles();
	const t = useTranslate();
	const { permissions } = usePermissions();

	if (!permissions) {
		return null;
	};

	const disabledValues = permissions.role === UserRole.customer_and_transporter_driver ? OrderStatusSkipNotifDriver : null;

	return (
		<UpdateForm
			id={permissions.id}
			resource="settings"
			render={({ body, buttons }) => (
				<CardBlock  buttons={buttons} children={body} />
			)}
		>
			<BooleanInput source="email_order_updates" defaultValue={false} label={"pages.settings.fields.order_updates"} />
			<FormSpy subscription={{ values: true }}>
				{({ values }) => (
				<SelectEnumInput enumObj={SysNotifType} enumName="sysnotiftype"
					source="chat_notification_type"
					disabled={!values.email_order_updates} 
					fullWidth
				/>
				)}
			</FormSpy>
			<FormSpy subscription={{ values: true }}>
				{({ values }) => (
					<ReferenceArrayInput
						disabled={!values.email_order_updates}
						source="email_order_updates_statuses"
						defaultValue={[]}
						reference="order-statuses"
						filter={{ with_permission: true }}
						label={"pages.settings.fields.order_updates_statuses"}
					>
						<CheckboxGroupInputEx disabledValues={disabledValues} classes={classes} row={false} 
											  fullWidth options={{ size: "small" }} />
					</ReferenceArrayInput>
				)}
			</FormSpy>
		</UpdateForm>

	);
}


export const CompanyNotificationsBlock = (props) => {
	const t = useTranslate();
	const { permissions } = usePermissions();
	const classes = useStyles();

	const isOSFCAdminManager = permissions && (
		permissions.role === UserRole.admin ||
		permissions.role === UserRole.osfc_manager
	);
	const isCustomerAndTransporterCompany = permissions && (
		permissions.role === UserRole.customer_and_transporter_manager ||
		permissions.role === UserRole.customer_and_transporter_employee ||
		permissions.role === UserRole.customer_and_transporter_driver
	);
	const isTransporterCompany = permissions && (
		permissions.role === UserRole.transporter_manager ||
		permissions.role === UserRole.transporter_employee ||
		permissions.role === UserRole.transporter_driver
	);
	const currentCompanyId = permissions?.company_id || (props?.record && props.record.id);

	const notifEventChoices = SysNotifEventsChoices.map(el => ({id: el.id, name: t(`osfc.enums.sysnotifevent.${el.name}`)}));
	
	return (
		currentCompanyId ? <UpdateForm
			id={currentCompanyId}
			resource="company-notifications"
			render={({ body, buttons }) => (
				<CardBlock Component={isOSFCAdminManager ? Box : Card} 
						   title={isOSFCAdminManager ? "" : t("pages.notifications.blocks.notifications")} 
						   buttons={buttons} children={body} />
			)}
		>
			<Grid container spacing={2} justify="flex-start">
				<Grid item sm={12} md={6}>
					{!isOSFCAdminManager && <EmailInput source="notification_email" label={t("pages.notifications.fields.notification_email")} disabled />}			
					<BooleanInput source="email_order_updates" defaultValue={false} label={t("pages.notifications.fields.email_order_updates")} />
					<FormSpy subscription={{ values: true }}>
					{({ values }) => (
						// client changed requirements: move deviation_notification_email to settings
						// <GridForm>
							<ReferenceArrayInput
								sm={12}
								disabled={!values.email_order_updates}
								source="email_order_updates_statuses"
								defaultValue={[]}
								reference="order-statuses"
								label={t("pages.notifications.fields.email_order_updates_statuses")}
								>
								<CheckboxGroupInput classes={classes} row={isOSFCAdminManager} fullWidth options={{ size: "small" }} />
							</ReferenceArrayInput>
							/*
							{(values.email_order_updates_statuses && values.email_order_updates_statuses.includes(OrderStatus.DEVIATION)) && (
							<GridInput xs={10} component={EmailInput} disabled={!values.email_order_updates} source="deviation_notification_email" />
							)}
							*/
						// </GridForm>
						)}
					</FormSpy>
				</Grid>
				<Grid item sm={12} md={6}>
					{(isCustomerAndTransporterCompany || isTransporterCompany || isOSFCAdminManager) && <>
						{!isOSFCAdminManager && <EmailInput source="transporter_notification_email" label={t("pages.notifications.fields.transporter_notification_email")} disabled/>}
						<GridInput sm={6} component={SelectInput} source="sys_notif_events" label={t("pages.notifications.fields.sys_notif_events")} choices={notifEventChoices} />
						<BooleanInput source="trans_email_order_updates" defaultValue={false} label={t("pages.notifications.fields.trans_email_order_updates")} />
						<FormSpy subscription={{ values: true }}>
							{({ values }) => (
								<ReferenceArrayInput
									disabled={!values.trans_email_order_updates}
									source="trans_email_order_updates_statuses"
									defaultValue={[]}
									reference="order-statuses"
									label={t("pages.notifications.fields.trans_email_order_updates_statuses")}
								>
									{/* <CheckboxGroupInput classes={classes} row={isOSFCAdminManager} fullWidth options={{ size: "small" }} /> */}
									<CheckboxGroupInputEx disabledValues={OrderStatusSkipNotifTrans} classes={classes} row={isOSFCAdminManager} fullWidth options={{ size: "small" }} />									
								</ReferenceArrayInput>
							)}
						</FormSpy>
					</>}					
				</Grid>				
			</Grid>
		</UpdateForm> : <></>
	);
}
