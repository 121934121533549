// import enLocale from "date-fns/locale/en-US";

export const overrides = {
	MuiFormHelperText: {
		root: {
			// border: '1px solid lime',
			lineHeight: '1.26',
		},
		marginDense: {
			marginTop: 0,
		},
	},
	MuiGrid: {
		"spacing-xs-2": {
			margin: 0,
			width: 'auto',
			
			'& > $item': {
				paddingTop: 4,
				paddingBottom: 0,
			}
		},
	},
	MuiTableCell: {
		sizeSmall: {
			padding: '2px 8px 2px 8px',
		},
		head: {
			whiteSpace: 'nowrap',
		},
		body: {
			whiteSpace: 'nowrap',

			'& > .MuiButton': {
				'&-containedSizeSmall': {
					padding: '1px 9px',
				},
				'&-outlinedSizeSmall': {
					padding: '1px 9px',
				},
			},
		},
	},
    RaList: {
        main: {
			"& div > .MuiCardContent-root > p.MuiTypography-root.MuiTypography-body2": {
				// backgroundColor: 'green',
				fontSize: 16,
				textAlign: 'center',
				color: '#999',
			}
        },
		// noResults: {
		// 	padding: 100,
		// },
    },

	RaDatagrid: {
		headerCell: {
			whiteSpace: 'nowrap',
		}
	},

	RaSelectInput: {
		input: {
			minWidth: 'auto',
		}
	},
	
	RaInput: {
		input: {
			minWidth: 'auto',
		}
	},
	RaShow: {
		main: {
			maxWidth: '100vw',

			'& .ra-field-datagrid': {
				maxWidth: '100%',
				flexBasis: '100%',
				
				'& > .MuiFormControl-root': {
					maxWidth: '100%',
				}
			}
		},
	},
	RaEdit: {
		main: {
			maxWidth: '100vw',
		}
	},
	RaTopToolbar: {
		root: {
			justifyContent: "flex-start",
			alignItems: "center",
		}
	},
	RaListToolbar: {
		actions: {
			paddingTop: 8,
		}
	},
	RaFilterForm: {
		form: {
			marginTop: '0',
			minHeight: 'auto',

			// [theme.breakpoints.up('sm')]: {
			// 	marginTop: 10,
			// }
		}
	},
	// RaTabbedShowLayout: {
	// 	content: {
	// 		'& > span > div > div': {
	// 			padding: 0,
	// 		},
	// 	}
	// }
};
