import React from 'react';
import { FunctionField } from 'react-admin';


const renderYesNo = (record, source) => {
	return record[source] === true ? 'YES' : record[source] === false ? 'NO' : '';
}

export const YesNoField = (props) => {
	return (
		<FunctionField {...props} render={renderYesNo} />
	);
};


YesNoField.defaultProps = {
    addLabel: true,
};
