import React from 'react';
import { Resource } from 'react-admin';

import { pricelistArticlesResource } from '../../resources/pricelist-articles/';
import { pricelistHolidaysResource } from '../../resources/pricelist-holidays/';
import { osfcManagersResource } from '../../resources/osfc-employees/';
import { customerCompaniesResource } from '../../resources/customer-companies/';
import { adminCustomerEmployees } from '../../resources/admin-customer-employees/';
// import { transportersResource } from '../../resources/transporters';
import { customerDriversResource } from '../../resources/drivers';
import { translationsResource } from '../../resources/translations';
import { hotlinksResource } from '../../resources/hotlinks';
import { transportTrucksResource } from '../../resources/trucks';
import { transportTrailersResource } from '../../resources/trailers';
import { adminLogsResource } from '../../resources/admin-log';
import { adminActionsResource } from '../../resources/admin-actions';


export const adminResources = [
	<Resource {...osfcManagersResource} name="osfc-employees" />,
	<Resource {...customerCompaniesResource} name="customer-companies" />,
	<Resource {...adminCustomerEmployees} name="admin-customer-employees" />,
	// <Resource {...transportersResource} name="transporters" />,
	<Resource name="transporters" intent="registration" />,
	<Resource {...customerDriversResource} create={null} edit={null} name="drivers" />,
	<Resource {...transportTrucksResource} name="trucks" />,
	<Resource {...transportTrailersResource} name="trailers" />,
	<Resource {...pricelistArticlesResource} name="pricelist-articles" options={{hasUpload: true}} />,
	<Resource {...pricelistHolidaysResource} name="pricelist-holidays" />,
	<Resource {...translationsResource} name="translations" />,
	<Resource {...hotlinksResource} name="hotlinks" />,
	<Resource {...adminLogsResource} name="admin-logs"/>,
	<Resource {...adminActionsResource} name="admin-actions" />,
	<Resource name="pricelist-special-price" intent="registration" />,
	<Resource name="customer-employees" intent="registration" />,
	
	<Resource name="ports" intent="registration" />,
	<Resource name="users" intent="registration" />,
	<Resource name="companies" intent="registration" />,
	<Resource name="order-statuses" intent="registration" />,
	<Resource name="outgoing-doc-statuses" intent="registration" />,
	<Resource name="company-notifications" intent="registration" />,
	<Resource name="terminal" intent="registration" />,
	<Resource name="customer-company-mappers" intent="registration"/>
];
