import React from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import {
	ReferenceField, TextInput, FunctionField, SaveButton,
	ReferenceInput, FormWithRedirect, useMutation,
	CRUD_UPDATE_SUCCESS, FETCH_END, UPDATE, useNotify,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import MUIButton from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';

import { OrderStatusCustomerEdit, UserRole } from '../../entities';


export const FieldButton = (props) => (
	<MUIButton color="primary" size="small" onClick={props.onClick} children={props.children} />
);


const useEditableFieldStyles = makeStyles({
	root: {
		cursor: "pointer",
		display: "block",
		userSelect: "none",
		width: '100%',
		height: '100%',
		position: 'relative',
		zIndex: 1,
		
		'td > &:hover:before, td > span > &:hover:before': {
			content: '" "',
			zIndex: -1,
			position: 'absolute',
			width: 'calc(100% + 10px)',
			height: 'calc(100% + 14px)',
			left: -5,
			top: -7,
			// borderRadius: 2,
			// boxShadow: '0px 0px 4px rgba(63, 81, 181, 0.8)',
			// backgroundColor: 'rgba(63, 81, 181, 0.1)',
			backgroundColor: 'rgba(255, 255, 255, 0.4)',
			borderLeft: '1px solid rgba(0,0,0,0.1)',
			borderRight: '1px solid rgba(0,0,0,0.1)',
		}
	}
});


export const EditForm = ({ anchorEl, handleClose, handleSubmit, loading, edit, ...props }) => {
	const filteredRecord = React.useMemo(() => React.Children.toArray(edit, child => child.props.source).reduce((prev, curr) => ({
		...prev,
		[curr.props.source]: props.record[curr.props.source] || (curr.props.defaultValue instanceof Function ? curr.props.defaultValue() : curr.props.defaultValue),
		// [curr.props.source]: props.record[curr.props.source] || curr.props.defaultValue,
	}), {}), [props.record, edit]);


	return (
		<Popover
			open={true}
			onClose={handleClose}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
		>
			<FormWithRedirect
				style={{minWidth: 200}}
				save={handleSubmit} resource={props.resource}
				// record={props.record}
				record={filteredRecord}
				render={({ handleSubmitWithRedirect, saving, submitting }) => (
					<Box component="form" style={{width: 250}} p={2} display="flex" flexDirection="column" onSubmit={e => {
						e.preventDefault();
						e.stopPropagation();
						handleSubmitWithRedirect();
					}}>
						{edit}
						<SaveButton disabled={saving || submitting} saving={saving || submitting || loading} handleSubmitWithRedirect={handleSubmitWithRedirect} />
					</Box>
				)}
			/>
		</Popover>
	);
}

export const EditPopup = ({ render, edit, editable, updateType, prepareValues = v => v, additionalParams = [], ...props }) => {
	const classes = useEditableFieldStyles();
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleOpen = (e) => {
		setAnchorEl(e.currentTarget);
		// e.preventDefault();
		// e.stopPropagation();
		// return false;
	};

	const handleClose = (e) => {
		setAnchorEl(null);
		// e.preventDefault();
		// e.stopPropagation();
		// return false;
	};
	const open = Boolean(anchorEl);
	const notify = useNotify();

	const [mutate, { loading }] = useMutation({
		type: updateType || 'updatePartial',
		resource: props.resource,
	}, {
		withDeclarativeSideEffectsSupport: true,
		onSuccess: ({ data }) => {
			setAnchorEl(null);
			dispatch({
				type: CRUD_UPDATE_SUCCESS,
				payload: { data },
				meta: {
					resource: props.resource,
					notification: {
						body: 'ra.notification.updated',
						level: 'info',
						messageArgs: {
							smart_count: 1,
						}
					},
					fetchResponse: UPDATE,
					fetchStatus: FETCH_END
				},
			})
			props.onSuccess && props.onSuccess();
		},
		onFailure: (error) => {
			notify(error.message, "error");
		}
	});

	const handleSubmit = async values => {
		const fields = React.Children.map(edit, child => child.props.source);
		const additionalData = additionalParams.reduce((additionalData, param) => {
			if (props.record[param] !== undefined) {
				additionalData[param] = props.record[param];
			}
			return additionalData;
		}, {});		
		const data = prepareValues ? prepareValues(values) : fields.reduce((prev, curr) => ({
			...prev, [curr]: values[curr]
		}), {});
		return mutate(
			{
				payload: {
					id: props.record.id,
					data: {
						...data,
						...additionalData,
					},
				}
			},
		);
	};

	const statusDisabled = OrderStatusCustomerEdit.indexOf(props.record.status) === -1;
	const isCustomerCompany = props.permissions && (
		props.permissions.role === UserRole.customer_manager || 
		props.permissions.role === UserRole.customer_employee
	);

	return (
		<React.Fragment>
			{((!isCustomerCompany || !statusDisabled) && open) && ReactDOM.createPortal((
				<EditForm anchorEl={anchorEl} handleSubmit={handleSubmit} handleClose={handleClose} loading={loading} edit={edit} {...props} />
			), document.body)}
			{editable === false ? render({}) : render({ openPopup: handleOpen, className: classes.root })}
		</React.Fragment>
	);
}


export const ReferenceEditableField = React.memo(({ children, editable, editField, allowEmpty, filter, optimized = false, ...props }) => {
	if (typeof editable === 'function') {
		editable = editable(props.record);
	}
	if (typeof editField === 'function') {
		editField = editField(props.record);
	}
	if (typeof filter === 'function') {
		filter = filter(props.record);
	}

	return (
		<EditPopup
			editable={editable}
			record={props.record}
			basePath={props.basePath}
			source={props.source}
			resource={props.resource}
			permissions={props.permissions}
			additionalParams={props.additionalParams}
			edit={(
				<ReferenceInput allowEmpty={allowEmpty ? allowEmpty : false} autoFocus label={props.label} source={props.source} reference={props.reference} filter={filter} {...props}>
					{editField}
				</ReferenceInput>
			)}
			render={({ openPopup, className }) => (
				<FunctionField
					render={r => {
						return (
							r[props.source] ?
								optimized ?
									<div {...props} className={className}>
										{React.cloneElement(children, {
											...children.props,
											className: className,
											onDoubleClick: openPopup,
											title: "Double click to edit",
										})}
									</div>
								:
									<ReferenceField {...props} title="Double click to edit" className={className} link={false}>
										{React.cloneElement(children, {
											...children.props,
											className: className,
											onDoubleClick: openPopup,
										})}
									</ReferenceField>
							:
								<div title="Double click to edit" className={className} onDoubleClick={openPopup}>&nbsp;</div>
						);
					}}
				/>
			)}
		/>
	);
}, (_old, _new) => _old.record.id === _new.record.id && _old.record[_old.source] === _new.record[_new.source])

ReferenceEditableField.defaultProps = {
    addLabel: true,
};


export const TextEditableField = React.memo(({ editable, EditComponent=undefined, ...props }) => {
	const Component = EditComponent || TextInput;

	return (
		<EditPopup
			editable={editable}
			source={props.source}
			resource={props.resource}
			record={props.record}
			basePath={props.basePath}
			edit={(
				<Component autoFocus label={props.label} source={props.source} reference={props.reference} />
			)}
			render={({ openPopup, className }) => (
				<FunctionField
					render={r => {
						return (
							r[props.source] ?
								<div title="Double click to edit" className={className} onDoubleClick={openPopup}>{r[props.source]}</div>
								:
								<div title="Double click to edit" className={className} onDoubleClick={openPopup}>&nbsp;</div>
						);
					}}
				/>
			)}
		/>
	);
}, (_old, _new) => _old.record.id === _new.record.id && _old.record[_old.source] === _new.record[_new.source])

TextEditableField.defaultProps = {
    addLabel: true,
};


export const ButtonEditableField = React.memo(({ editable, children, title, color, variant, style, updateType, ...props }) => {
	return (
		<EditPopup
			editable={editable}
			source={props.source}
			resource={props.resource}
			record={props.record}
			basePath={props.basePath}
			edit={children}
			updateType={updateType}
			render={({ openPopup, className }) => (
				<FunctionField
					render={r => {
						return (
							<MUIButton onClick={openPopup} size="small" color={color || "primary"} variant={variant || "text"} style={style || {backgroundColor: "#fff", minWidth: 'auto'}}>
								{title}
							</MUIButton>
						);
					}}
				/>
			)}
		/>
	);
}, (_old, _new) => _old.record === _new.record);


export const ButtonEditableCustomField = React.memo(({ editable, children, title, color, style, variant, prepareValues, ...props }) => {

	return (
		<EditPopup
			prepareValues={prepareValues}
			editable={editable}
			source={props.source}
			resource={props.resource}
			record={props.record}
			basePath={props.basePath}
			edit={children}
			render={({ openPopup, className }) => (
				<FunctionField
					render={r => {
						return (
							<MUIButton onClick={openPopup} size="small" color={color || "primary"} variant={variant || "text"} style={style || {backgroundColor: "#fff", minWidth: 'auto'}}>
								{title}
							</MUIButton>
						);
					}}
				/>
			)}
		/>
	);
}, (_old, _new) => _old.record === _new.record);

ButtonEditableField.defaultProps = {
    addLabel: true,
};
