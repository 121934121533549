import HotlinksIcon from '@material-ui/icons/Storage';

import { HotlinksCreate } from './Create/Create';
import { HotlinksEdit } from './Edit/Edit';
import { HotlinksList } from './List/List';
import { HotlinksShow } from './Show/Show';


export {
	HotlinksList,
	HotlinksCreate,
	HotlinksEdit,
	HotlinksShow,
};

export const hotlinksResource = {
	list: HotlinksList,
	edit: HotlinksEdit,
	create: HotlinksCreate,
	show: HotlinksShow,
	icon: HotlinksIcon,
};
