import React from 'react';
import { BooleanField } from './EqualField';
import { makeStyles } from '@material-ui/core/styles';

// const emptyIcon = React.forwardRef((props, ref) => <span ref={ref} />);

const useStyles = makeStyles({
	root: {
		'& > span': {
			background: '#fff',
			color: '#e74c3c',
			// boxShadow: '0 0 4px #e74c3c, 0 0 2px #e74c3c inset',
			width: 24,
			height: 24,
			borderRadius: 3,
			padding: 2,
			boxSizing: 'border-box',
		}
	}
});


export const PriorityField = (props) => {
	const classes = useStyles();
	return (
		// <BooleanField {...props} FalseIcon={emptyIcon} />
		<BooleanField {...props} classes={props.record[props.source] ? classes : undefined} />
	);
};


PriorityField.defaultProps = {
    addLabel: true,
};
