import React from 'react';

import { FunctionField } from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";


const useEllipsisStyles = makeStyles({
	root: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		maxWidth: '140px',
	},
});


export const LongTextField = (props) => {
	const classes = useEllipsisStyles();
	return (
		<FunctionField {...props} render={record => (
			<Tooltip title={record[props.source] || ''} aria-label={props.source}>
				<Typography variant="body2" classes={classes}>
					{record[props.source]}
				</Typography>
			</Tooltip>
		)} />
	);
}


LongTextField.defaultProps = {
    addLabel: true,
};
