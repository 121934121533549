import React from "react";
import { Filter, } from 'react-admin';
import TextInputEx from "../../../components/_extensions/react-admin/input/TextInputEx";


export const CustomersFilter = props => (
	<Filter {...props}>
		<TextInputEx source="_query" alwaysOn />
		<TextInputEx source="key" />
		<TextInputEx source="en" />
		<TextInputEx source="no" />
	</Filter>
);
