import React from 'react';

import {useTranslate} from 'ra-core';


export const Commodity = {
	SALMON: "salmon",
	SALMON_PROD: "salmon_prod",
	SALMON_MIX: "salmon_mix",
	TROUTH: "trouth",
	WHITEFISH: "whitefish",
	BACALAO: "bacalao",
	DRYFISH: "dryfish",
	SCRIMP: "scrimp",
	OTHER: "other",
};

export const useCommodityChoices = () => {
	const t = useTranslate();

	const choices = React.useMemo(() => Object.values(Commodity).map(x => ({id: x, name: t(`osfc.enums.commodity.${x}`)})), [t]);

	return choices;
};