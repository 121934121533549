import React from "react";
import { usePermissions, useTranslate } from 'react-admin';

import { Notifications, CompanySettingsTab } from ".";
import { UserRole } from "../../entities";

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { TabPanel } from "./TabPanel";

const useStyles = makeStyles(() => ({
	tabs: {
		borderBottom: '1px solid #e0e0e0', 
	}
}));
  
export const CompanySettings = (props) => {
    const { permissions } = usePermissions();
	const translate = useTranslate();

	const isCompanyManager = permissions && (
		(permissions.role == UserRole.customer_manager) ||
		(permissions.role == UserRole.transporter_manager) ||
		(permissions.role == UserRole.customer_and_transporter_manager)
	);
	const classes = useStyles();

	const [value, setValue] = React.useState(0);
  
	const handleChange = (event, newValue) => {
	  setValue(newValue);
	};

	return (
		isCompanyManager ? <>
		  <Tabs
			value={value}
			onChange={handleChange}
			indicatorColor="primary"
			textColor="primary"
			className={classes.tabs} 
		  >
			<Tab label={translate("tab.notifications.name")} />
			<Tab label={translate("tab.company_settings.name")}/>
		  </Tabs>
		
		  <TabPanel value={value} index={0}>
			<Notifications {...props} />
		  </TabPanel>
		  <TabPanel value={value} index={1}>
		  	<CompanySettingsTab {...props}/>
		  </TabPanel>
	  </> : <></>
	  );
};
