import React from 'react';

import { SelectEnumInput } from '../../../components/inputs/SelectEnumInput';
import { OrderDocumentOutputType } from '../../../entities/OrderDocumentOutputType';


const DocumentOutputType = {
	loading: OrderDocumentOutputType.loading,
	unloading: OrderDocumentOutputType.unloading,
}

export const DocumentOutputTypeSelect = (props) => (
	<SelectEnumInput
		key={"doc_out_type"} 
		resettable
		enumName="document-output-type" 
		enumObj={DocumentOutputType} 
		style={{minWidth: "240px"}}
		{...props}/>
);
