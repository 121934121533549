import React from "react";

import { SimpleForm } from 'react-admin';

import { SaveWithCancelToolbar } from '../toolbars/SaveWithCancelToolbar';

export const SimpleFormAdv = (props) => {
    const ToolbarComp = props.toolbarComp ?? SaveWithCancelToolbar;
    const onFormChange = (a, b, c) => {
		console.log(a);
	};

	return <SimpleForm
    onChange={onFormChange}
        submitOnEnter={false}
        redirect="list"
        toolbar={<ToolbarComp hasShow={props.hasShow} {...props.toolbarCompProps} />}
        {...props}        
    />;
};
