import React from 'react';

// import ExportIcon from '@material-ui/icons/TableChart';
import ExportIcon from '@material-ui/icons/GetApp';

import { ToolbarButton } from './ToolbarButton';

import { ProgressIcon } from '../../ProgressIcon';
import { fetchOrdersForExport } from './OrdersExportTable';
import { httpClientRaw } from '../../../api/httpClient';


export const ExportServerButton = ({ filters, label, type }) => {
	const [loading, setLoading] = React.useState(false);
	const anchor = React.useRef();

	React.useEffect(() => {
		anchor.current = document.createElement("a");
		document.body.appendChild(anchor.current);
		return () => {
			document.body.removeChild(anchor.current);
		}
	}, []);

	const exportFile = () => {
		setLoading(true);
		fetchOrdersForExport(httpClientRaw, filters, type)
		.then(res => res.blob())
		.then(blobby => {
			setLoading(false);
			let objectUrl = window.URL.createObjectURL(blobby);

			anchor.current.href = objectUrl;
			anchor.current.download = `orders.${type}`;
			anchor.current.click();

			window.URL.revokeObjectURL(objectUrl);
		}).catch(err => {
			console.error(err);
			setLoading(false);
		});
	}

	return (
		<ToolbarButton onClick={exportFile} icon={loading ? <ProgressIcon /> : <ExportIcon />}>{label}</ToolbarButton>
	);
}


export const ExportServerCSVButton = (props) => (
	<ExportServerButton {...props} type="csv" label="CSV" />
);

export const ExportServerXLSXButton = (props) => (
	<ExportServerButton {...props} type="xlsx" label="XLSX" />
);
