import React from 'react';
import { useInput, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { FilterToggleButtonGroup, StyledToggleButton } from './FilterToggleInput';
import { filterColors } from '../../../utils/constants';


export const ToggleTypeEnum = {
	Customer: "customer",
	Transporter: "transporter",
}

const useStyles = makeStyles({
	filterState: {
		backgroundColor: filterColors.state
	},
});

export const ToggleTypeFilter = (props) => {
	const translate = useTranslate();
	const classes = useStyles();
	const { input } = useInput(props);
	const value = input.value;

	const handleChange = (e, newValue) => {
		input.onChange(newValue);
	}

	return (
		<FilterToggleButtonGroup value={value} onChange={handleChange} label={input.label} exclusive className={classes.filterState}>
			<StyledToggleButton value={ToggleTypeEnum.Customer}>
				{translate('osfc.filters.type.customer')}
			</StyledToggleButton>
			<StyledToggleButton value={ToggleTypeEnum.Transporter}>
				{translate('osfc.filters.type.transporter')}
			</StyledToggleButton>
		</FilterToggleButtonGroup>
  );
}
