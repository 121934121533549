import ViewColumnIcon from '@material-ui/icons/ViewColumn';

import { OrderDocumentStatusList } from './List/List';


export {
	OrderDocumentStatusList,
}


export const orderDocumentStatusResource = {
	list: OrderDocumentStatusList,	
	icon: ViewColumnIcon,
}
