import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';


export const PaperCustomOverlay = withStyles({
	root: {
		position: 'relative',

		'&::before': {
			boxSizing: 'border-box',
			content: '""',
			zIndex: 10000,
			position: 'absolute',
			left: 0,
			top: 0,
			width: '100%',
			height: '100%',
			backgroundColor: 'rgba(255,255,255,0.5)',
			border: '4px dashed #aaa',
			borderRadius: '3px',
		},
		'&::after': {
			boxSizing: 'border-box',
			// content: '"ADD FILE"',
			zIndex: 10000,
			fontFamily: 'sans-serif',
			fontSize: '1.4em',
			fontWeight: 600,
			position: 'absolute',
			left: '50%',
			top: '50%',
			transform: 'translate(-50%, -50%)'
		},
	}
})(Paper);


const PaperAddDocument = withStyles({
	root: {
		'&::after': {
			content: '"ADD DOCUMENT"',
		},
	}
})(PaperCustomOverlay);

const PaperUploading = withStyles({
	root: {
		'&::after': {
			content: '"UPLOADING..."',
		},
	}
})(PaperCustomOverlay);


export class DropPaper extends React.Component {
	state = {
		dragging: false,
	}

	setDragging = (dragging) => {
		this.setState(state => ({
			...state,
			dragging: dragging,
		}))
	}

	handleStop = (e) => {
		e.preventDefault();
		e.stopPropagation();
	}

	handleDragIn = (e) => {
		this.handleStop(e);
		if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
			this.setDragging(true);
		}
	}

	handleDragOut = (e) => {
		this.handleStop(e);
		this.setDragging(false);
	}

	handleDrop = (e) => {
		this.handleStop(e);
		this.setDragging(false);
		if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
			this.props.processDocument(e.dataTransfer.files[0]);
		}
	}

	render() {
		let Component = Paper;
		if (this.state.dragging) {
			Component = PaperAddDocument;
		}
		if (this.props.uploading) {
			Component = PaperUploading;
		}

		const { uploading, processDocument, ...rest }  = this.props;

		return (
			<div
				style={{width: '100%'}}
				onDragEnter={this.handleDragIn}
				onDragLeave={this.handleDragOut}
				onDragOver={this.handleStop}
				onDrop={this.handleDrop}
			>
				<Component {...rest} />
			</div>
		);
	}
}
