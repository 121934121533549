import React from 'react';

import {
	TopToolbar, EditButton, ShowButton, CloneButton, DeleteButton, useRedirect, useTranslate, usePermissions
} from 'react-admin';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';

import {MuiButtonMainProps} from '../_helpers/props/MuiButtonProps';
import {OrderStatus} from "../../entities";


export const ActionsWithBackButton = ({
										  basePath,
										  data,
										  resource,
										  hasList,
										  hasEdit,
										  hasShow,
										  hasClone,
										  hasDelete,
										  cloneMutableValues,
										  ifCustomerTransporterOrderEdit
									  }) => {


	const redirect = useRedirect();
	const t = useTranslate();
	const {permissions} = usePermissions();
	if (!permissions || !data) return null;
	if (ifCustomerTransporterOrderEdit){
		if (permissions.company_id === data.company_id) {
			hasDelete = (record) => record?.status === OrderStatus.BOOKED;
			hasClone = true;
		// } else if (permissions.company_id === data.eta_3rd_party ||
		// 	permissions.company_id === data.etd_3rd_party) {
		// 	hasShow = false;
		}
	}
	return (
		<TopToolbar>
			{hasList && <Box display="flex">
				<Button startIcon={<ArrowBack/>} {...MuiButtonMainProps} size="small"
						onClick={e => redirect(basePath)}>
					{t('ra.action.back')}
				</Button>
			</Box>}
			{hasEdit && <Box ml={1} display="flex">
				<EditButton variant="outlined" basePath={basePath} record={data}/>
			</Box>}
			{hasShow && <Box ml={1} display="flex">
				<ShowButton variant="outlined" basePath={basePath} record={data}/>
			</Box>}
			{hasClone && <Box ml={1} display="flex">
				<CloneButton variant="outlined" basePath={basePath} record={{...data, ...cloneMutableValues}}/>
			</Box>}
			{hasDelete && (hasDelete instanceof Function ? hasDelete(data) : hasDelete) && <Box ml={1} display="flex">
				<DeleteButton undoable={false} basePath={basePath} record={data}/>
			</Box>}
			<Box display="flex" flex="1"/>
		</TopToolbar>
	);
}
