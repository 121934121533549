export const byId = {
	1: 'A',
	2: 'B',
	3: 'C',
	4: 'D',
	5: 'E',
};

export const list = [
	{ id: 1, name: 'A' },
	{ id: 2, name: 'B' },
	{ id: 3, name: 'C' },
	{ id: 4, name: 'D' },
	{ id: 5, name: 'E' },
];
