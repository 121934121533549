import React from 'react';

import { TextField, EmailField, Datagrid } from 'react-admin';
import { PhoneField } from '../../../components/fields/PhoneField';


export const EmployeesDatagrid = props => {
	return (
		<Datagrid {...props}>
			<TextField source="id" />
			<TextField source="first_name" />
			<TextField source="last_name" />
			<EmailField source="email" />
			<PhoneField source="phone" />
		</Datagrid>
	);
}
