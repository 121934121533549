import React from 'react';

import { toDate } from '../../_helpers/dateTime';
import { httpClient } from '../../../api/httpClient';
import { AutoTable } from './AutoTable';


export const fetchOrdersForExport = (httpClient, filter, type = "json", range=[0,19], order = [ 'id', 'DESC' ]) => {
	const _order = JSON.stringify(order);
	const _filter = JSON.stringify(filter);
	const _range = JSON.stringify(range);
	return httpClient(`/orders-export.${type}?filter=${_filter}&sort=${_order}&range=${_range}`);
}

const rowsPerPageOptions = [200, 500, 1000];

export const OrdersExportTable = React.forwardRef(({ filters, setExportDate }, tableRef) => {
    const [loading, setLoading] = React.useState(true);
    const [orders, setOrders] = React.useState([]);
    const [totalPages, setTotalPages] = React.useState(1);
	const [page, setPage] = React.useState(0); 
    const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageOptions[0]); 
	// const itemsPerPage = 20;


    React.useEffect(() => {
        setLoading(true);
        fetchOrdersForExport(httpClient, filters, "json", [page * rowsPerPage, page * rowsPerPage + rowsPerPage - 1])
            .then(res => {
                const exportDateRaw = res.headers.get('x-date');
                const exportDate = toDate(exportDateRaw);
                if (setExportDate) setExportDate(exportDate);
                
                const [start, end, total] = res.headers.get('Content-Range').split(/[\/-]/).map(Number);
                setTotalPages(total);

                setOrders(res.json);
                setLoading(false);
            });
    }, [filters, page, rowsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <AutoTable
                ref={tableRef}				
				loading={loading}
                header={orders && orders.header}
                rows={orders && orders.rows}
                totalCount={totalPages}
                rowsPerPageOptions={rowsPerPageOptions}
                page={page}
				rowsPerPage={rowsPerPage}
                setPage={setPage}				
                setRowsPerPage={setRowsPerPage}
            />
        </React.Fragment>
    );
});