import React, { Component } from 'react';
import T from 'prop-types';

import { FieldTitle } from 'react-admin';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Drawer from '@material-ui/core/Drawer';


export const EXCLUDE = -1;


export class SelectionDialog extends Component {
  onColumnClicked = ({ target: { value: columnName } }) => {
    this.props.onColumnClicked(columnName);
  };

  render() {
    const { columns, selection, onClose, resource } = this.props;

    return (
      <Drawer maxWidth="xs" anchor="left" onClose={onClose} open>
        <DialogTitle id="ra-columns-dialog-title">{this.props.configurationLabel}</DialogTitle>
        <DialogContent>
          <FormGroup>
            {columns.map(({ source, label }) => (
              <FormControlLabel
                key={source}
                control={
                  <Checkbox
                    checked={selection[source] !== EXCLUDE}
                    onChange={this.onColumnClicked}
                    value={source}
                  />
                }
                label={<FieldTitle label={label} source={source} resource={resource} />}
              />
            ))}
          </FormGroup>
        </DialogContent>
      </Drawer>
    );
  }
}

SelectionDialog.propTypes = {
  columns: T.arrayOf(
    T.shape({
      label: T.string,
      source: T.string.isRequired,
    }),
  ).isRequired,
  selection: T.object,
};

SelectionDialog.defaultProps = {
  columns: [],
};
