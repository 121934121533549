import React, { useState, useEffect } from "react";

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton'
import CheckBoxOutlineBlankIcon from '@material-ui/icons//CheckBoxOutlineBlank';
import ListAltIcon from '@material-ui/icons//ListAlt';

import { TextInput, SelectInput, ReferenceInput } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';

import { GridInput, GridInputMultiChanges } from '../GridForm';


export const SelectTextInput = ({xs, sm, md, lg, xl, spacing, disabled, sourceText, sourceSelect, isDefaultTextMode, childrenStyle, ...rest}) => {
	const form = useForm();
	const { values } = useFormState();
	const valueText = values[sourceText];
	const valueSelect = values[sourceSelect];

	const [isTextMode, setIsTextMode] = useState(isDefaultTextMode || (valueText!==null && valueSelect===null));

	const handleTextChange = (e) => {
		setIsTextMode(!isTextMode);
		if (isTextMode) {
			form.change(sourceText, null);
		} else {
			form.change(sourceSelect, null);
		};
	};

	useEffect(() => {
		if (valueText!==null&& valueSelect===null) setIsTextMode(true);
		else if (valueSelect!==null) setIsTextMode(false);
	}, [valueText, valueSelect]);

	const GridInputComp = rest["multiData"] ? GridInputMultiChanges : GridInput;

	return (
		// <Grid item container xs={xs} sm={sm} md={md} lg={lg} xl={xl} spacing={spacing}>
		<Grid container>
			{isTextMode ? <GridInputComp xs={10} component={TextInput} {...rest} source={sourceText} disabled={disabled} style={childrenStyle} /> : 
			<GridInputComp xs={10} disabled={disabled} style={childrenStyle} {...rest} source={sourceSelect} allowEmpty>
				<ReferenceInput>
					<SelectInput {...rest} resettable />
				</ReferenceInput>
			</GridInputComp>}
			<Grid item xs={2} style={childrenStyle}>
				<center>
					<IconButton color="primary" disabled={disabled} onClick={handleTextChange}>
						{isTextMode ? <ListAltIcon /> : <CheckBoxOutlineBlankIcon />}
					</IconButton>
				</center>
			</Grid>
		</Grid>
	);
}


SelectTextInput.defaultProps = {
	xs: 3,
	childrenStyle: { margin: '0px' },
}
