import { OrderService } from "../../entities";
import { OrderStatus } from "../../entities";

export const validatePlaceLength = (value) => {
    if (value && value.length > 64) {
      return "osfc.messages.validation.validatePlaceLength";
    }
  };


export const validateArea = (value, allValues) => {
    if (allValues["service"] === OrderService.intoPlukkStorage && allValues["eta_date"] && allValues["status"] >= OrderStatus.LOADING_COMPLETED && !value){
      return "ra.validation.required"
    }
};  