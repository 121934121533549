import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useMemo } from 'react';
import { MenuItemLink, usePermissions, UserMenu, useLocale, useTranslate, useRedirect } from 'react-admin';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import Divider from '@material-ui/core/Divider';
import SettingsIcon from '@material-ui/icons/Settings';
import UserIcon from '@material-ui/icons/AccountCircle';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import { UserRole } from '../entities/';
import { LocaleSwitcher } from './LocaleSwitcher.js';

import HelpIcon from '@material-ui/icons/Help';
import Box from '@material-ui/core/Box';
import { IconButton, Badge } from '@material-ui/core';
import MessageIcon from '@material-ui/icons/Message'; // or 'ChatBubble' for an alternative chat icon
import { httpClient } from '../api/httpClient.js';

const MenuItemClickRaw = withStyles(theme => ({
	active: {
		color: theme.palette.text.secondary,
	}
}))(MenuItemLink);


export const MenuItemClick = props => {
	return (
		<MenuItemClickRaw to="#" {...props} />
	);
}


const useUserMenuStyles = makeStyles(theme => ({
	userButton: {
		marginLeft: theme.spacing(1),
	}
}))


export const MyUserMenu = (props) => {
	const locale = useLocale();
	const { permissions } = usePermissions();
	const redirect = useRedirect();
	const userMenuStyles = useUserMenuStyles();
	const translate = useTranslate()
	const [unreadCount, setUnreadCount] = React.useState(0);

	// const handleUnreadCountUpdate = (newCount) => {
	// 	setUnreadCount(newCount);
	// };

	const fetchMessages = () => {
		// httpClient(`messages?filter=${encodeURIComponent(JSON.stringify({ unread: true }))}`, {
		
		httpClient(`messages-unread-count`, {
			method: 'GET',
		}).then((response) => {
			setUnreadCount(Number(response.body));
		})
	};

	React.useEffect(() => {
		fetchMessages();
		const intervalId = setInterval(fetchMessages, 60 * 1000);
		return () => clearInterval(intervalId);
	}, []);

	const location = useLocation()
	const isOrdersHref = useMemo(() => {
		if (location.pathname) {
			return location.pathname.includes("orders");
		}
		else return false;
	}, [location]);
	
	if (!permissions) return null;

	const handleMessagesClick = () => {
		setUnreadCount(0);
		redirect("/messages");		
	};
	

	return (
		<React.Fragment>
			{/* {permissions.role !== UserRole.transporter_driver && permissions.role !== UserRole.transporter_employee
			&& permissions.role !== UserRole.transporter_manager? */}
				<IconButton
					// to="/messages"
					onClick={handleMessagesClick}
					component={Link}
					style={{ color: 'white' }}>
					{unreadCount ?
						<Badge badgeContent={unreadCount} color="error">
							<MessageIcon />
						</Badge> : <MessageIcon />
					}
				</IconButton>
				{/* :
				null
			} */}
			<LocaleSwitcher />
			<Box ml={1}>
				<IconButton
					size="medium"
					href={"/help/order_" + locale + ".pdf"} target="_blank"
					disabled={!isOrdersHref}
					color="inherit"
				>{<HelpIcon />}</IconButton>
			</Box>
			<UserMenu {...props} classes={userMenuStyles}>
				<MenuItemLink
					to="#"
					sidebarIsOpen
					leftIcon={<UserIcon />}
					primaryText={permissions.email}
				/>
				<MenuItemLink
					to="/settings"
					primaryText={translate("user-menu.settings")}
					leftIcon={<SettingsIcon />}
				/>
				<Divider />
				{permissions.role === UserRole.admin ?
					<MenuItemLink
						to="/developer"
						primaryText={translate("user-menu.developer")}
						leftIcon={<DeveloperModeIcon />}
					/>
					:
					null
				}
			</UserMenu>
		</React.Fragment>
	);
}
