import React from 'react';
import { SelectInput } from 'react-admin';
import { FlagEN, FlagNO } from '../icons/flags';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    selectContent: {
      display: 'flex',
      alignItems: 'center',
    },
  });

const SelectComponentContent = ({icon, text}) => {
  const classes = useStyles();
  return (
    <div className={classes.selectContent}>
      {icon}
      <span style={{marginLeft: "10px"}}>{text}</span>
    </div>
  );
};

const choices= [
    { id: 'en', name: <SelectComponentContent icon={<FlagEN />} text={"English"}/> },
    { id: 'no', name: <SelectComponentContent icon={<FlagNO />} text={"Norwegian"}/> },
]

const LanguageSelect = ( props ) => {
  return (
    <SelectInput source="locale" choices={choices} autoFocus {...props} />
  );
};


export default LanguageSelect;