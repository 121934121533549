import React, { Component } from 'react';
import T from 'prop-types';

import { Datagrid, useTranslate } from 'react-admin';

import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import get from 'lodash/get';

import DisableBulkIcon from '@material-ui/icons/CheckBox';
import EnableBulkIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import { SelectionDialog, EXCLUDE } from './SelectionDialog';
import LocalStorage from './LocalStorage';

// const arrayToSelection = values =>
//   values.reduce((selection, columnName) => {
//     selection[columnName] = -1;
//     return selection;
//   }, {});

// CustomizableDatagrid allows to show/hide columns dynamically
// the preferences are stored in local storage
export class CustomizableDatagrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpened: false,
      selection: this.getInitialSelection(),
    };
  }
  getColumnNames() {
    const { children } = this.props;
    return filter(React.Children.map(children, field => get(field, ['props', 'source'])));
  }

  getColumnLabels() {
    const { children } = this.props;
    return filter(
      React.Children.map(
        children,
        field =>
          field && {
            source: get(field, ['props', 'source']),
            label: get(field, ['props', 'label']),
          },
      ),
      item => item && item.source,
    );
  }

  getInitialSelection() {
    const {
		// defaultColumns,
		resource, storage,
	} = this.props;

    const previousSelection = storage.get(resource) || {};
	const previousSelectionKeys = Object.keys(previousSelection);
	const columns = this.getColumnNames();
	for (let i = 0; i < previousSelectionKeys.length; i++) {
		if (
			previousSelection[previousSelectionKeys[i]] !== EXCLUDE
			|| columns.indexOf(previousSelectionKeys[i]) < 0
		) {
			delete previousSelection[previousSelectionKeys[i]];
		}
	}

    // if we have a previously stored value, let's return it
    if (!isEmpty(previousSelection)) {
      return previousSelection;
    }

    // if defaultColumns are set let's return them
    // if (!isEmpty(defaultColumns)) {
    //   // Any ! character? (use to subtract columns from default)
    //   if (defaultColumns.reduce((sub, col) => sub || col.indexOf('!') >= 0, false)) {
    //     let excludeColumns = defaultColumns.map(item => item.indexOf('!') === 0 ? item.slice(1) : item)
    //     return arrayToSelection(this.getColumnNames().filter(v => excludeColumns.indexOf(v) < 0));
    //   } else
    //     return arrayToSelection(defaultColumns);
    // }

    // otherwise we fallback on the default behaviour : display all columns
    // return arrayToSelection(this.getColumnNames());
    return [];
  }

  // updates the storage with the internal state value
  updateStorage = () => {
    this.props.storage.set(this.props.resource, this.state.selection);
  };

  toggleColumn = columnName => {
    const previousSelection = this.state.selection;
    // const selection = {
    //   ...previousSelection,
    //   [columnName]: !previousSelection[columnName],
    // };
    const selection = { ...previousSelection };
	if (previousSelection[columnName] === EXCLUDE) {
		delete selection[columnName];
	} else {
		selection[columnName] = EXCLUDE;
	}
    this.setState({ selection }, this.updateStorage);
  };

  handleOpen = () => this.setState({ modalOpened: true });
  handleClose = () => this.setState({ modalOpened: false });

  handleSetBulkOper = () => this.props.setIsBulkOper(!this.props.isBulkOper);

  renderChild = child => {
    if (child) {
      const source = get(child, ['props', 'source']);
      const { selection } = this.state;

      // Show children without source, or children explicitly visible
	  if (source && selection[source] === EXCLUDE) {
		  return null;
	  }

	  return React.cloneElement(child, {});
    }
    return null;
  };

  render() {
    const { children, defaultColumns, ...rest } = this.props;
    const { selection, modalOpened } = this.state;

    return (
      <div>
        <div id={"aboveTable"}>
          {/* <Button variant="text" color="primary" startIcon={<ColumnIcon />}>
          </Button> */}
            {this.props.canMultipleChanges ?
              <IconButton color="primary" onClick={this.handleSetBulkOper} style={{padding: "12px 16px"}}
                >{this.props.isBulkOper ? <EnableBulkIcon/> : <DisableBulkIcon/> }
              </IconButton> :
            null}
          <Button variant="text" color="primary" aria-label="add" onClick={this.handleOpen} >
			    {this.props.columnsLabel}
          </Button>
        </div>
        {modalOpened && (
          <SelectionDialog
            selection={selection}
            columns={this.getColumnLabels()}
            onColumnClicked={this.toggleColumn}
            onClose={this.handleClose}
			resource={this.props.resource}
      configurationLabel={this.props.configurationLabel}
          />
        )}
        <Datagrid {...rest}>{React.Children.map(children, this.renderChild)}</Datagrid>
      </div>
    );
  }
}


CustomizableDatagrid.propTypes = {
//   defaultColumns: T.arrayOf(T.string),
  storage: T.shape({
    get: T.func.isRequired,
    set: T.func.isRequired,
  }),
};


CustomizableDatagrid.defaultProps = {
//   defaultColumns: [],
  storage: LocalStorage,
};
