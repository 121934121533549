import React from 'react';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';

import TextField from '@material-ui/core/TextField';
import { useQuery } from 'react-admin';

import { DATE_TIME_FORMAT, toDate, locale } from '../../_helpers/dateTime';


export const DatetimeInputComponent = (props) => {
	return (
		<TextField
			{...props}
			style={{ width: 200, marginLeft: 8 }}
			variant="outlined"
			margin="none"
			size="small"
		/>
	);
}


export const DateFilterField = ({ label, onChange, value }) => {
	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
			<DateTimePicker
				TextFieldComponent={DatetimeInputComponent}
				margin="normal"
				ampm={false}
				// id="date-picker-dialog"
				label={label}
				format={DATE_TIME_FORMAT}
				value={value}
				onChange={onChange}
			/>
		</MuiPickersUtilsProvider>
	);
}


export const DateFromFilterField = ({ userId, label, onChange, value }) => {
	const qq = useQuery({
		type: "getOne",
		resource: "orders/last-export-date",
		payload: { id: userId },
	});

	const serverValue = qq.data && qq.data.date;
	React.useEffect(() => {
		if (serverValue) {
			onChange(toDate(serverValue));
		} else if (serverValue === null) {
			onChange(serverValue);
		} else {
			onChange(value);
		}
	}, [onChange, serverValue]); // eslint-disable-line

	return (
		<DateFilterField
			onChange={onChange}
			label={label}
			value={value}
		/>
	);
}
