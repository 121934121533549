import React, { useState } from 'react';
import { ReferenceInput, SelectInput, usePermissions } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';


export const TransporterInput = ({ prefix, onChange: _onChange, isEmptyDisable, ...props }) => {
	const form = useForm();
	const {permissions} = usePermissions();
	if (!permissions) return null;

	const handleTransporterChange = (e) => {
		form.change(`${prefix}_driver_id`, null);
		form.change(`${prefix}_driver`, null);
		form.change(`${prefix}_driver_phone`, null);
		// form.change(`${prefix}_truck_id`, null);	
		// form.change(`${prefix}_trailer_id`, null);
		_onChange(e);
	}	

	const excludeCompanyId = props?.curr_company_id ? props.curr_company_id : permissions.company_id;
	const excludeFilterKey = excludeCompanyId && excludeCompanyId.constructor === Array ? "id:nin" : "id:ne";

	return ( isEmptyDisable ? <SelectInput  {...props} resettable />  :
		<ReferenceInput {...props} allowEmpty reference="transporters" sort={{ field: "full_name", order: "ASC" }}
						filter={{ no_range: true, is_blocked: false, all_transporters: true, [excludeFilterKey] : excludeCompanyId}}
						onChange={handleTransporterChange}>
			<SelectInput emptyValue="" resettable optionText="full_name" />
		</ReferenceInput>
	);
}
