import React from 'react';

import {
	useMutation, useRefresh,
	FormWithRedirect, useTranslate,
} from 'react-admin';

import AddIcon from '@material-ui/icons/Add';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useSimpleModalToggle } from '../../../components/dialogs/useSimpleModal';

import { useNotifyError } from '../../../utils/notifiers/useNotifyError';

import { AddEditMapperBody } from './AddEditMapperBody';


export const AddMapper = (props) => {
	
	const refresh = useRefresh();
	const notifyError = useNotifyError();
	const translate = useTranslate();
	const { open, handleOpen, handleClose } = useSimpleModalToggle();

	const [create, { loading }] = useMutation({
        type: 'create',
        resource: 'customer-company-mappers',
    }, {
		onSuccess: () => {
			handleClose();
			refresh();
		},
		onFailure: (error) => {
			notifyError(error);
		}
	});

	const handleSubmit = (values) => {
		create({
			payload: {
				data: {
					...values,
					company_id: props.company_id
				},
			}
		})
	}

	return (
		<>
			<Button aria-label="create" size="small" color="primary" startIcon={<AddIcon />} onClick={handleOpen}>
				{translate("resources.customer-companies-mapper.create")}
			</Button>
			{open && <Dialog open={true} maxWidth="md" onClose={handleClose}>
				<DialogTitle>{translate("resources.customer-companies-mapper.button.add_mapper")}</DialogTitle>
				<FormWithRedirect
					submitOnEnter={false}
					component={DialogContent}
					save={handleSubmit}
					render={({ handleSubmitWithRedirect, form, record }) => (
						<React.Fragment>
							<AddEditMapperBody form={form} record={record} />
							<DialogActions>
								<Button disabled={loading} onClick={handleClose} color="primary">
									{translate("ra.action.cancel")}
								</Button>
								<Button disabled={loading} onClick={handleSubmitWithRedirect} color="primary">
									{translate("ra.action.add")}
								</Button>
							</DialogActions>
						</React.Fragment>
					)}
				/>
			</Dialog>}
		</>
	);
};
