import * as React from 'react';
import {useHistory, useParams} from "react-router-dom";

import {Login} from 'react-admin';
import {Link} from '@material-ui/core';
import LoginFormEx from "../components/_extensions/react-admin/ra-ui-materialui/src/auth/LoginFormEx"
import {ForgotPasswordForm} from "./ForgotPasswordForm";
import {ResetPasswordForm} from "./ResetPasswordForm";

const ResetPasswordRequestLink = () => {
	const history = useHistory()
	return (
		<Link href="/#/forgot-password" underline={"none"} onClick={(event) => {
			const email = document.getElementsByTagName("input")[0].value; // from first input
			history.push({
				pathname: "/forgot-password",
				state: {email: email}
			})
		}}>
			Forgot password?
		</Link>
	);
}

const LoginFormWithReset = (props) => (
	<LoginFormEx HelperPasswordTextComponent={ResetPasswordRequestLink} {...props} />
);

export const MyLoginPage = (props) => (
	<Login><LoginFormWithReset {...props}/></Login>
);

export const ForgotPassword = (props) => (
	<Login><ForgotPasswordForm {...props}/></Login>
);

export const ResetPassword = (props) => {
	const {request_id, request_code, user_id, email, created_at} = useParams();
	return (
		<Login><ResetPasswordForm request_id={request_id} request_code={request_code} user_id={user_id} email={email}
								  created_at={created_at} {...props}/></Login>
	);
}






