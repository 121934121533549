import * as React from 'react';
import { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { useInput, FieldTitle, InputProps } from 'ra-core';


import {
	sanitizeInputRestProps,
	InputHelperText,
	ResettableTextField
} from 'react-admin';

import {debounce} from 'lodash';

/**
 * An Input component for a string
 *
 * @example
 * <TextInput source="first_name" />
 *
 * You can customize the `type` props (which defaults to "text").
 * Note that, due to a React bug, you should use `<NumberField>` instead of using type="number".
 * @example
 * <TextInput source="email" type="email" />
 * <NumberInput source="nb_views" />
 *
 * The object passed as `options` props is passed to the <ResettableTextField> component
 */
const TextInputEx = ({
    label,
    format,
    helperText,
    onBlur,
    onFocus,
    onChange,
    options,
    parse,
    resource,
    source,
    validate,
    debounceTime=750,
    ...rest
}) => {
    const {
        id,
        input,
        isRequired,
        meta: { error, submitError, touched },
    } = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        type: 'text',
        validate,
        ...rest,
    });

    const {onChange: onChangeInput, value: valueInput, ...inputRest} = input;

    const [valueField, setValueField] = React.useState(valueInput);

    const onChangeDebounce = React.useMemo(
        () => debounce(onChangeInput, debounceTime)
      , [onChangeInput, debounceTime]);
    
    React.useEffect(() => {
        return () => {
            onChangeDebounce.cancel();
        }
    }, []);

    return (
        <ResettableTextField
            id={id}
            {...inputRest}
            value={debounceTime ? valueField : input.value}
            onChange={e => {
                const val = e?.target?.value ?? "";
				debounceTime && setValueField(val);
				debounceTime ? onChangeDebounce(val) :
					onChangeInput(val);
            }}
            label={
                label !== '' &&
                label !== false && (
                    <FieldTitle
                        label={label}
                        source={source}
                        resource={resource}
                        isRequired={isRequired}
                    />
                )
            }
            error={!!(touched && (error || submitError))}
            helperText={
                <InputHelperText
                    touched={touched}
                    error={error || submitError}
                    helperText={helperText}
                />
            }
            {...options}
            {...sanitizeInputRestProps(rest)}
        />
    );
};

TextInputEx.propTypes = {
    className: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    options: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
};

TextInputEx.defaultProps = {
    options: {},
};

export default TextInputEx;
