import React from 'react';
import {
  Button
} from 'react-admin';
import useUpdatePartial from '../../../components/_extensions/react-admin/core/dataProvider/useUpdatePartial';
import { useRefresh, useNotify, useTranslate } from 'react-admin';

export const ResendButton = ({ basePath, resource, record }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const t = useTranslate();

  const [data, setData] = React.useState(record);

  const [update, { loading }] = useUpdatePartial(
    resource,
    record.id,
    {},
    record,
    {
      mutationMode: 'undoable',
      onSuccess: ({ data }) => {
        notify(t(`resources.${resource}.resend.success`), 'info', {}, true);
        refresh();
      },
      onFailure: (error) => {
        notify(error.message, 'error');
      },
      
    },
    'update'
  );

  const handleClick = (e) => {
    e.stopPropagation();
    update();
  };

  if (data.sent_attempts >= 3 && data.status === 4) {
    return <Button label={t('osfc.buttons.resend')} onClick={handleClick} />;
  } else {
    return null;
  }
};