export function getUserDataState(dataProvider, key, setFilters, filterDefaultValues){
    // TODOL it was commented because it could be cause of server stuck. Needs to investigate and refactor.
    // dataProvider.getOne("user-data", {
    //     id: key,
    // }).then(({data}) => {
    //     setFilters({
    //         ...filterDefaultValues,
    //         ...data.data,
    //     });
    // });
    setFilters({
        ...filterDefaultValues,
    });
}