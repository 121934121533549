import React from "react";
import {makeStyles} from "@material-ui/core/styles";

export const stylesForColorInheritInList = makeStyles({
	root: {
		"& td": {
			color: 'inherit',
		},
		"& table": {
			color: 'inherit',
		},
		"& td a, & td a span": {
			color: 'inherit',
		},
		"& td span": {
			color: 'inherit',
		}
	}
});





