import React from "react";
import { Filter, } from 'react-admin';
import TextInputEx from "../../../components/_extensions/react-admin/input/TextInputEx";

export const ManagersFilter = props => (
	<Filter {...props}>
		<TextInputEx source="_query" alwaysOn />
		<TextInputEx source="first_name" />
		<TextInputEx source="last_name" />
		<TextInputEx source="email" />
		<TextInputEx source="phone" />
	</Filter>
);
