import React from 'react';

import { Filter } from 'react-admin';

import TextInputEx from '../../../components/_extensions/react-admin/input/TextInputEx';
import { parseLinkQuerystring } from '../../../components/_helpers/parsers';


export const HotlinksFilter = (props) => (
	<Filter {...props}>
		<TextInputEx source='name' />
		<TextInputEx source='link' parse={parseLinkQuerystring} />
	</Filter>
);
