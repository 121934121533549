import React from "react";

import {usePermissions} from 'react-admin';
import {FormCustomer} from "./FormCustomer";
import {FormTransporter} from "./FormTransporter";


export const FormCustomerTransporter = ({isAddMode, ...props}) => {
	const {permissions} = usePermissions();
	if (!permissions) return null;

	if (permissions.company_id === props.record.company_id) {
		return <FormCustomer isAddMode={isAddMode} companyId={permissions.company_id} {...props}/>;
	} else if (permissions.company_id === props.record.eta_3rd_party ||
		permissions.company_id === props.record.etd_3rd_party) {
		return <FormTransporter isAddMode={isAddMode} {...props}/>;
	} else return null;
};
