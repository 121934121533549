import React from "react";
import { useMutation, FunctionField, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MUIList from '@material-ui/core/List';
import MUIListItem from '@material-ui/core/ListItem';
import MUIListItemText from '@material-ui/core/ListItemText';


import { currencyOptions, currencyLocales } from '../../../components/fields/PriceField';


const useStyles = makeStyles({
  nowrap: {
    whiteSpace: 'nowrap',
  }
});

const SpecialPricesButton = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const translate = useTranslate()

  const [approve, { loading, data }] = useMutation({
    type: 'getManyReference',
    resource: 'pricelist-special-price',
    payload: {
      filter: {
        article_code: props.articleCode,
        terminal_id: props.terminalId,
      },
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'id', order: 'DESC' },
    }
  });

  const handleClick = (e) => {
    try {
      approve();
    } catch (err) {
      console.error(err);
    }
    setAnchorEl(e.currentTarget);
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClose = (e) => {
    setAnchorEl(null);
    e.preventDefault();
    e.stopPropagation();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button size="small" aria-describedby={id} variant="outlined" color="primary" onClick={handleClick}>
        {translate("resources.pricelist-articles.button.prices")}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
			vertical: 'center',
			horizontal: 'left',
        }}
        transformOrigin={{
			vertical: 'center',
			horizontal: 'right',
        }}
      >
        <MUIList dense>
          {loading ? <CircularProgress /> : data && data.length ? data.map(item =>
            <MUIListItem button={true} key={item.company_id}>
              <Box className={classes.nowrap} display="flex" width="100%" justifyContent="space-between">
                <Box display="flex" mr={2}><Typography variant="body2">{item.company_name}</Typography></Box>
                <Box display="flex"><Typography variant="body2">{item.price.toLocaleString(currencyLocales, currencyOptions)}</Typography></Box>
              </Box>
            </MUIListItem>
          ) : <MUIListItem>
              <MUIListItemText primary={translate("resources.pricelist-articles.no_special_prices")} />
            </MUIListItem>}
        </MUIList>
      </Popover>
    </div>
  );
}


export const SpecialPricesField = (props) => {
  return (
    <FunctionField {...props} render={() =>
      <SpecialPricesButton articleCode={props.record.code} terminalId={props.record.terminal_id} />
    } />
  );
};

SpecialPricesField.defaultProps = {
    addLabel: true,
};
