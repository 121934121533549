import React from 'react';
import { Create, SimpleForm } from 'react-admin';

import { SaveWithCancelToolbar } from '../../../components/toolbars/SaveWithCancelToolbar';
import { AdminActionCreateForm } from './CreateForm';

export const AdminActionCreate = (props) => (
	<Create {...props}>
		<SimpleForm submitOnEnter={false} toolbar={<SaveWithCancelToolbar />} redirect='list'>
			<AdminActionCreateForm />
		</SimpleForm>
	</Create>
);
