import React from 'react';
import {
	CreateButton,
	useListContext,
	sanitizeListRestProps,
	TopToolbar,
	ExportButton,
	usePermissions
} from 'react-admin';

import { OrdersExport, OrdersExportWithFilters } from '../../../components/dialogs/OrdersExportDialog/';


export const OrdersActions = (props) => {
    const { className, exporter, filters, ...rest } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext(props);
	const { permissions } = usePermissions();
	const isAccessToExport = permissions && (permissions.access_to_export);
    return React.useMemo(
        () => (
            <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
                {filters &&
                    React.cloneElement(filters, {
                        resource,
                        showFilter,
                        displayedFilters,
                        filterValues,
                        context: 'button',
                    })}
                {(exporter !== false && isAccessToExport) && (
                    <ExportButton
                        disabled={total === 0}
                        resource={resource}
                        sort={currentSort}
                        filterValues={filterValues}
                    />
                )}
				{/* {isAccessToExport && (<OrdersExport title="Export" filters={filterValues}/>)}
                {isAccessToExport && (<OrdersExport title="Export Search" />)} */}
                {isAccessToExport && (<OrdersExportWithFilters filters={filterValues}/>)}
                {isAccessToExport && (<OrdersExport userId={permissions.id} />)}
                {hasCreate && <CreateButton basePath={basePath} />}
            </TopToolbar>
        ),
        [resource, displayedFilters, filterValues, selectedIds, filters, total] // eslint-disable-line react-hooks/exhaustive-deps
    );
}
