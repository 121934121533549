// Package from
// https://github.com/vascofg/react-admin-date-inputs

import React from 'react';
import PropTypes from 'prop-types';
import { FieldTitle, useInput, useTranslate } from 'react-admin';
import MUITextField from '@material-ui/core/TextField';
import {
	KeyboardDatePicker,
	// KeyboardTimePicker,
	KeyboardDateTimePicker,
	MuiPickersUtilsProvider,
	KeyboardTimePicker,
} from '@material-ui/pickers';
import * as dateFns from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT, TODAY, locale } from '../_helpers/dateTime';


const InputComponent = props => (
	<MUITextField
		{...props}
		size="small"
		// margin="dense"
	/>
);


export const Picker = ({ PickerComponent, defaultValue, onChange=undefined, ...fieldProps }) => {
	const {
		resettable,
		margin,
		variant,
		options,
		label,
		source,
		resource,
		className,
		providerOptions,
		parse,
		format,
		autoFocus
	} = fieldProps;

	const { isRequired, input, meta } = useInput({ source, validate: fieldProps.validate, parse: parse, format: format, defaultValue: defaultValue, });
	const { touched, error } = meta;
	const translate = useTranslate();

	return (
		<MuiPickersUtilsProvider {...providerOptions}>
			<PickerComponent
				autoFocus={autoFocus}
				variant="modal"
				{...options}
				resettable={resettable}
				margin={margin}
				onKeyDown={fieldProps.onKeyDown}
				autoOk
				clearable
				showTodayButton={true}
				label={<FieldTitle
					label={label}
					source={source}
					resource={resource}
					isRequired={isRequired}
				/>}
				// InputAdornmentProps
				error={!!(touched && error)}
				helperText={touched && error ? translate(error) : ""}
				className={className}
				value={input.value || null}
				onChange={val => {
					input.onChange(val);
					onChange && onChange(val);
				}}
				onBlur={input.onBlur}
				inputVariant={variant || "filled"}
				fullWidth={fieldProps.fullWidth}
				minutesStep={fieldProps.minutesStep}
				disabled={fieldProps.disabled}
				ampm={fieldProps.ampm}
				TextFieldComponent={InputComponent}
			/>
		</MuiPickersUtilsProvider>
	)
}

Picker.propTypes = {
	input: PropTypes.object,
	isRequired: PropTypes.bool,
	label: PropTypes.string,
	minutesStep: PropTypes.number,
	// meta: PropTypes.object,
	options: PropTypes.object,
	resource: PropTypes.string,
	source: PropTypes.string,
	labelTime: PropTypes.string,
	className: PropTypes.string,
	providerOptions: PropTypes.shape({
		utils: PropTypes.func,
		locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

Picker.defaultProps = {
	input: {},
	isRequired: false,
	minutesStep: 5,
	ampm: false,
	// meta: { touched: false, error: false },
	options: {
		format: DATE_TIME_FORMAT,
	},
	resource: '',
	source: '',
	labelTime: '',
	className: '',
	providerOptions: {
		utils: DateFnsUtils,
		locale: locale,
	},
};


const parseTime = (val) => {
	try {
		return dateFns.format(val, TIME_FORMAT);
		// return dateFns.format(val, { representation: 'time' });
	} catch(e) {
		console.error(e);
		return val;
	}
};

const formatTime = (val) => {
	try {
		if (!val) return null;
		return dateFns.parse(val, TIME_FORMAT, TODAY);
	} catch(e) {
		console.error(e);
		return val;
	}
};


export const parseDate = (val) => {
	try {
		const result = dateFns.formatISO(val, { representation: 'date' });
		return result;
	} catch(e) {
		console.error(e);
		return val;
	}
};

export const formatDate = (val) => {
	try {
		if (!val) return null;
		const result = dateFns.parseISO(val, TODAY);
		return result;
	} catch(e) {
		console.error(e);
		return val;
	}
};



export const DateInput = props => <Picker parse={parseDate} format={formatDate} PickerComponent={KeyboardDatePicker} options={{ format: DATE_FORMAT }} {...props} />
export const TimeInput = props => <Picker parse={parseTime} format={formatTime} PickerComponent={KeyboardTimePicker} options={{ format: TIME_FORMAT }} {...props} minutesStep={5} ampm={false} />
export const DateTimeInput = props => <Picker PickerComponent={KeyboardDateTimePicker} {...props} minutesStep={5} ampm={false} />
