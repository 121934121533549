import React from "react";
import './index.css';

import { Admin, adminReducer } from 'react-admin';
import { Route, Redirect } from 'react-router-dom';

import { Developer } from '../pages/developer/';
import { Settings } from '../pages/settings/';
import { Notifications } from '../pages/settings/';
import { CompanySettingsPage } from "../pages/settings/";

import { MyLayout } from './Layout';
import Menu from './Menu';

import { authProvider, dataProvider } from '../api/';
import { i18nProvider } from '../translation/';
import { osfcDefault as theme } from '../themes/osfcDefault';
import { resourcesByPermissions } from './roles';
import { ForgotPassword, MyLoginPage, ResetPassword } from './Login';

// import { OrdersMultipleChangesPage } from "../resources/orders/MultipleChanges";
import { OrdersMultipleChangesPage } from "../resources/orders/MultipleChanges2";
// import { OrdersChanges } from "../resources/orders-changes/List/List";
import Messages from "../resources/messages/List/List";

const customRoutes = [
	<Route exact path="/developer" component={Developer} />,
	<Route exact path="/settings" component={Settings} />,
	<Route exact path="/notifications" component={CompanySettingsPage} />,
	<Route exact path="/forgot-password" component={ForgotPassword} noLayout />,
	<Route exact path="/reset-password/:request_id/:request_code/:user_id/:email/:created_at" component={ResetPassword} noLayout />,
	<Route exact path="/orders/multiple-changes" component={OrdersMultipleChangesPage} />,
	// <Route exact path="/orders-changes" component={OrdersChanges} />,
	<Route exact path="/messages" component={Messages} />

];


const initialState = adminReducer(undefined, { type: '@@INIT' });

const customAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SIDEBAR_OPEN':
      return { ...state, ui: { ...state.ui, sidebarOpen: action.payload } };
    default:
      return adminReducer(state, action);
  }
};

const App = (props) => {
	return (
		<>
			<Admin
				theme={theme}
				  layout={(layoutProps) => <MyLayout {...layoutProps}  />}
				customReducers={{ admin: customAdminReducer }}
				menu={Menu}
				customRoutes={customRoutes}
				authProvider={authProvider}
				dataProvider={dataProvider}
				i18nProvider={i18nProvider}
				loginPage={MyLoginPage}
				catchAll={() => <Redirect to="/" />}
			>
				{resourcesByPermissions}
			</Admin>
		</>
	)
};


export default App;
