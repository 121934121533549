import React from "react";

import { Filter } from 'react-admin';

import TextInputEx from "../../../components/_extensions/react-admin/input/TextInputEx";
import { CompanySelect } from '../../../components/inputs/CompanySelect';
import { parseDigitsAndLetters } from '../../../components/_helpers/parsers';


export const TrailersFilter = ({ companySource, hasCompanyFilter, ...props }) => (
	<Filter {...props}>
		{hasCompanyFilter && <CompanySelect source="company_id" reference="companies" />}
		<TextInputEx source="name" parse={parseDigitsAndLetters(1, 10)} />
	</Filter>
);
