import React from "react";
import { TextInput, required } from 'react-admin';

import { GridForm, GridInput } from '../../../components/GridForm/';
import { PhoneInput, EmailInput } from '../../../components/inputs/';
import { EmployeeTypeSelectInput } from '../../../components/inputs/EmployeeTypeSelect';
import { YesNoInput } from '../../../components/inputs/YesNoInput';


export const CustomerEditForm = props => (
	<GridForm {...props}>
		<GridInput sm={2} component={TextInput} source="id" disabled />
		<GridInput sm={5} component={TextInput} source="first_name" validate={[required()]} autoFocus />
		<GridInput sm={5} component={TextInput} source="last_name" validate={[required()]} />
		<GridInput sm={2} component={EmployeeTypeSelectInput} source="is_manager" />
		<GridInput sm={2} component={YesNoInput} source="access_to_export" defaultValue={false} />
		<GridInput sm={4} component={PhoneInput} source="phone" />
		<GridInput sm={4} component={EmailInput} source="email" validate={[required()]} />
	</GridForm>
);
