import BusinessIcon from '@material-ui/icons/Business';

import { CompanyCreate } from './Create/Create';
import { CompanyEdit } from './Edit/Edit';
import { CompanyShow } from './Show/Show';
import { CompaniesList } from './List/List';


export {
	CompaniesList,
	CompanyCreate,
	CompanyEdit,
	CompanyShow,
}


export const customerCompaniesResource = {
	list: CompaniesList,
	edit: CompanyEdit,
	create: CompanyCreate,
	show: CompanyShow,
	icon: BusinessIcon,
}
