import React, {useCallback, useEffect, useRef} from 'react';
import {Link} from "react-router-dom";

import {
	TextField, EditButton, Pagination, Button,
	List, SelectInput, Loading, ShowButton,
	FunctionField, SelectField, 
	useTranslate, useRefresh, useGetList, useListContext, useRecordContext,
} from 'react-admin';

import {makeStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import DescriptionIcon from '@material-ui/icons/Description';
import SubjectIcon from '@material-ui/icons/Subject';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {useExpandDefaultForAll} from "./useExpandDefaultForAll"

import { OrderDocumentOutputType } from '../../../entities';
import {CompPerm} from '../../../entities/Permission';
import {ScrollingWrapper} from '../../../components/ScrollingWrapper';
import {EqualField, BooleanField} from '../../../components/fields/EqualField';
import {DateFormatField} from '../../../components/fields/DateFormatField';
import TransDriverField from '../../../components/fields/TransDriverField';
import {LongTextField} from '../../../components/fields/LongTextField';
import {PriorityField} from '../../../components/fields/PriorityField';
import CustomerField from '../../../components/fields/CustomerField';
import {ObjectChipField} from '../../../components/fields/ObjectChipField';
import {EditButtonField, ShowButtonField} from '../../../components/fields/LinkField';
import {YesNoField} from "../../../components/fields/YesNoField";
import {SelectDisabledInput} from "../../../components/inputs/SelectUppercaseInput";
import ChatMessageCountField from "../../../components/fields/ChatMessageCountField";
import {CssRowFieldCellWrapper} from "../../../components/fields/CssFieldCellWrapper";
// import {CssFieldCellWrapperOrderAsTrans} from "../../../components/fields/CssFieldCellWrapper";

// import {TextEditableField} from '../../../components/fields/EditableField';
import {
	ReferenceEditableField,
	ButtonEditableCustomField
} from '../../../components/fields/EditableField';
import {Datagrid} from '../../../components/CustomizableDataGrid/';
import {
	CoolerType, OrderServices, OrderStatus, OrderStatusCustomerEdit, OrderStatusOnCompleteEdit, 
	UserRole, UserRoleByCompanyType, useCommodityChoices
} from '../../../entities/';
import { validateArea } from '../../../utils/validation/order';
import {toPrice} from '../../../components/fields/PriceField';
import {NoneOrValueField} from '../../../components/fields/NoOrValueField';

import {OrdersFilter, useOrderFilterDefaultValues} from './ListFilters';
import {OrdersActions} from './Actions';
import ArrivedIcon from '@material-ui/icons/LocalShipping';
import {TimeInput} from '../../../components/inputs';
import {PortInput} from '../../../components/inputs/PortInput';
// import {format} from '../../../components/_helpers/dateTime';
import {TotalPriceLabel} from './TotalPriceLabel';
import {useOrdersAsyncColumnsStorage} from './useOrdersAsyncColumnsStorage';
import {GridInput} from "../../../components/GridForm";
import {SearchResultDocumentsRowExpand} from "./SearchResultRowExpand";
import {useIsSearchInDocs} from "./useIsSearchInDocs";
import CustomBulkActionButtons from './CustomBulkActionButtons';

import { LabelSource } from '../../../entities/LabelSource';
import { OutDocumenStatus } from '../../../entities/OutDocumentStatus';
import { SelectEnumField } from '../../../components/fields/SelectEnumField';
import { OutgoingDocItemsPopup } from '../../../components/dialogs/OutgoingDocItemsPopup';
import { LinkOrderType } from '../../../entities/LinkOrderType';


const defaultSort = {field: 'id', order: 'DESC'};


const useStyles = makeStyles({
	list: {
		noResults: {
			backgroundColor: 'red',
			padding: 100,
		},
		actions: {
			backgroundColor: 'blue',
		},
	},
	expandIcon: {
		padding: "2px"
	}
});

const useCellStyles = makeStyles({
	cellGreen: {
		// backgroundColor: "#e1ffc9", // green
		backgroundColor: "#b7ff7d", // green
	},

	cellRed: {
		// backgroundColor: "#ffe1c9", // red
		// backgroundColor: "#ffb77d", // red
		backgroundColor: "#ff8c8c", // red
	},

	cellWhite: {
		backgroundColor: "#ffffff", // white
	},

	noPadding: {
		padding: '0',

		'&>span>button': {
			borderTopRightRadius: 0,
		},
	},

	noPaddingButtonStart: {
		padding: '0',

		'&>span>button': {
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
		},
	},
	noPaddingButtonEnd: {
		padding: '0',

		'&>span>button': {
			borderTopLeftRadius: 0,
			borderBottomLeftRadius: 0,
		},
	},
});


const Label = ({title, label}) => {
	return (
		<Tooltip title={title} placement="bottom">
			<div>
				{label}
			</div>
		</Tooltip>
	);
}


const OrdersPagination = props => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;


const prepareEtaArrived = v => ({
	port_in: v.port_in,
	eta_time: v.eta_time,
	eta_slot_time: v.eta_slot_time,
	status: OrderStatus.LOADING_READY
});
const prepareEtdArrived = v => ({
	port_out: v.port_out,
	etd_time: v.etd_time,
	etd_slot_time: v.etd_slot_time,
	status: OrderStatus.LOADING_READY
});


const getFilterDataArray = (rec) => {
	switch (rec['link_order_type']){
		case LinkOrderType.trip_number:
			return  ['trip_number', rec['trip_number']];
		case LinkOrderType.etd_truck_id:
			return  ['etd_truck_id', rec['etd_truck_id']];
		case LinkOrderType.etd_truck:
			return  ['etd_truck', rec['etd_truck']];
		default: return [null, null];
	}
}
// const getCurrentTime = () => format(new Date(), "HH:mm");

const DocumentsButton = (props) => {
	const [isOpenedPopover, setIsOpenedPopover] = React.useState(false)
	const popoverAnchor = React.useRef(null);

	const openPopover = () => {
		setIsOpenedPopover(true);
	};
  
	const closePopover = () => {
		setIsOpenedPopover(false);
	};

	const [link_order_key, link_order_value] = getFilterDataArray(props.record);

	const data = {
		outDocType: props.record['outgoing_doc_type'],
		outDocSrc: props.record['outgoing_doc_src'],
		outDocItems: props.record['outgoing_doc_items_with_max_status'],		
		suggested_area: props.record['suggested_area'],
		suggested_area_2: props.record['suggested_area_2'],
		outgoing_order_doc_status: props.record['outgoing_order_doc_status'],
		outDocStatuses: props.outDocStatuses,
		link_order_key: link_order_key,
		link_order_value: link_order_value
	};

	return (
	  <>
		<div ref={popoverAnchor} onMouseEnter={openPopover} onMouseLeave={closePopover}>
			<ShowButton
				to={`/${props.resource}/${props.record.id}/show/${props.pageIndex}`}
				icon={props.record.has_documents ? <DescriptionIcon/> : <SubjectIcon />}
				{...props}			
			/>
		</div>
		{props.record.outgoing_order_doc_status > OutDocumenStatus.none ?
		 <OutgoingDocItemsPopup anchorEl={popoverAnchor}
		    handlePopoverOpen={openPopover}
		 	handlePopoverClose={closePopover}
			isOpen={isOpenedPopover}
			data={data}			
		 /> 
		  : null}
	  </>
	);
}



const ActionButtonsField = ({
	isCompanyActive, isSearchInDocs,
	resource, basePath, record,
	outDocStatuses, comp_perm
}) => {
	const outgoing_doc_style = outDocStatuses && record && record.has_out_documents ? {
		backgroundColor: outDocStatuses[record.outgoing_order_doc_status] && outDocStatuses[record.outgoing_order_doc_status].color,
		border: '1px solid #000000'
	} : {};

	return <React.Fragment>
		{record.has_documents || record.has_out_documents ?
			<DocumentsButton label="" resource={resource} basePath={basePath} record={record} pageIndex={( (comp_perm & CompPerm.is_output_document) && record.has_out_documents ? 4: 3)} 
				style={{minWidth: 'auto', ...outgoing_doc_style}} outDocStatuses={outDocStatuses}/>
			:
			<EditButton label="" disabled={!isCompanyActive} style={{minWidth: 'auto', ...outgoing_doc_style}} resource={resource}
						basePath={basePath} record={record}/>
		}
		{isSearchInDocs && <Link to={`/${resource}/${record.id}/show`} target={"_blank"}>
			<Button style={{minWidth: 'auto', ...outgoing_doc_style}}><VisibilityIcon/></Button>
		</Link>}
	</React.Fragment>;
};

// const OutgoingDocButtonsField = ({outDocStatuses,resource, basePath, record}) => {
// 	const outgoingColumnCellStyle = () => {
// 		return {
// 			backgroundColor: outDocStatuses[record.outgoing_doc_status] && outDocStatuses[record.outgoing_doc_status].color,
// 			border: '3px solid #FFFFFF'
// 		};
// 	};

// 	return (
// 		<React.Fragment>
// 			{record.outgoing_doc_status &&
// 				<DocumentsButton style={outgoingColumnCellStyle() } label="" resource={resource} basePath={basePath} record={record} pageIndex={4}/>
// 			}
// 		</React.Fragment>
// )};

const GridFieldWithSelectionOnDoubleClick = ({ isEqual = false, component, ...props }) => {
	const listContext = useListContext();
	const refresh = useRefresh();
	const record = useRecordContext(props);

	const handleOnDoubleClick = useCallback(() => {
		const newSelectedIds = Object.values(listContext.data) 
									 .filter(row => row[props.source] === record[props.source])
									 .map(row => row.id);

		if (newSelectedIds.length !== 0 && !newSelectedIds.every(id => listContext.selectedIds.includes(id))) {
			listContext.onSelect(newSelectedIds);
			refresh();
		}
	}, [ listContext.data, listContext.selectedIds, props.source ]);

	const sharedProps = {
		style: { cursor: 'pointer' },
		title: "Double click to select same records",
		onDoubleClick: handleOnDoubleClick,
	};

	return React.createElement(component, { ...props, ...sharedProps });
};

export const OrdersList = ({permissions, ...props}) => {
	const classes = useStyles();
	const cellClasses = useCellStyles();
	const aboveListRef = useRef(null);
	const translate = useTranslate();
	const t = (field) => translate(`resources.orders.fields.${field}`);
	const filterDefaultValues = useOrderFilterDefaultValues();
	const columnsAsyncStorage = useOrdersAsyncColumnsStorage();
	const isSearchInDocs = useIsSearchInDocs(filterDefaultValues);
	const commodityChoices = useCommodityChoices();
	const refresh = useRefresh();
	const [isBulkOper, setIsBulkOper] = React.useState(false);

	const { ids: _, data: statuses } = useGetList("order-statuses");

	const { ids: __, data: outDocStatuses } = useGetList(
		"outgoing-doc-statuses",
		{page: 0, perPage: -1},
		{field: 'id', order: 'ASC'},
	);
	
	useEffect(() => {
		if (permissions?.time_refresh_order) {
			const intervalId = setInterval(refresh, permissions.time_refresh_order * 1000); 
			return () => clearInterval(intervalId);
		}
	}, [permissions]);
	

	useExpandDefaultForAll(props.resource);

	if (!permissions || filterDefaultValues === undefined || columnsAsyncStorage.columns === undefined) {
		return <Loading loadingSecondary=""/>;
	}

	const orderRowStyle = (record, index) => {
		return {
			backgroundColor: statuses[record.status] && statuses[record.status].color,
		};
	};

	const orderRowClass = rec => {
		const css = [];
		if ((userCompanyId && userCompanyId !== rec["company_id"])){			
			if (userCompanyId === rec["eta_3rd_party"]) {
				css.push("order-as-trans-eta");
			}
			if (userCompanyId === rec["etd_3rd_party"]) {
				css.push("order-as-trans-etd");			
			}
		}
		return css;
	};

	const userCompanyId = permissions && permissions.company_id;
	const isCompanyActive = !permissions['company_is_blocked'];
	const isOSFCManager = permissions && (
		permissions.role === UserRole.osfc_manager
	);
	const isOSFCUser = permissions && (
		permissions.role === UserRole.osfc_manager ||
		permissions.role === UserRole.osfc_employee
	);
	const isCustomerCompany = permissions && (
		permissions.role === UserRole.customer_manager
		|| permissions.role === UserRole.customer_employee
	);
	const isCustomerAndTransporterCompany = permissions && (
		permissions.role === UserRole.customer_and_transporter_manager ||
		permissions.role === UserRole.customer_and_transporter_employee ||
		permissions.role === UserRole.customer_and_transporter_driver
	);
	// const isTransporterCompany = permissions && (
	// 	permissions.role === UserRole.transporter_manager ||
	// 	permissions.role === UserRole.transporter_employee ||
	// 	permissions.role === UserRole.transporter_driver
	// );

	const isDriver = permissions && (
		permissions.role === UserRole.customer_and_transporter_driver ||
		permissions.role === UserRole.transporter_driver
	);
	// const isRoleForTerminalView = isOSFCManager || isCustomerCompany || (
	// 	permissions && (
	// 		permissions.role === UserRole.customer_and_transporter_manager ||
	// 		permissions.role === UserRole.customer_and_transporter_employee
	// 	)
	// );

	const isNot3rdPartyRole = UserRoleByCompanyType.customer_and_transporter_roles.indexOf(permissions.role) === -1
	const canMultipleChanges = isOSFCUser;

	return (
		<div ref={aboveListRef}>
			<List
				{...props}
				perPage={50}
				empty={false}
				pagination={<OrdersPagination/>}
				classes={classes.list}
				exporter={false}
				bulkActionButtons={canMultipleChanges && isBulkOper && <CustomBulkActionButtons />}
				filters={<OrdersFilter hasCompanyFilter={isOSFCUser}
									hasOrderTypeFilter={isCustomerAndTransporterCompany}
									isOnlyDates={isDriver}
									isSearchInDocs={isSearchInDocs}/>}
				filterDefaultValues={filterDefaultValues}
				sort={defaultSort}
				hasCreate={props.hasCreate && isCompanyActive}
				actions={<OrdersActions/>}
			>
				<ScrollingWrapper>
					<Datagrid rowStyle={orderRowStyle}
							classes={{expandIcon: classes.expandIcon}}
							storage={columnsAsyncStorage}
							isRowExpandable={() => isSearchInDocs}
							// expand={<SearchResultRowExpand aboveListRef={aboveListRef} />}
							// expand={isSearchInDocs ? <SearchResultSimpleRowExpand aboveListRef={aboveListRef} /> : <></>}
							expand={isSearchInDocs ? <SearchResultDocumentsRowExpand /> : <></>}
							expandIconCell={{padding: "2px"}}
							columnsLabel={translate('osfc.filters.labels.columns')}
							canMultipleChanges = {canMultipleChanges}
							isBulkOper = {isBulkOper}
							setIsBulkOper = {setIsBulkOper}
							configurationLabel={translate('osfc.filters.labels.configuration')}>

						{isCustomerAndTransporterCompany ? <CssRowFieldCellWrapper cssFn={orderRowClass} /> : <div></div>}

						{/* <OutgoingDocButtonsField  outDocStatuses={outDocStatuses}/> */}

						<ActionButtonsField isCompanyActive={isCompanyActive} isSearchInDocs={isSearchInDocs} outDocStatuses={outDocStatuses} comp_perm={permissions.comp_perm} />

						<ChatMessageCountField source="message_count" label="" sortable={false}/>

						{(isOSFCUser || isCustomerAndTransporterCompany) && <CustomerField source="company_id" formatLabel={(record) => record["customer_name"]} />}
						<PriorityField source="priority" label={<Label title={t('priority')} label="P"/>}
									sortable={false}/>
						<EditButtonField source="reference" disabled={!isCompanyActive}/>
						<ReferenceEditableField
							optimized={true}
							editable={isOSFCManager ? true : isCustomerCompany ? rec => OrderStatusCustomerEdit.indexOf(rec["status"]) !== -1 : isCustomerAndTransporterCompany && !isDriver ? rec => OrderStatusCustomerEdit.indexOf(rec["status"]) !== -1 && userCompanyId === rec["company_id"] : false}
							permissions={permissions}
							source="status"
							reference="order-statuses"
							additionalParams={['set_order_completed']}
							editField={isOSFCUser ?
								(rec => rec["status"] === OrderStatus.ORDER_COMPLETED ? <SelectDisabledInput allowedIds={OrderStatusOnCompleteEdit} optionText="name" /> : <SelectInput optionText="name"/>) :
								<SelectDisabledInput allowedIds={OrderStatusCustomerEdit} optionText="name"/>								
						}>
							<TextField source="status_name"/>
						</ReferenceEditableField>
						<ObjectChipField source="service" object={OrderServices} titleKey="short_name" tooltipKey="full_name"/>
						<TextField source="terminal_name" label="resources.orders.fields.terminal" />
						<TextField source="et_date_union" />
						{/* {isCustomerAndTransporterCompany ? <CssFieldCellWrapperOrderAsTrans children={<DateFormatField />} 
															cssClass={["eta-a-block"]} prefix="eta" userCompanyId={userCompanyId} 
															source="eta_date" headerClassName={cellClasses.cellGreen} /> :  */}
						
						<DateFormatField source="eta_date" headerClassName={cellClasses.cellGreen} />
						{/* {isCustomerAndTransporterCompany ? <CssFieldCellWrapperOrderAsTrans children={<TextField />} 
															cssClass={["eta-a-block"]} prefix="eta" userCompanyId={userCompanyId} 
															source="eta_time" headerClassName={cellClasses.cellGreen} /> :  */}
						<TextField source="eta_time" headerClassName={cellClasses.cellGreen} />
						{/* {isCustomerAndTransporterCompany ? <CssFieldCellWrapperOrderAsTrans children={<TextField />} 
															cssClass={["eta-a-block"]} prefix="eta" userCompanyId={userCompanyId} 
															source="eta_slot_time" headerClassName={cellClasses.cellGreen} /> :  */}
						<TextField source="eta_slot_time" headerClassName={cellClasses.cellGreen} />
						{/* {isCustomerAndTransporterCompany ? <CssFieldCellWrapperOrderAsTrans children={<TextEditableField />} 
															cssClass={["eta-a-block"]} prefix="eta" userCompanyId={userCompanyId} 
															source="port_in" EditComponent={PortInput} editable={isOSFCManager} 
															headerClassName={cellClasses.cellGreen} /> :  */}
						{/* <TextEditableField source="port_in" headerClassName={cellClasses.cellGreen} editable={!isCustomerAndTransporterCompany}  EditComponent={PortInput}/> */}
						{/* <ReferenceEditableField source="port_in" reference="ports"
							headerClassName={cellClasses.cellGreen}
							permissions={permissions}
							editField={<SelectInput optionText="name"/>}
						>
							<TextField source="name" />
						</ReferenceEditableField> */}
						{isOSFCUser ?
							<ReferenceEditableField source="port_in" reference="ports"
								optimized={true}
								headerClassName={cellClasses.cellGreen}
								permissions={permissions}
								editField={<SelectInput optionText="name" emptyValue="" />}
								filter={rec => ({ terminal_id: [rec.terminal_id] })}
								allowEmpty={true}
								format={value => value === 0 ? '' : value} 
								parse={value => value === '' ? 0 : value}
							>
								<TextField source="port_in_name" />
							</ReferenceEditableField> :
							<TextField source="port_in_name" label={t("port_in")} headerClassName={cellClasses.cellGreen} />
						}
						<CustomerField source="eta_3rd_party" formatLabel={(record) => record["eta_3rd_party_name"]} headerClassName={cellClasses.cellGreen}/>
						{ isNot3rdPartyRole ? <YesNoField source="eta_show_doc_for_trans" headerClassName={cellClasses.cellGreen} label={t("eta_show_doc")}/> : null}
						{/* {isCustomerAndTransporterCompany ? <CssFieldCellWrapperOrderAsTrans children={<TransDriverField />} 
																cssClass={["eta-a-block"]} prefix="eta" userCompanyId={userCompanyId} 
																source="eta_driver" refSource="eta_driver_id" 
																headerClassName={cellClasses.cellGreen} /> : */}
						{isCustomerAndTransporterCompany && <TransDriverField source="eta_driver" refSource="eta_driver_id" prefix="eta" formatLabel={(record) => record["eta_driver_name"]} headerClassName={cellClasses.cellGreen} />}
						{/* {isCustomerAndTransporterCompany ? <CssFieldCellWrapperOrderAsTrans children={<SelectTextField />} 
															cssClass={["eta-a-block"]} prefix="eta" userCompanyId={userCompanyId} 
															source="eta_truck" refSource="eta_truck_id" reference="trucks"
															headerClassName={cellClasses.cellGreen} /> :  */}
						{ isOSFCManager && isBulkOper ? 
							<GridFieldWithSelectionOnDoubleClick component={TextField} sortable={false} source="eta_truck_name" label={t("eta_truck")} headerClassName={cellClasses.cellGreen} />
						:	
							<TextField sortable={false} source="eta_truck_name" label={t("eta_truck")} headerClassName={cellClasses.cellGreen} />
						}
						{/* {isCustomerAndTransporterCompany ? <CssFieldCellWrapperOrderAsTrans children={<SelectTextField />} 
															cssClass={["eta-a-block"]} prefix="eta" userCompanyId={userCompanyId} 
															source="eta_trailer refSource="eta_trailer_id" reference="trailers" 
															headerClassName={cellClasses.cellGreen} /> :  */}
						<TextField source="eta_trailer_name" label={t("eta_trailer")} headerClassName={cellClasses.cellGreen} />
						<LongTextField source="place_from" headerClassName={cellClasses.cellGreen}/>
						{/* <TextField source="assignee_1" headerClassName={cellClasses.cellGreen} /> */}
						{isOSFCUser &&  <ReferenceEditableField source="assignee_1" reference="osfc-employees"
								optimized={true}
								headerClassName={cellClasses.cellGreen}
								permissions={permissions}
								editField={<SelectInput optionText={rec => rec.first_name + ' ' + rec.last_name} emptyValue="" />}
								filter={{ no_range: true, is_terminal: true, ...props.filter }}
								allowEmpty={true}
								format={value => value === 0 ? '' : value} 
								parse={value => value === '' ? 0 : value}
							>
								<TextField source="assignee_1_name" />
							</ReferenceEditableField>
						}

						{isOSFCUser && <ButtonEditableCustomField title={<ArrivedIcon 
							style={{color: '#27ae60'}} fontSize="small"/>}
																	headerClassName={cellClasses.cellGreen}
																	cellClassName={cellClasses.noPaddingButtonStart}
																	prepareValues={prepareEtaArrived}
																	updateType="updatePartial">
							<GridInput xs={12} component={TimeInput} margin="dense"
									label="resources.orders.fields.eta_time" source="eta_time"/>
							<GridInput xs={12} component={TimeInput} margin="dense"
									label="resources.orders.fields.eta_slot_time" source="eta_slot_time"/>
							<FunctionField source="port_in" render={record => 
								<GridInput xs={12} component={PortInput} 
										   label="resources.orders.fields.port_in" source="port_in" 
										   filter={{ terminal_id: [record.terminal_id] }} 
								/>}
							/>
						</ButtonEditableCustomField>}
						{isOSFCUser && <ButtonEditableCustomField title={<ArrivedIcon style={{color: '#e74c3c'}}
																						fontSize="small"/>}
																	headerClassName={cellClasses.cellRed}
																	cellClassName={cellClasses.noPaddingButtonEnd}
																	prepareValues={prepareEtdArrived}
																	updateType="updatePartial">
							<GridInput xs={12} component={TimeInput} margin="dense"
									label="resources.orders.fields.etd_time" source="etd_time"/>
							<GridInput xs={12} component={TimeInput} margin="dense"
									label="resources.orders.fields.etd_slot_time" source="etd_slot_time"/>
							<FunctionField source="port_out" render={record => 
								<GridInput xs={12} component={PortInput} 
										   label="resources.orders.fields.port_out" source="port_out" 
										   filter={{ terminal_id: [record.terminal_id] }} 
								/>}
							/>
						</ButtonEditableCustomField>}
						{/* {isCustomerAndTransporterCompany ? <CssFieldCellWrapperOrderAsTrans children={<DateFormatField />} 
															cssClass={["eta-d-block"]} prefix="etd" userCompanyId={userCompanyId} 
															source="etd_date" headerClassName={cellClasses.cellRed} /> :  */}
						<DateFormatField source="etd_date" headerClassName={cellClasses.cellRed} />
						{/* {isCustomerAndTransporterCompany ? <CssFieldCellWrapperOrderAsTrans children={<TextField />} 
															cssClass={["eta-d-block"]} prefix="etd" userCompanyId={userCompanyId} 
															source="etd_time" headerClassName={cellClasses.cellRed} /> :  */}
						<TextField source="etd_time" headerClassName={cellClasses.cellRed} />
						{/* {isCustomerAndTransporterCompany ? <CssFieldCellWrapperOrderAsTrans children={<TextField />} 
															cssClass={["eta-d-block"]} prefix="etd" userCompanyId={userCompanyId} 
															source="etd_slot_time" headerClassName={cellClasses.cellRed} /> :  */}
						<TextField source="etd_slot_time" headerClassName={cellClasses.cellRed} />
						{/* {isCustomerAndTransporterCompany ? <CssFieldCellWrapperOrderAsTrans children={<TextEditableField />} 
															cssClass={["eta-d-block"]} prefix="etd" userCompanyId={userCompanyId} 
															source="port_out" EditComponent={PortInput} editable={isOSFCManager} 
															headerClassName={cellClasses.cellRed} /> :  */}
						{/* <TextEditableField source="port_out" headerClassName={cellClasses.cellRed} editable={!isCustomerAndTransporterCompany} EditComponent={PortInput}/> */}      
						{/* <ReferenceEditableField source="port_out" reference="ports"
							headerClassName={cellClasses.cellRed}
							permissions={permissions}
							editField={<SelectInput optionText="name"/>}
						>
							<TextField source="name" />
						</ReferenceEditableField> */}
						{isOSFCUser ?
							<ReferenceEditableField source="port_out" reference="ports"
								optimized={true}
								headerClassName={cellClasses.cellRed}
								permissions={permissions}
								editField={<SelectInput optionText="name" emptyValue=""/>}
								filter={rec => ({ terminal_id: [rec.terminal_id] })}
								allowEmpty={true}
								format={value => value === 0 ? '' : value} 
								parse={value => value === '' ? 0 : value}
							>
								<TextField source="port_out_name" />
							</ReferenceEditableField> :
							<TextField source="port_out_name" label={t("port_out")} headerClassName={cellClasses.cellRed} />
						}
						<CustomerField source="etd_3rd_party" formatLabel={(record) => record["etd_3rd_party_name"]} headerClassName={cellClasses.cellRed}/>						
						{ isNot3rdPartyRole ? <YesNoField source="etd_show_doc_for_trans" headerClassName={cellClasses.cellRed} label="resources.orders.fields.etd_show_doc"/> : null}
						{/* {isCustomerAndTransporterCompany ? <CssFieldCellWrapperOrderAsTrans children={<TransDriverField />} 
																cssClass={["eta-d-block"]} prefix="etd" userCompanyId={userCompanyId} 
																source="etd_driver" refSource="etd_driver_id"
																headerClassName={cellClasses.cellRed} /> : */}
						{isCustomerAndTransporterCompany && <TransDriverField source="etd_driver" refSource="etd_driver_id" prefix="etd" formatLabel={(record) => record["etd_driver_name"]} headerClassName={cellClasses.cellRed} />}
						{/* {isCustomerAndTransporterCompany ? <CssFieldCellWrapperOrderAsTrans children={<SelectTextField />} 
															cssClass={["eta-d-block"]} prefix="etd" userCompanyId={userCompanyId} 
															source="etd_truck" refSource="etd_truck_id" reference="trucks"
															headerClassName={cellClasses.cellRed} /> :  */}
						{ isOSFCManager && isBulkOper ?
							<GridFieldWithSelectionOnDoubleClick component={TextField} sortable={false} source="etd_truck_name" label={t("etd_truck")} headerClassName={cellClasses.cellRed} />
						:	
							<TextField sortable={false} source="etd_truck_name" label={t("etd_truck")} headerClassName={cellClasses.cellRed} />
						}
						{/* {isCustomerAndTransporterCompany ? <CssFieldCellWrapperOrderAsTrans children={<SelectTextField />} 
															cssClass={["eta-d-block"]} prefix="etd" userCompanyId={userCompanyId} 
															source="etd_trailer" refSource="etd_trailer_id" reference="trailers"
															headerClassName={cellClasses.cellRed} /> :  */}
						<TextField sortable={false} source="etd_trailer_name" label={t("etd_trailer")} headerClassName={cellClasses.cellRed} />
						<LongTextField source="place_to" headerClassName={cellClasses.cellRed} />
						{/* <TextField source="assignee_2" headerClassName={cellClasses.cellRed} /> */}
						{isOSFCUser && <ReferenceEditableField source="assignee_2" reference="osfc-employees"
								optimized={true}
								headerClassName={cellClasses.cellRed}
								permissions={permissions}
								editField={<SelectInput optionText={rec => rec.first_name + ' ' + rec.last_name} emptyValue="" />}
								filter={{ no_range: true, is_terminal: true, ...props.filter }}
								allowEmpty={true}
								format={value => value === 0 ? '' : value} 
								parse={value => value === '' ? 0 : value}
							>
								<TextField source="assignee_2_name" />
							</ReferenceEditableField>
						}					
						{ isOSFCManager && isBulkOper ?
							<GridFieldWithSelectionOnDoubleClick component={TextField} source="trip_number" />
						:
							<TextField source="trip_number"/>
						}
						<BooleanField source="plumb_seal" label={<Label title={t('plumb_seal')} label="S"/>}
									sortable={false}/>
						<NoneOrValueField source="printed_doc" label={<Label title={t('printed_doc')} label="D"/>}
										sortable={false}/>
						{/* <PriceField source="total_price" /> */}
						<ShowButtonField source="total_price"
										label={isOSFCManager ? <TotalPriceLabel source="total_price" resource={props.resource}/> : <Label title={t('total_price')} label={t('total_price')}/> }
										formatter={toPrice} sortable={false}/>
						<SelectField source="commodity" choices={commodityChoices} sortable={false} />
						<EqualField source="cooler_type_id" to={CoolerType.freezer}
									label={<Label title={t('cooler_type_freezer')} label="F"/>} sortable={false}/>
						<LongTextField source="notes" sortable={false}/>
						<FunctionField source="area_id" render={record => 
								<>
									{isOSFCUser ?
									<ReferenceEditableField source="area_id" reference="areas"
									    record={record}
										optimized={true}
										permissions={permissions}
										editField={<SelectInput optionText="name" emptyValue="" disableValue="disabled_by_any" />}
										filter={{ terminal_id: [record.terminal_id] }}
										allowEmpty={true}
										format={value => value === 0 ? '' : value} 
										parse={value => value === '' ? 0 : value}
										validate={(value, _) => validateArea(value, record)}
										{...props}
									>
										<TextField source="area_name"/>
									</ReferenceEditableField>
									 :
									<TextField source="area_name" label={t("area_id")} />
									}
								</>
							}
						/>
						{isOSFCUser ? <YesNoField source="is_area_blocked" /> : null}
						<TextField source="pallets"/>
						<TextField source="boxes"/>
						<TextField source="label"/>
						<SelectEnumField source="label_source" enumName="label_source" enumObj={LabelSource} />
						<TextField source="kilos"/>
						<YesNoField source="is_doc_for_nfsa"/>
						<LongTextField source="internal_notes" sortable={false} />
					</Datagrid>
				</ScrollingWrapper>
			</List>
		</div>
	);
};
