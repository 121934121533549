import React from 'react';

import { SelectInput } from 'react-admin';
import { SelectEnumInput } from './SelectEnumInput';
import { MapperType } from '../../entities/MapperType';

export const MapperTypeSelect = (props) => (
	<SelectEnumInput
		key={"mapper_type"} 
		resettable 
		enumName="mapper-type" 
		enumObj={MapperType} 
		style={{minWidth: "240px"}} 
		{...props}/>
);
