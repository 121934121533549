import React from "react";
import { TextInput, PasswordInput } from 'react-admin';

import { GridForm, GridInput } from '../../../components/GridForm/';
import { PhoneInput, EmailInput } from '../../../components/inputs/';
import { EmployeeTypeSelectInput } from '../../../components/inputs/EmployeeTypeSelect';
import { YesNoInput } from '../../../components/inputs/YesNoInput';
import { confirmPasswordValidation } from '../../../utils/validation/confirmPasswordValidation';


export const ManagerCreateForm = props => (
	<GridForm {...props}>
		<GridInput sm={6} component={EmployeeTypeSelectInput} source="is_manager" />
		<GridInput sm={6} component={YesNoInput} source="is_terminal" defaultValue={false} />
		<GridInput sm={6} component={YesNoInput} source="access_to_export" defaultValue={false} />
		<GridInput sm={6} component={TextInput} source="first_name" autoFocus />
		<GridInput sm={6} component={TextInput} source="last_name" />
		<GridInput sm={6} component={PhoneInput} source="phone" />
		<GridInput sm={6} component={EmailInput} source="email" />
	</GridForm>
);
