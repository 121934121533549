import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';

import { FunctionField } from 'react-admin';

import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles({
	chip: {
		width: '100%',
		maxWidth: 69,
		fontSize: 12
	}
});

export const ObjectChipField = ({ object, titleKey="full_name", tooltipKey=null, ...props }) => {
    const classes = useStyles();
	return (
        <FunctionField
            {...props}
            addLabel={true}
            render={record => {
                const data = object[record[props.source]];
                if (tooltipKey) {
                    return (
                        <Tooltip title={data[tooltipKey]} placement="right">
                            <Chip fontSize="small" variant="outlined" className={classes.chip} size="small" label={data[titleKey]} />
                            {/* <span>{data[titleKey]}</span> */}
                        </Tooltip>
                    );
                }
                return data[titleKey];
            }}
        />
    );
};

ObjectChipField.defaultProps = {
    addLabel: true,
};
