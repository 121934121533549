export const parseDigits = (val) => {
	if (val) {
		const numbers = val.match(/\d/gi);
		return numbers ? numbers.join('') : '';
	}
	return '';
}


export const parseDigitsAndLetters = (minLength, maxLength) => (val) => {
	const re = new RegExp('[A-Za-z0-9]{' + minLength + ',' + maxLength + '}');

	if (val) {
		const name = val.match(re);
		return name ? name.join('') : '';
	}
	return '';
}


export const parseLinkQuerystring = (value) => {
	let parsedQuerystring = "";
	if (typeof value === "string") {
		const splitedLink = value.split("#/")
		parsedQuerystring = splitedLink[splitedLink.length - 1];
	};
	return parsedQuerystring;
};


export const formatLinkPreview = (cutToLength) => (value) => {
	const parsed = parseLinkQuerystring(value);
	if (parsed.length < cutToLength) {
		return parsed;
	};

	const sliced = parsed.slice(0, cutToLength);
	if (sliced === "") {
		return "";
	};

	const formated = `${sliced}...`;
	return formated;
};


export const parseSortOrder = (value) => {
	return value.replace(/[^0-9]/g, '')
};
