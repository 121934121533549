import LocalShippingIcon from '@material-ui/icons/LocalShipping';

import { DriverCreate } from './Create/Create';
import { DriverEdit } from './Edit/Edit';
import { DriverShow } from './Show/Show';
import { DriversList } from './List/List';


export {
	DriversList,
	DriverCreate,
	DriverEdit,
	DriverShow,
}


export const customerDriversResource = {
	list: DriversList,
	edit: DriverEdit,
	create: DriverCreate,
	show: DriverShow,
	icon: LocalShippingIcon,
}
