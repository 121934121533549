import React from 'react';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import MUIIconButton from '@material-ui/core/IconButton';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Datagrid, TextField, FunctionField, ReferenceManyField, useTranslate } from 'react-admin';

import { PriceField, toPrice } from '../../../../components/fields/PriceField';
import { ScrollingWrapperInCard } from '../../../../components/ScrollingWrapper';

import { StepsPopover } from './StepsPopover';
import { EditArticle } from './EditArticle';
import { AddArticle } from './AddArticle';


const useResourceOptions = (resource) => {
	return useSelector(state => state.admin.resources[resource].props.options)
}


export const PricelistDatagrid = (props) => {
	const orderPricelistOptions = useResourceOptions("order-pricelist");
	const translate = useTranslate()
	
	const total = React.useMemo(() => {
		if (!props.ids || !props.data) return null;
		return props.ids.reduce((prev, curr) => {
			if (props.data[curr].deleted) {
				return prev;
			}
			return prev + props.data[curr].total;
		}, 0);
	}, [ props.data, props.ids ]);

	if (!props.ids || !props.ids.length) {
		if (orderPricelistOptions.hasAdd) {

		} else {
			return null;
		}
	}

	return (
		<Paper variant="outlined">
			<ScrollingWrapperInCard>
				<Datagrid {...props} rowStyle={row => row && row.deleted ? ({ backgroundColor: row.color, textDecoration: 'line-through' }) : ({ backgroundColor: row.color })}>
					<TextField sortable={false} source="article_code"/>
					<TextField sortable={false} source="name" />
					<TextField sortable={false} source="calculation"/>
					<TextField sortable={false} source="units"/>
					<PriceField sortable={false} source="price"/>
					<PriceField sortable={false} source="total"/>
					<TextField sortable={false} source="service_description"/>
					<FunctionField source="steps" render={record => <StepsPopover record={record} steps={record.steps} />} />
					{orderPricelistOptions.hasEdit &&
						<FunctionField source="actions" render={record => <EditArticle article={record} />} />
					}
				</Datagrid>
			</ScrollingWrapperInCard>
			<Box p={1} display="flex" alignItems="center" justifyContent="space-between" boxSizing="border-box">
				<Box pl={0.4} mr={2} display="flex" alignItems="flex-end">
					{total ?
						<Typography variant="body2" title={total}>
							{translate("resources.order-pricelist.total")}: {toPrice(total)}
						</Typography>
						:
						<Typography variant="body2">
							{translate("resources.order-pricelist.empty")}
						</Typography>
					}
				</Box>
				{orderPricelistOptions.hasAdd &&
					<Box display="flex">
						<Box mr={1}>
							<MUIIconButton title="Rules" target="_blank" href="/pricelist_analyze" color="primary" size="small" style={{opacity: 0.5}}>
								<HelpOutlineIcon />
							</MUIIconButton>
						</Box>
						<Box display="flex" alignItems="flex-end">
							<AddArticle order={props.order} />
						</Box>
					</Box>
				}
			</Box>
		</Paper>
	);
}


export const PricelistField = (props) => {
	return (
		<ReferenceManyField {...props} perPage={-1}>
			<PricelistDatagrid order={props.record} />
		</ReferenceManyField>
	);
}


PricelistField.defaultProps = {
    addLabel: true,
	className: 'ra-field-datagrid'
};
