import React from 'react';

import { List, Datagrid, TextField, SelectField, Pagination } from 'react-admin';

import { LongTextField } from '../../../components/fields/LongTextField';
import { ScrollingWrapper } from '../../../components/ScrollingWrapper';
import { AdminActionsFilter } from './ListFilters';


const defaultSort = { field: 'name', order: 'DESC' };

export const AdminActionsList = props => {	

	return (
		<List
			{...props}
			sort={defaultSort}
			exporter={false}
			pagination={<Pagination rowsPerPageOptions={[100, 200, 500]} />}
			bulkActionButtons={false}
			filters={<AdminActionsFilter />}
			// empty={<Empty/>}
		>
			<ScrollingWrapper>
				<Datagrid
					rowClick='show'
				>
					<TextField source='id' sortable={false}/>
					<TextField source='name' sortable={false}/>
					<LongTextField source="data" sortable={false}/>
				</Datagrid>
			</ScrollingWrapper>
		</List>
	);
};
